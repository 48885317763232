import { ApiClient } from '@k2/common/backend/api-client';
import { AssignmentIdentity } from '@k2/common/entities-state/types';
import { map } from 'rxjs/operators';

export class AssignmentPartyEndpoint {
  constructor(private api: ApiClient) {}

  fetchNewPersonForm = ({ clientId, assignmentId }: AssignmentIdentity) => {
    return this.api
      .get(`/clients/${clientId}/assignment/${assignmentId}/analysis/party/person/new`)
      .pipe(map(payload => payload.form));
  };

  fetchEditPersonForm = ({ clientId, assignmentId }: AssignmentIdentity, uniqId: string) => {
    return this.api
      .get(`/clients/${clientId}/assignment/${assignmentId}/analysis/party/person/${uniqId}`)
      .pipe(map(payload => payload.form));
  };

  updatePerson = ({ clientId, assignmentId }: AssignmentIdentity, uniqId: string, data: any) => {
    return this.api.patch(
      `/clients/${clientId}/assignment/${assignmentId}/analysis/party/person/${uniqId}`,
      data
    );
  };

  deletePerson = ({ clientId, assignmentId }: AssignmentIdentity, uniqId: string) => {
    return this.api.delete(
      `/clients/${clientId}/assignment/${assignmentId}/analysis/party/person/${uniqId}`
    );
  };

  createNewPerson = ({ clientId, assignmentId }: AssignmentIdentity, data: any) => {
    return this.api.post(
      `/clients/${clientId}/assignment/${assignmentId}/analysis/party/person/new`,
      data
    );
  };

  fetchNewPetForm = ({ clientId, assignmentId }: AssignmentIdentity) => {
    return this.api
      .get(`/clients/${clientId}/assignment/${assignmentId}/analysis/party/pet/new`)
      .pipe(map(payload => payload.form));
  };

  createNewPet = ({ clientId, assignmentId }: AssignmentIdentity, data: any) => {
    return this.api.post(
      `/clients/${clientId}/assignment/${assignmentId}/analysis/party/pet/new`,
      data
    );
  };

  fetchEditPetForm = ({ clientId, assignmentId }: AssignmentIdentity, uniqId: string) => {
    return this.api
      .get(`/clients/${clientId}/assignment/${assignmentId}/analysis/party/pet/${uniqId}`)
      .pipe(map(payload => payload.form));
  };

  updatePet = ({ clientId, assignmentId }: AssignmentIdentity, uniqId: string, data: any) => {
    return this.api.patch(
      `/clients/${clientId}/assignment/${assignmentId}/analysis/party/pet/${uniqId}`,
      data
    );
  };

  deletePet = ({ clientId, assignmentId }: AssignmentIdentity, uniqId: string) => {
    return this.api.delete(
      `/clients/${clientId}/assignment/${assignmentId}/analysis/party/pet/${uniqId}`
    );
  };
}
