import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { FeedbackComment } from '@k2/common/entities-state/types';
import { Paginator } from '@k2/common/pagination/paginator';

@Component({
  selector: 'feedback-comments',
  templateUrl: 'feedback-comments.component.html',
  styleUrls: ['feedback-comments.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FeedbackCommentsComponent {
  @Input() comments: FeedbackComment[];
  readonly commentsPaginator = Paginator.table();
}
