import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import * as moment from 'moment';
import { Moment } from 'moment';

// todo: Fix a strange behaviour of datepicker when user uses backspace
@Component({
  selector: 'date-filter',
  templateUrl: 'date-filter.component.html',
  styleUrls: ['date-filter.component.scss']
})
export class DateFilterComponent implements OnChanges {
  @Input() control: UntypedFormControl;
  @Input() placeholder = 'Click to choose';
  @Input() clearable = false;
  @Input() noFutureDates = false;
  @Input() minDate = null;
  @Input() maxDate = null;

  @Output() valueChange = new EventEmitter<moment.Moment>();

  momentValue: moment.Moment;
  interimValue: moment.Moment;

  ngOnChanges() {
    if (!!this.interimValue && this.interimValue.unix() * 1000 === Date.parse(this.control.value)) {
      this.momentValue = this.interimValue;
    }
    this.momentValue = moment.utc(this.control.value);
  }

  onValueChange(emitEvent = true) {
    this.interimValue = this.momentValue;
    if (this.momentValue === null) {
      this.control.patchValue(null, { emitEvent });
    } else {
      this.control.patchValue(this.momentValue.toISOString(), { emitEvent });
    }
    this.valueChange.emit();
  }

  setValue(value: Moment | null, emitEvent = true) {
    this.momentValue = value;
    this.onValueChange(emitEvent);
  }

  clearValue() {
    this.momentValue = null;
    this.onValueChange(true);
  }

  getMaxDate() {
    if (this.noFutureDates && !this.maxDate) {
      return new Date();
    } else if (this.maxDate) {
      return new Date(this.maxDate);
    } else {
      return null;
    }
  }
}
