import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { isNotNil } from '@k2/common/helpers';
import { Field, VariationGroups } from '@k2/common/k2-forms-state/types';
import { fromPairs } from 'ramda';

@Component({
  selector: 'variations-control',
  templateUrl: 'variations-control.component.html',
  styleUrls: ['variations-control.component.scss']
})
export class VariationsControlComponent implements OnChanges {
  @Input() field: Field<number[]>;
  options: Option[] = [];

  ngOnChanges(changes: SimpleChanges): void {
    const variationIds = this.field.control.value || [];

    this.options = variationIds.map(variationId => {
      const group = this.groups.find(group => {
        return group.variations.some(variation => variation.id === variationId);
      });

      return { variationId, group: group.type };
    });

    if (this.options.length === 0) {
      this.options = [this.emptyOption];
    }
  }

  onGroupChange = (option: Option) => {
    option.variationId = null;
    this.onVariationChange();
  };

  onVariationChange = () => {
    const values = this.options.map(option => option.variationId).filter(isNotNil);
    const fieldValue = [...new Set(values)];

    this.field.control.setValue(fieldValue);
  };

  addEmptyOption = () => {
    this.options = [...this.options, this.emptyOption];
  };

  removeOption = (option: Option) => {
    this.options = this.options.filter(existing => existing !== option);

    if (this.options.length === 0) {
      this.options = [this.emptyOption];
    }

    this.onVariationChange();
  };

  get groups(): VariationGroups[] {
    return this.field.attributes.valueOptions;
  }

  get associativeGroups(): any {
    return fromPairs(this.groups.map(group => [group.type, group] as any));
  }

  get canRemove(): boolean {
    return this.options.length > 1 || this.options[0].variationId != null;
  }

  private get emptyOption(): Option {
    return { variationId: null, group: this.groups[0].type };
  }

  get disabled() {
    return this.field.control.disabled;
  }

  touch = () => {
    this.field.control.markAsTouched();
  };
}

interface Option {
  variationId: number;
  group: string;
}
