<fieldset [class.expanded]="expanded">
  <div *ngIf="!expanded" class="content brief">
    <label>Name</label>

    <div>
      {{ firstName.defaultValue }} {{ lastName.defaultValue }}
      <empty *ngIf="!firstName.defaultValue && !lastName.defaultValue">
        This field has not been set yet
      </empty>
    </div>

    <div *ngIf="!disabled" class="edit"><a (click)="expand()">edit</a></div>
  </div>

  <div
    *ngIf="expanded"
    class="content"
    cdkTrapFocus
    [cdkTrapFocusAutoCapture]="true"
  >
    <label>Name</label>
    <form [formGroup]="form" (submit)="submit()">
      <div class="control-wrap">
        <div class="control">
          <field-control [field]="firstName"></field-control>
        </div>
        <field-errors [controls]="form.controls[firstName.name]"></field-errors>

        <div class="control last-name">
          <field-control [field]="lastName"></field-control>
        </div>
        <field-errors [controls]="form.controls[lastName.name]"></field-errors>
      </div>

      <submit-control (cancel)="cancel()">Save changes</submit-control>
    </form>
  </div>
</fieldset>
