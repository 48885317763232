<div class="clearfix">
  <div class="row">
    <div [class.col-sm-2]="compact" [class.col-sm-3]="!compact">
      <nav>
        <h2 *ngIf="label != null">{{ label }}</h2>
        <ul *ngSubscribe="visibleItems as visibleItems">
          <li *ngFor="let item of visibleItems; let item_index = index">
            <a
              routerLinkActive="active"
              [routerLink]="item.link"
              [queryParams]="item.queryParams"
              [routerLinkActiveOptions]="{ exact: item.exact }"
              matRipple
            >
              {{ item_index + 1 }}. {{ item.label }}
              <span>»</span>
              <div class="arrow-tip right"></div>
            </a>
          </li>
        </ul>
        <ng-container
          *ngIf="additionalLabel != null && additionalVisibleItems != null"
        >
          <h2>{{ additionalLabel }}</h2>
          <ul *ngSubscribe="additionalVisibleItems as additionalVisibleItems">
            <li
              *ngFor="
                let item of additionalVisibleItems;
                let item_index = index
              "
            >
              <a
                routerLinkActive="active"
                [routerLink]="item.link"
                [queryParams]="item.queryParams"
                [routerLinkActiveOptions]="{ exact: item.exact }"
                matRipple
              >
                {{ item_index + 1 }}. {{ item.label }}
                <span>»</span>
                <div class="arrow-tip right"></div>
              </a>
            </li>
          </ul>
        </ng-container>

        <todo-list *ngIf="hasTodoList" [service]="service" [todos]="todos"></todo-list>
      </nav>
    </div>

    <div
      [class.col-sm-10]="compact"
      [class.col-sm-9]="!compact"
      class="content"
    >
      <ng-content></ng-content>
    </div>
  </div>
</div>
