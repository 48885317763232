import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges
} from '@angular/core';
import { Subscription } from 'rxjs';
import { Region } from '@k2/common/entities-state/types';

@Component({
  selector: 'regions-selector',
  templateUrl: 'regions-selector.component.html',
  styleUrls: ['regions-selector.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RegionsSelectorComponent implements OnChanges, OnDestroy {
  @Input() regions: Region[];
  @Input() selected: number[];
  @Output() selectedChange = new EventEmitter<number[]>();
  @Input() changeServiceToUniversal: EventEmitter<any>;

  @Input() deselectedLabel = 'Not available in';
  @Input() selectedLabel = 'Available in';
  @Input() disabled = false;
  @Input() readonly?: boolean = false;

  leftSelection = new Set<number>();
  rightSelection = new Set<number>();
  subscription: Subscription;

  ngOnChanges(changes: SimpleChanges) {
    if (changes.changeServiceToUniversal && changes.changeServiceToUniversal.currentValue) {
      this.subscription = this.changeServiceToUniversal.subscribe(() => this.select());
    }
  }

  toggleInLeftSelection = (regionId: number) => {
    if (this.leftSelection.has(regionId)) {
      this.leftSelection.delete(regionId);
    } else {
      this.leftSelection.add(regionId);
    }
  };

  toggleInRightSelection = (regionId: number) => {
    if (this.rightSelection.has(regionId)) {
      this.rightSelection.delete(regionId);
    } else {
      this.rightSelection.add(regionId);
    }
  };

  get notSelectedRegions() {
    return this.regions.filter(region => !this.selected.includes(region.id));
  }

  get selectedRegions() {
    return this.regions.filter(region => this.selected.includes(region.id));
  }

  select = () => {
    const newValue = [...this.selected, ...this.leftSelection];
    this.selectedChange.emit(newValue);
    this.leftSelection.clear();
  };

  deselect = () => {
    const newValue = this.selected.filter(id => !this.rightSelection.has(id));
    this.selectedChange.emit(newValue);
    this.rightSelection.clear();
  };

  selectAll = () => {
    const newValue = this.regions.map(region => region.id);
    this.selectedChange.emit(newValue);
    this.leftSelection.clear();
  };

  deselectAll = () => {
    this.selectedChange.emit([]);
    this.rightSelection.clear();
  };

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
