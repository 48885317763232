<ng-content select="[notes]"></ng-content>

<div class="field-tmpl-content">
  <div class="field-main-label">
    <ng-content select="[mainLabel]"></ng-content>
  </div>

  <div class="right">
    <div class="context">
      <div class="field-controls" [class.collapsed]="horizontallyCollapsed">
        <ng-content></ng-content>
      </div>

      <field-tooltip *ngIf="tooltip && !tooltip.isFullAddressTooltip" [tooltip]="tooltip"></field-tooltip>

      <div *ngIf="tooltip && tooltip.isFullAddressTooltip" class="address-tooltips">
        <field-tooltip [tooltip]="tooltip"></field-tooltip>
        <field-tooltip [tooltip]="tooltip"></field-tooltip>
        <field-tooltip [tooltip]="tooltip"></field-tooltip>
        <div class="empty"></div>
        <field-tooltip [tooltip]="tooltip"></field-tooltip>
        <field-tooltip [tooltip]="tooltip"></field-tooltip>
        <field-tooltip [tooltip]="tooltip"></field-tooltip>
      </div>
      <div class="additional">
        <ng-content select="[additional]"></ng-content>
      </div>
    </div>

    <div class="field-errors"><ng-content select="[errors]"></ng-content></div>
  </div>
</div>
