<div [ngStyle]="containerStyle" class="auth-container">
  <div class="h-100">
    <div class="content row col-12 justify-content-center align-content-center">
      <div class="logo col-12 pt-5">
        <k2-logo></k2-logo>
      </div>
      <div #card *ngIf="!showSuccessMessage && showForm" class="card row col-10 col-md-6 justify-content-center align-self-center px-4 py-3">
        <div class="row col-12">
          <div class="row col-12 align-self-center">
            <h1 class="col-12 page-title mb-4"><strong>DATA SUBJECT CONSENT FORM</strong></h1>
            <br>
            <p class="col-12 name"><strong>I, {{ agreementStatus?.userName }}</strong></p>
            <br>
            <p class="col-12">am hereby consenting that K2 Corporate Mobility can process my personal data for the
              purpose of managing my relocation services.
            </p>
            <br>
            <p class="col-12">I am aware and I was informed that I may withdraw my consent at any time by using the
              “DATA SUBJECT CONSENT WITHDRAWAL FORM,” either by sending it via email at
              <a class="flex-nowrap"
                 href="{{'mailto:' + withdrawConsentEmailAddress }}">{{withdrawConsentEmailAddress}}
              </a>
              or by post at:<br>
              <strong>{{ agreementStatus?.k2InvoicingOffice }}</strong> for the attention
              of <strong>{{ k2_employee.first_name }} {{ k2_employee.last_name }},
                {{ k2_employee.job_title }}</strong>
            </p>
          </div>
          <div class="row col-12 align-self-center">
            <h2 class="col-12 agree-terms"> Agree terms </h2>
          </div>
          <div class="row col-12 form-container">
            <form (submit)="submit()" [formGroup]="form">
              <div class="row align-items-center justify-content-center">
                <label class="col-10 px-0 pb-1">Signature</label>
                <div #signatureDiv class="col-10">
                  <signature-pad
                    #signature
                    (drawEnd)="drawComplete($event)"
                    (window:resize)="resizeCanvas()"
                    id="signature"
                  ></signature-pad>

                  <icon
                    (click)="clearPad()"
                    class="clear-pad d-flex justify-content-end p-0"
                    type="erase"
                  ></icon>
                </div>

              </div>

              <div class="accept-checkbox d-flex col-12 align-items-center justify-content-end">
                <input
                  [formControl]="form.controls.accept"
                  id="accept"
                  type="checkbox"
                />

                <label class="pl-2 pr-4" for="accept">I accept</label>
              </div>

              <div class="col-12 justify-content-center my-3">
                <p>
                  By clicking I Accept, I consent to K2 Corporate Mobility processing my data as outlined in our
                  <a
                    href="https://www.k2corporatemobility.com/privacy-policy/"
                    target="_blank"
                  >
                    Privacy Policy
                  </a>
                </p>
              </div>

              <submit-control class="justify-content-center mt-3">Submit</submit-control>
            </form>
          </div>
        </div>
      </div>
      <div *ngIf="!showSuccessMessage && !showForm" class="short-card row col-10 col-md-6 justify-content-center align-self-center px-4 py-3">
          <h1 class="col-12 page-title mb-4"><strong>DATA SUBJECT CONSENT FORM</strong></h1>
          <p class="col-12 px-4">
            This link is either invalid or the Data Subject Consent Form has already been submitted.
            If you think this is a mistake, please contact your K2 Relocation Manager.
          </p>
      </div>
      <div *ngIf="showSuccessMessage" class="short-card row col-10 col-md-6 justify-content-center align-self-center px-4 py-3">
        <h1 class="col-12 page-title mb-4"><strong>DATA SUBJECT CONSENT FORM</strong></h1>
        <p class="col-12 px-4">
          Thank you for filling in the Data Subject Consent Form!
        </p>
      </div>
      <div class="copyright col-12 py-3">© {{ year }} - K2 Corporate Mobility Ltd.</div>
    </div>
  </div>
</div>
