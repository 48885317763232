import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Document } from '@k2/common/entities-state/types';

/**
 * Document link with an optional photo thumbnail and categories information.
 */
@Component({
  selector: 'document-link',
  templateUrl: 'document-link.component.html',
  styleUrls: ['document-link.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DocumentLinkComponent {
  @Input() document: Document;

  get photoCategories(): string | null {
    const categories = this.document.photo_categories || [];
    if (categories.length === 0) return null;
    return categories.join(', ');
  }
}
