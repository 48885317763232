import { DatePipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { FieldTextValueComponent } from '@k2/common/k2-forms/field-value/field-text-value.component';
import { FieldValueFormatter } from '@k2/common/k2-forms/field-value/field-value-formatter';
import { FieldValueComponent } from '@k2/common/k2-forms/field-value/field-value.component';
import {
  FORMATTERS_LIST_PROVIDER,
  FORMATTERS_PROVIDER
} from '@k2/common/k2-forms/field-value/formatters';
import { SharedModule } from '@k2/common/shared.module';
import { TelephoneModule } from '@k2/common/telephone/telephone.module';
import { UsersModule } from '@k2/common/users/users.module';

@NgModule({
  imports: [SharedModule, TelephoneModule, UsersModule],
  declarations: [FieldValueComponent, FieldTextValueComponent],
  exports: [FieldValueComponent],
  providers: [FORMATTERS_PROVIDER, FORMATTERS_LIST_PROVIDER, DatePipe, FieldValueFormatter]
})
export class FieldValueModule {}
