<h2>Response rates</h2>

<div class="col">
  <div class="name">Total requested:</div>
  <div class="value">{{ totalRequested }}</div>
</div>

<div class="col">
  <div class="name">Total received:</div>
  <div class="value">{{ totalReceived }}</div>
</div>

<div class="col">
  <div class="name">Awaiting response:</div>
  <div class="value">{{ totalAwaiting }}</div>
</div>

<h2>Your global response rate: {{ totalPercentage }}%</h2>

<doughnut-chart [config]="response"></doughnut-chart>
