<annotated-control [field]="field">
  <textarea
    [id]="field.attributes.id"
    [formControl]="field.control"
    [placeholder]="placeholder"
    [attr.disabled]="field.attributes.disabled ? 'disabled' : null"
    rows="3"
    autosize
    class="form-control"
  ></textarea>
</annotated-control>
