<ul
  class="qq-upload-list-selector qq-upload-list"
  aria-live="polite"
  aria-relevant="removals"
>
  <li *ngFor="let document of documents" class="qq-upload-success">
    <span>
      <span aria-hidden="true" class="glyphicon glyphicon-file"></span>
      <span class="qq-upload-file-selector qq-upload-file">
        {{ document.name }}
      </span>
    </span>

    <button
      (click)="remove(document)"
      type="button"
      class="qq-btn qq-upload-delete-selector qq-upload-delete"
    >
      <span aria-hidden="true" class="glyphicon glyphicon-remove"></span>
    </button>
  </li>
</ul>
