import { NavigationExtras } from '@angular/router';
import { toAbsolutePath } from '@k2/common/routing/utils';
import { Action } from '@ngrx/store';

export const NAVIGATE = '[Router] Navigate';
export const BACK = '[Router] Back';
export const FORWARD = '[Router] Forward';

export type Actions = Navigate | Back | Forward;

export class Navigate implements Action {
  readonly type = NAVIGATE;
  readonly absolutePath: string;

  constructor(path: any, readonly extras?: Pick<NavigationExtras, 'queryParams'>) {
    const normalizedPath = normalize(path);

    if (normalizedPath.startsWith('/')) {
      this.absolutePath = normalizedPath;
    } else {
      this.absolutePath = toAbsolutePath(normalizedPath);
    }
  }
}

function normalize(path: any) {
  const string = Array.isArray(path) ? path.join('/') : path;
  return string.replace('//', '/');
}

export class Back implements Action {
  readonly type = BACK;
}

export class Forward implements Action {
  readonly type = FORWARD;
}
