import { ApiClient } from '@k2/common/backend/api-client';
import { map } from 'rxjs/operators';
import { AssignmentIdentity } from '../../entities-state/types';
import { pipe } from 'ramda';

export class AssignmentRealEstateEndpoint {
  constructor(private api: ApiClient) { }

  fetchNewBMAForm = ({ clientId, assignmentId }: AssignmentIdentity) => {
    return this.api
      .get(`/staff/clients/${clientId}/assignments/${assignmentId}/bvo/bma/form`)
      .pipe(map(payload => payload.form));
  };

  fetchNewInspectionForm = ({ clientId, assignmentId }: AssignmentIdentity) => {
    return this.api
      .get(`/staff/clients/${clientId}/assignments/${assignmentId}/bvo/inspection/form`)
      .pipe(map(payload => payload.form));
  };

  addNewBMA = ({ clientId, assignmentId }: AssignmentIdentity, data: any) => {
    return this.api.post(`/staff/clients/${clientId}/assignments/${assignmentId}/bvo/bma`, data);
  };

  updateBma = ({ clientId, assignmentId }: AssignmentIdentity, bmaId, data: any) => {
    return this.api.put(`/staff/clients/${clientId}/assignments/${assignmentId}/bvo/bma/${bmaId}`, data);
  }

  addNewInspection = ({ clientId, assignmentId }: AssignmentIdentity, data: any) => {
    return this.api.post(`/staff/clients/${clientId}/assignments/${assignmentId}/bvo/inspections`, data);
  };

  fetchAssignmentBmas = ({ clientId, assignmentId }: AssignmentIdentity) => {
    return this.api
      .getWithoutCache(`/staff/clients/${clientId}/assignments/${assignmentId}/bvo/bma`)
      .pipe(map(payload => payload));
  }

  fetchInspections = ({ clientId, assignmentId }: AssignmentIdentity) => {
    return this.api.getWithoutCache(`/staff/clients/${clientId}/assignments/${assignmentId}/bvo/inspections`)
      .pipe(map(payload => payload));
  }

  updateInspection = ({ clientId, assignmentId }: AssignmentIdentity, inspectionId, data: any) => {
    return this.api.put(`/staff/clients/${clientId}/assignments/${assignmentId}/bvo/inspections/${inspectionId}`, data);
  };

  fetchBma = ({ clientId, assignmentId }: AssignmentIdentity, bmaId) => {
    const url = `/staff/clients/${clientId}/assignments/${assignmentId}/bvo/bma/${bmaId}/view`;
    return this.api
      .getWithoutCache(url)
      .pipe(map(payload => payload.form));
  }

  fetchInspection = ({ clientId, assignmentId }: AssignmentIdentity, inspectionId) => {
    return this.api.getWithoutCache(`/staff/clients/${clientId}/assignments/${assignmentId}/bvo/inspections/${inspectionId}`)
      .pipe(map(payload => payload.form));
  }

  deleteBma = ({ clientId, assignmentId }: AssignmentIdentity, bmaId) => {
    return this.api
      .delete(`/staff/clients/${clientId}/assignments/${assignmentId}/bvo/bma/${bmaId}`);
  }

  deleteInspection = ({ clientId, assignmentId }: AssignmentIdentity, inspectionId) => {
    const url = `/staff/clients/${clientId}/assignments/${assignmentId}/bvo/inspections/${inspectionId}`;
    console.log(url);
    return this.api.delete(url);
  }

  fetchBrokerCompanyUsers = ({ clientId, assignmentId }: AssignmentIdentity, brokerId) => {
    return this.api
      .get(`/staff/clients/${clientId}/assignments/${assignmentId}/bvo/bma/brokers/${brokerId}/users`)
      .pipe(map(payload => payload.brokers));
  }

  setBmaIsSelected = ({ clientId, assignmentId }: AssignmentIdentity, bmaId: any, data: any) => {
    const url = `/staff/clients/${clientId}/assignments/${assignmentId}/bvo/bma/${bmaId}/selected`;
    return this.api.patch(url, data)
      .pipe(map(payload => payload));
  }
  setIsUsedForValue = ({ clientId, assignmentId }: AssignmentIdentity, bmaId: any, data: any) => {
    const url = `/staff/clients/${clientId}/assignments/${assignmentId}/bvo/bma/${bmaId}/used_for_value`;
    return this.api.patch(url, data)
      .pipe(map(payload => payload));
  }

  fetchOverview = ({ clientId, assignmentId }: AssignmentIdentity) => {
    return this.api
      .getWithoutCache(`/staff/clients/${clientId}/assignments/${assignmentId}/real-estate/overview`)
      .pipe(map(payload => payload));
  }

  updateOverviewNotes = ({ clientId, assignmentId }: AssignmentIdentity, notes: string) => {
    return this.api
      .patch(`/staff/clients/${clientId}/assignments/${assignmentId}/real-estate/overview`, { notes: notes })
      .pipe(map(payload => payload.form));
  }

  fetchTitleForm = ({ clientId, assignmentId }: AssignmentIdentity) => {
    return this.api
      .getWithoutCache(`/staff/clients/${clientId}/assignments/${assignmentId}/bvo/title`)
      .pipe(map(payload => payload.form));
  }

  saveTitle = ({ clientId, assignmentId }: AssignmentIdentity, data: any) => {
    return this.api.post(`/staff/clients/${clientId}/assignments/${assignmentId}/bvo/title`, data);
  }

  fetchListingForm = ({ clientId, assignmentId }: AssignmentIdentity) => {
    return this.api.getWithoutCache(`/staff/clients/${clientId}/assignments/${assignmentId}/bvo/listing`)
    .pipe(map(payload => payload.form));
  }

  saveListing = ({ clientId, assignmentId}: AssignmentIdentity, data: any) => {
    return this.api.post(`/staff/clients/${clientId}/assignments/${assignmentId}/bvo/listing`, data);
  }
}
