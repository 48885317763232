import { isNil, uniqBy } from 'ramda';
import { combineLatest, Observable } from 'rxjs';
import { map, skipWhile } from 'rxjs/operators';

export const ignoreValue = '_NOTHING_';

export function toOptionsFilter<TItem, TFilterValue, TFilterLabel>(
  toOption: ToOption<TItem, TFilterValue, TFilterLabel>,
  filterValue: Observable<TFilterValue>
) {
  return toFilter(
    (item: TItem, value: TFilterValue) => toOption(item).value === value,
    filterValue
  );
}

export function toFormFedOptionsFilter<TItem, TFilterValue, TFilterLabel>(
  toOption: ToFormFedOption<TItem, TFilterValue, TFilterLabel>,
  filterValue: Observable<TFilterValue>
) {
  return toFilter(
    (item: TItem, value: TFilterValue) => toOption(item, value).value === value,
    filterValue
  );
}

export function toFilter<TItem, TFilterValue>(
  isAllowed: IsAllowed<TItem, TFilterValue>,
  filterValue: Observable<TFilterValue>
): Filter<TItem> {
  return (source: Observable<TItem[]>): Observable<TItem[]> => {
    return combineLatest([source, filterValue]).pipe(
      map(([items, filterValue]) => {
        if (items == null || items.length === 0 || (filterValue as any) === ignoreValue) {
          return items;
        }
        const filtered = items.filter(item => isAllowed(item, filterValue));

        Object.defineProperty(filtered, '$filtered', { enumerable: false, value: true });
        return filtered;
      })
    );
  };
}

export function asOptions<TItem, TFilterValue, TFilterLabel>(toOption: ToOption<TItem, TFilterValue, TFilterLabel>) {
  return (source: Observable<TItem[]>): Observable<Array<FilterOption<TFilterValue, TFilterLabel>>> => {
    return source.pipe(
      skipWhile(isNil),
      map(items => items.map(toOption)),
      map(uniqBy(o => o.value))
    );
  };
}

export type ToOption<TItem, TFilterValue, TFilterLabel> = (item: TItem) => FilterOption<TFilterValue, TFilterLabel>;

export type ToFormFedOption<TItem, TFilterValue, TFilterLabel> = (item: TItem, filterValue: TFilterValue) =>
  FilterOption<TFilterValue, TFilterLabel>;

export type IsAllowed<TItem, TFilterValue> = (item: TItem, filterValue: TFilterValue) => boolean;

export type Filter<TItem = any> = (source: Observable<TItem[]>) => Observable<TItem[]>;

export interface FilterOption<TValue = any, TLabel = any> {
  value: TValue;
  label: TLabel;
}

export function toFilterOption<T extends string | number>(value: T): FilterOption<T, T> {
  return { value, label: value };
}
