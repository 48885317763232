<profile-ingot>
  <ng-container image>
    <file-jet-img
      [image]="partner.avatar"
      [width]="100"
      [height]="40"
      [alt]="partner.name"
    ></file-jet-img>
  </ng-container>

  <a [routerLink]="partner | partnerLink" tabindex="-1">{{ partner.name }}</a>
</profile-ingot>
