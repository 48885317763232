import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { AbstractControl, ValidationErrors } from '@angular/forms';
import { isNotEmpty } from '@k2/common/helpers';
import { Field } from '@k2/common/k2-forms-state/types';
import { addValidators, WithFormControl } from '@k2/common/k2-forms/field-control/utils';

@Component({
  selector: 'telephone-control',
  templateUrl: 'telephone-control.component.html'
})
export class TelephoneControlComponent extends WithFormControl<string> implements OnChanges {
  @Input() field: Field<string>;

  private _isValid: boolean;

  ngOnChanges(changes: SimpleChanges): void {
    addValidators(this.field.control, this.customValidator);
  }

  private customValidator = (c: AbstractControl): ValidationErrors => {
    if (this._isValid === false && isNotEmpty(this.value)) {
      return { telephone: 'Field should be a valid telephone number' };
    }
    return null;
  };

  set isValid(isValid: boolean) {
    this._isValid = isValid;
    this.field.control.updateValueAndValidity();
  }

  get isValid(): boolean {
    return this._isValid;
  }
}
