<annotated-control [field]="field">
  <telephone-input
    [id]="field.attributes.id"
    [initialCountry]="field.attributes.initial_country"
    [value]="value"
    [disabled]="disabled"
    (blur)="touch()"
    (valueChange)="value = $event"
    (isValid)="isValid = $event"
  ></telephone-input>
</annotated-control>
