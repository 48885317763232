import { Region } from '@k2/common/entities-state/types';
import { values } from 'ramda';

export function extractContinentRegions(continents: Continents): Region[] {
  return values(continents)
    .reduce((regions, continent) => [...regions, ...continent.countries], [])
    .map(region => ({ id: region.id, name: region.name, country_iso: region.iso2 }))
    .sort((a, b) => a.name.localeCompare(b.name));
}

interface Continents {
  [name: string]: {
    continent: string;
    countries: Array<{
      id: number;
      name: string;
      iso2: string;
      enabled: boolean;
    }>;
  };
}
