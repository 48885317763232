import { Pipe, PipeTransform } from '@angular/core';
import { alwaysPrimitive } from '@k2/common/helpers';
import { Partner } from '@k2/common/entities-state/types';
import { LinkGenerator } from './link-generator';

@Pipe({ name: 'partnerLink' })
export class PartnerLinkPipe implements PipeTransform {
  constructor(private generator: LinkGenerator) {}

  transform(partner: Pick<Partner, 'id'> | number): any {
    if (partner == null) return;

    return this.generator.generate({
      type: 'partner',
      data: {
        partnerId: alwaysPrimitive(partner, p => p.id)
      }
    });
  }
}
