import { isAction } from '@k2/common/state/action-utils';
import { ObservableActionResult } from '@k2/common/state/services/app-store';
import { Action } from '@ngrx/store';
import { undo } from "@k2/common/ngrx-undo-replacement/undoAction";
import { pipe } from 'ramda';
import { Observable, of } from 'rxjs';
import { catchError, filter, map } from 'rxjs/operators';

/**
 * Undo a given action when underlying observable throws an error.
 */
export function asUndoable(action: Action) {
  return (source: Observable<any>): Observable<any> => {
    return source.pipe(catchError(() => of(undo(action))));
  };
}

/**
 * Allows to create effects, which may or may not return an action.
 */
export function rest(source: Observable<any>): Observable<any> {
  return source.pipe(filter(isAction));
}

/**
 * A combination of `asUndoable(action)` and `rest`.
 */
export const asUndoableRest = (action: Action) => pipe(asUndoable(action), rest);

/**
 * Dispatch SUCCESS/FAILED action when operation success/rejects.
 *
 * Use `let(asObservableAction(action))` at the end of each effect, which state's needs to be observable.
 */
export function asObservableAction(action: Action) {
  return (source: Observable<any>): Observable<ObservableActionResult> => {
    return source.pipe(
      map(result => ObservableActionResult.success(action, result)),
      catchError(
        (error): Observable<any> => {
          return Observable.create(subscriber => {
            subscriber.next(ObservableActionResult.failed(action, error));
            subscriber.error(error);
            subscriber.complete();
          });
        }
      )
    );
  };
}
