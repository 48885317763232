<h2 *ngIf="showHeader">
  Noticeboard
  <small *ngIf="k2Only">
    <i class="iconfont lock"></i>
    notes only visible to K2
  </small>
</h2>

<h2 *ngIf="title">
  {{title}}
</h2>

<markdown-editor
  [id]="'markdown-editor'"
  [(value)]="_notes"
  [placeholder]="placeholder"
  [noPreview]="true"
  (valueChange)="onNotesChange($event)"
></markdown-editor>

<btn (click)="save.emit(notes)" icon="ok">Save changes</btn>
