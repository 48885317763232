import { Pipe, PipeTransform } from '@angular/core';
import { values } from 'ramda';

@Pipe({
  name: 'values'
})
export class ValuesPipe implements PipeTransform {
  transform(object): any[] {
    return values(object);
  }
}
