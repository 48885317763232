import { NgModule } from '@angular/core';
import { RoutingEffects } from '@k2/common/routing/effects';
import { routerStateSerializerProvider } from '@k2/common/routing/services/router-state-serializer';
import { EffectsModule } from '@ngrx/effects';
import { routerReducer, StoreRouterConnectingModule } from '@ngrx/router-store';
import { StoreModule } from '@ngrx/store';

/**
 * Needs to be imported after RouterModule and StoreModule
 */
@NgModule({
  imports: [
    StoreModule.forFeature('routerReducer', routerReducer),
    EffectsModule.forFeature([RoutingEffects]),
    StoreRouterConnectingModule.forRoot()
  ],
  providers: [routerStateSerializerProvider]
})
export class RoutingModule {}
