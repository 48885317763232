<div *ngIf="!showConfirmation" class="deletable-item-options">
  <ng-content></ng-content>

  <a *ngIf="deleteLink" [routerLink]="deleteLink" class="red-option">
    <span class="glyphicon glyphicon-remove visible-xs-inline" aria-hidden="true"></span>
    <span class="hidden-xs">remove</span>
  </a>
  <a *ngIf="!deleteLink" (click)="showConfirmation = true" class="red-option">
    <span class="glyphicon glyphicon-remove visible-xs-inline" aria-hidden="true"></span>
    <span class="hidden-xs">remove</span>
  </a>
</div>

<div *ngIf="showConfirmation" class="deletable-item-options d-flex flex-column align-items-center">
  <div>Are you sure?</div>
  <div>
    <a (click)="confirmDelete()" class="blue-option">
      <span class="glyphicon glyphicon-ok visible-xs-inline" aria-hidden="true"></span>
      <span class="hidden-xs">yes</span>
    </a>
    |
    <a (click)="showConfirmation = false" class="red-option">
      <span class="glyphicon glyphicon-remove visible-xs-inline" aria-hidden="true"></span>
      <span class="hidden-xs">no</span>
    </a>
  </div>
</div>
