<ul [hidden]="isHidden | async">
  <li>
    <button (click)="setPage(0)" [disabled]="cannotBeDecreased | async">
      <span class="glyphicon glyphicon-step-backward"></span>
    </button>
  </li>
  <li>
    <button (click)="decreasePage()" [disabled]="cannotBeDecreased | async">
      <span class="glyphicon glyphicon-triangle-left"></span>
    </button>
  </li>

  <li *ngFor="let page of numberOfPagesAsRange | async">
    <a (click)="setPage(page)" [class.active]="(activePage | async) === page">
      {{ page + 1 }}
    </a>
  </li>

  <li>
    <button (click)="increasePage()" [disabled]="cannotBeIncreased | async">
      <span class="glyphicon glyphicon-triangle-right"></span>
    </button>
  </li>
  <li>
    <button (click)="setPageToLast()" [disabled]="cannotBeIncreased | async">
      <span class="glyphicon glyphicon-step-forward"></span>
    </button>
  </li>
</ul>
