import { FieldSpec } from '@k2/common/k2-forms-state/types';

export function multiOptionsFormatter(field: FieldSpec): string {
  const options = field.attributes.valueOptions;
  const values = field.value || [];

  return values
    .map(value => {
      const selected = options.find(option => option.id === value);
      return selected.value;
    })
    .join('\n');
}
