import { subsidiarySchema } from '@k2/common/clients-state/subsidiaries/normalizer-schemas';
import { toUserId } from '@k2/common/state/schema-utils';
import { schema } from 'normalizr';

/**
 * Use userSchema if user can be "K2 user" or "client user".
 *
 * Use k2UserSchema if user can be only a "k2 user".
 * Use clientUserSchema if user can be only a "client user".
 *
 * All user schemas are actually a "userSchema" to be sure, that each user is normalized properly.
 * Even in situation, when user can be "k2 user" or "client user".
 */
export const userSchema = new schema.Entity(
  'users',
  {
    subsidiary: subsidiarySchema
  },
  { idAttribute: toUserId }
);

export const usersListSchema = new schema.Array(userSchema);

export const k2UserSchema = userSchema;
export const k2UsersListSchema = usersListSchema;

export const clientUserSchema = userSchema;
export const clientUsersListSchema = usersListSchema;

const officeSchema = new schema.Entity('offices', {
  contact: k2UserSchema
});

userSchema.define({
  office: officeSchema,
  reports_to: userSchema
});
