<div class="collapsible-block-headline">
  <h2>
    Noticeboard
    <small *ngIf="k2Only">
      <i class="iconfont lock"></i>
      notes only visible to K2
    </small>
  </h2>

  <a (click)="onExpandedChange($event)" class="toggle">
    <icon [type]="collapsed ? 'plus' : 'minus'"></icon>
  </a>
</div>

<markdown-editor
  [id]="'markdown-editor'"
  [ngClass]="collapsed && 'collapsed-editor'"
  [value]="_notes"
  (focusin)="onFocus()"
  (focusout)="onBlur()"
  [placeholder]="placeholder"
  [noPreview]="true"
  (valueChange)="onNotesChange($event)"
></markdown-editor>

<btn *ngIf="!collapsed" (click)="save.emit(this.notes); changed=false;" icon="ok" [loading]="loading">
  Save changes
</btn>
