import {
  Actions,
  ADD_ENTITIES,
  DELETE_ENTITIES,
  UPDATE_ENTITIES
} from '@k2/common/entities-state/actions';
import { Entities, Entity, EntityId, IdentifiedEntities } from '@k2/common/entities-state/entity';
import { initialState, State } from '@k2/common/entities-state/state';
import { mergeDeepRight, omit, pick, zipObj } from 'ramda';
import { UPDATE_USER } from '@k2/common/users-state/actions';
import { renameKeys } from '@k2/common/helpers';

export function featureReducer(state: State = initialState, action: Actions): State {
  switch (action.type) {
    case ADD_ENTITIES: {
      return {
        ...state,
        [action.entityType]: addEntities(state[action.entityType], action.entities)
      };
    }
    case UPDATE_ENTITIES: {
      return {
        ...state,
        [action.entityType]: updateEntities(state[action.entityType], action.entities)
      };
    }
    case DELETE_ENTITIES: {
      return {
        ...state,
        [action.entityType]: removeEntities(state[action.entityType], action.ids)
      };
    }
    case UPDATE_USER: {
      const propertyAliases = {
        telephone: 'telephone_work',
        mobile: 'telephone_mobile'
      };

      const properties = pick(
        [
          'first_name',
          'last_name',
          'avatar',
          'email',
          'job_title',
          'telephone',
          'telephone_extension',
          'mobile',
          'ustatus',
          'leaving_at',
          'reports_to'
        ],
        action.values
      );

      return mergeDeepRight(state, {
        users: {
          [action.userId]: renameKeys(propertyAliases, properties)
        }
      });
    }
    default: {
      return state;
    }
  }
}

function addEntities(state, entities: Entities) {
  return {
    ...state,
    ...normalizeEntities(entities)
  };
}

function updateEntities(state, entities: Entities) {
  return mergeDeepRight(state, normalizeEntities(entities));
}

function removeEntities(state, ids: EntityId[]) {
  const stringifiedIds = ids.map(id => id.toString());
  return omit(stringifiedIds, state);
}

function normalizeEntities(entities: Entities): IdentifiedEntities {
  if (areIdentifiedEntities(entities)) return entities;
  return toIdentifiedEntities(entities);
}

function areIdentifiedEntities(entities: Entities): entities is IdentifiedEntities {
  return !Array.isArray(entities);
}

function toIdentifiedEntities(entities: Entity[]): IdentifiedEntities {
  const keys = entities.map(e => e.id.toString());
  const data = entities.map(e => e.data);
  return zipObj(keys, data);
}
