<section outline>
  <h2>Billing</h2>

  <div class="form-group">
    <label>Currency:</label>
    <span>{{ currency.iso3 }} - {{ currency.name }}</span>
  </div>

  <div class="form-group">
    <label>Entity:</label>
    <span>{{ subsidiary.name }}</span>
  </div>

  <div class="form-group">
    <label>Address:</label>
    <address-block [address]="address"></address-block>
  </div>
</section>
