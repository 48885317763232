<h2 mat-dialog-title>{{ data.title }}</h2>

<mat-dialog-content>
  <div class="address-wrapper" *ngFor="let address of data.addresses">
    <address-block
      class="address-inner"
      [address]="address"
      (click)="select(address)"
    >
    </address-block>
  </div>
</mat-dialog-content>
