<mat-select
  #select
  [(value)]="value"
  [disabled]="disabled"
  [placeholder]="placeholder"
  (selectionChange)="changeValue()"
  (openedChange)="openedChange.emit($event)"
  [class.black-border]="select.focused"
  class="form-control"
  [class.disabled]="disabled"
  [multiple]="multiple"
>
  <mat-option *ngIf="!multiple">
    <ngx-mat-select-search
      placeholderLabel=""
      noEntriesFoundLabel="Nothing found"
      [hideClearSearchButton]="true"
      [formControl]="filterCtrl"
    ></ngx-mat-select-search>
  </mat-option>

  <mat-option
    *ngFor="let option of filteredOptions"
    [value]="option.id"
    [class.hidden]="!option.text"
  >
    {{ option.text }}
  </mat-option>
</mat-select>
