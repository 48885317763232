<autocomplete-option>
  <figure>
    <file-jet-img
      [image]="user.avatar"
      [width]="40"
      [height]="40"
    ></file-jet-img>
  </figure>

  <div [innerHTML]="user | userName | highlight: highlight" class="main"></div>
  <div>{{ user.job_title }}</div>
</autocomplete-option>
