<div [id]="field.attributes.id">
  <label>
    <input
      type="radio"
      [name]="field.name"
      [value]="true"
      [formControl]="field.control"
      [attr.disabled]="field.attributes.disabled ? 'disabled' : null"
    />
    <span>Yes</span>
  </label>

  <label>
    <input
      type="radio"
      [name]="field.name"
      [value]="false"
      [formControl]="field.control"
      [attr.disabled]="field.attributes.disabled ? 'disabled' : null"
    />
    <span>No</span>
  </label>
</div>
