/**
 * ⚠️⚠️⚠️ !!! STOP !!! ⚠️⚠️⚠️
 *
 * PLEASE MAKE SURE THAT YOU NEVER IMPORT THIS FILE DIRECTLY.
 * INSTEAD, PLEASE USE `appConfig.ts` to access any environment/configuration values.
 */
export const environment = {
  stage: 'stg',
  apiUrl: 'https://api.staging.k2staging.k2relo.com',
  filejet: {
    storageId: 'none',
    customDomain: 'https://cdn.k2staging.k2relo.com'
  },
  fullstoryAccountId: null
};
