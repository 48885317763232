import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { AppState } from '@k2/app-state';
import { Navigate } from '@k2/common/routing/actions';
import { getRealSession, getSessionType } from '@k2/common/sessions-state/reducers';
import { SessionType } from '@k2/common/sessions-state/session';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { first, map, tap } from 'rxjs/operators';
import { RefreshRealSession } from '@k2/common/sessions-state/actions';

@Injectable({ providedIn: 'root' })
export class AuthGuard  {
  constructor(private store: Store<AppState>) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.store.pipe(
      select(getRealSession),
      tap(session => {
        if (session == null) return;

        const { type, questionnaireHash } = session;

        if (type === 'ASSIGNEE') {
          this.store.dispatch(new Navigate(`/assignee`));
        } else if (type === 'CLIENT') {
          this.store.dispatch(new Navigate('/client'));
        } else if (type === 'STAFF') {
          this.store.dispatch(new Navigate('/staff'));
        }
      }),
      map(session => session == null)
    );
  }
}

@Injectable({ providedIn: 'root' })
export class AssigneeGuard  {
  constructor(private store: Store<AppState>, private router: Router) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | boolean {
    if (state.url.startsWith('/assignee/feedback')) return true;
    return guard(this.store, 'ASSIGNEE', state);
  }
}

@Injectable({ providedIn: 'root' })
export class ClientGuard  {
  constructor(private store: Store<AppState>) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return guard(this.store, 'CLIENT', state);
  }
}

@Injectable({ providedIn: 'root' })
export class StaffGuard  {
  constructor(private store: Store<AppState>) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return guard(this.store, 'STAFF', state);
  }
}

function guard(store: Store<AppState>, allowedType: SessionType, state: RouterStateSnapshot): Observable<boolean> {
  return store.pipe(
    select(getSessionType),
    first(),
    tap(type => {
      if (type) {
        if (type !== allowedType) store.dispatch(new Navigate('/auth'));
      } else {
        store.dispatch(new RefreshRealSession(state));
      }
    }),
    map(type => type === allowedType)
  );
}
