import { getDenormalized } from '@k2/common/entities-state/selectors';
import { Client, K2User, Task } from '@k2/common/entities-state/types';
import { getK2UserTeam } from '@k2/common/k2-users-state/common';
import { RootState } from '@k2/common/k2-users-state/state';
import { Actions, STORE_K2_USER_TASKS } from '@k2/common/k2-users-state/tasks/actions';
import { TasksState, UserTasksState } from '@k2/common/k2-users-state/tasks/state';
import {
  Actions as TasksActions,
  DELETE_TASK,
  MARK_TASK_AS_DONE,
  REASSIGN_TASKS
} from '@k2/common/tasks-state/actions';
import { taskSchema } from '@k2/common/tasks-state/normalizer-schemas';
import { Selector } from '@ngrx/store';
import { groupBy, identity, mapObjIndexed, values } from 'ramda';
import { createSelector } from 'reselect';

export function tasksReducer(state: TasksState = {}, action: Actions | TasksActions): TasksState {
  switch (action.type) {
    case STORE_K2_USER_TASKS: {
      const currentUserState = state[action.userId] || { clientTasks: null };

      return {
        ...state,
        [action.userId]: {
          myTasks: action.userTaskIds,
          clientTasks: action.clientTaskIds || currentUserState.clientTasks
        }
      };
    }
    case MARK_TASK_AS_DONE:
    case DELETE_TASK: {
      return removeUserTask(action.taskId, state);
    }
    case REASSIGN_TASKS: {
      return action.requests.reduce((state, request) => {
        const newState = removeUserTask(request.taskId, state);

        const currentTargetUserState = newState[request.userId] || {
          myTasks: [],
          clientTasks: null
        };

        return {
          ...newState,
          [request.userId]: {
            ...currentTargetUserState,
            myTasks: [...currentTargetUserState.myTasks, request.taskId]
          }
        };
      }, state);
    }
    default: {
      return state;
    }
  }
}

function removeUserTask(taskId: number, state: TasksState): TasksState {
  const map = mapObjIndexed((userState: UserTasksState) => ({
    ...userState,
    myTasks: userState.myTasks.filter(id => id !== taskId)
  }));

  return map(state);
}

export function getK2UserTasks(userId: number): Selector<RootState, Task[]> {
  return createSelector(
    [(state: RootState) => state.k2Users.tasks[userId], identity],
    (userTasksState, state) => {
      if (userTasksState != null) {
        return getDenormalized(taskSchema, userTasksState.myTasks)(state);
      }
    }
  );
}

export function getK2UserTeamTasks(userId: number): Selector<RootState, UserTasks[]> {
  return createSelector([getK2UserTeam(userId), identity], (team, state: RootState) => {
    if (team == null) return;

    return team
      .map(user => ({
        user,
        tasks: getK2UserTasks(user.id)(state)
      }))
      .filter(({ tasks }) => tasks != null);
  });
}

export interface UserTasks {
  user: K2User;
  tasks: Task[];
}

export function getK2UserClientTasks(userId: number): Selector<RootState, ClientTasks[]> {
  return createSelector(
    [(state: RootState) => state.k2Users.tasks[userId], identity],
    (userTasksState, state) => {
      if (userTasksState == null || userTasksState.clientTasks == null) return;

      const tasks = getDenormalized(taskSchema, userTasksState.clientTasks)(state);
      const byClient = groupBy((task: Task) => task.client.id.toString());

      return values(byClient(tasks)).map((tasks: Task[]) => ({ client: tasks[0].client, tasks }));
    }
  );
}

export interface ClientTasks {
  client: Client;
  tasks: Task[];
}

export function getK2UserClientTasksGroupedByUser(
  userId: number
): Selector<RootState, ClientUserTasks[]> {
  return createSelector(getK2UserClientTasks(userId), (groups: ClientTasks[]) => {
    if (groups == null) return;

    return groups
      .map(({ client, tasks }) => ({
        client,
        users: values(
          tasks.reduce(
            (byUser, task) => ({
              ...byUser,
              [task.assigned_to.id]: {
                user: task.assigned_to,
                tasks: [
                  ...(byUser[task.assigned_to.id] ? byUser[task.assigned_to.id].tasks : []),
                  task
                ]
              }
            }),
            {}
          )
        )
      }))
      .filter(({ users }) => users.length > 0);
  });
}

export interface ClientUserTasks {
  client: Client;
  users: UserTasks[];
}
