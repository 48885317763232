import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, Input, ViewChild } from '@angular/core';
import { animate, style, transition, trigger } from '@angular/animations';

@Component({
  selector: 'custom-tooltip',
  templateUrl: './tooltip.component.html',
  styleUrls: ['./tooltip.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('tooltip', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate(300, style({ opacity: 1 })),
      ]),
      transition(':leave', [
        animate(300, style({ opacity: 0 })),
      ]),
    ]),
  ]
})
export class TooltipComponent implements AfterViewInit {

  @ViewChild('mainContainer', { static: true }) elementView: ElementRef;

  @Input() text = '';
  @Input() listData: { title: string, value: string }[];
  @Input() config: any;

  ngAfterViewInit(): void {
    const containerRect = this.config.getBoundingClientRect();
    const tooltipRect = this.elementView.nativeElement.getBoundingClientRect();

    if (containerRect.top - tooltipRect.height < 0) {
      this.elementView.nativeElement.style.top = `${containerRect.bottom}px`;
    } else {
      this.elementView.nativeElement.style.top = `${containerRect.top - tooltipRect.height}px`;
    }

    const x = containerRect.left + containerRect.width / 2 - tooltipRect.width / 2;

    if (x > 0 && x + tooltipRect.width < window.innerWidth) {
      this.elementView.nativeElement.style.left = `${x}px`;
    } else if (x + tooltipRect.width > window.innerWidth) {
      this.elementView.nativeElement.style.right = `0`;
    }
  }
}
