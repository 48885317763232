import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { doughnutColors } from '@k2/common/k2-charts/colors';
import { CustomizedGraphConfig } from '@k2/common/k2-charts/utils';
import { BaseChartDirective } from 'ng2-charts';

@Component({
  selector: 'doughnut-chart',
  templateUrl: 'doughnut-chart.component.html',
  styleUrls: ['doughnut-chart.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DoughnutChartComponent implements OnChanges {
  @Input() config: DoughnutChartConfig;

  @ViewChild(BaseChartDirective) chartDirective: BaseChartDirective;

  legend: SafeHtml;

  constructor(private changeDetector: ChangeDetectorRef, private sanitizer: DomSanitizer) {
  }

  private get chart() {
    return this.chartDirective ? this.chartDirective.chart : null;
  }

  ngOnChanges(changes: SimpleChanges): void {
    setTimeout(() => {
      if (this.chart != null) {
        // this.legend = this.sanitizer.bypassSecurityTrustHtml(this.chart.generateLegend() as any);
        this.changeDetector.markForCheck();
      }
    });
  }

  get data() {
    return {
      labels: this.config.labels,
      datasets: [
        {
          data: this.config.data,
          ...this.colors[0]
        }
      ]
    };
  }

  get labels() {
    return this.config.labels;
  }

  get options() {
    return this.config.options || {};
  }

  get colors() {
    return doughnutColors;
  }

  get height(): number | undefined {
    return this.config.options ? this.config.options.height : undefined;
  }
}

export interface DoughnutChartConfig extends CustomizedGraphConfig {
  readonly data: number[];
  readonly labels: string[];
}
