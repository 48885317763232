import { ActivatedRoute } from '@angular/router';
import {
  all,
  always,
  assoc,
  complement,
  concat,
  curry,
  fromPairs,
  identity,
  isEmpty,
  isNil,
  keys,
  pickBy,
  reduce,
  times,
  toPairs,
  unapply,
  values
} from 'ramda';
import {
  combineLatest,
  isObservable,
  Observable,
  of,
  ReplaySubject,
  Subject,
  Subscription
} from 'rxjs';
import { first, map, shareReplay } from 'rxjs/operators';

/**
 * Converts a given function to safe selector,
 * which returns `undefined` when any property in access chain is undefined.
 */
export function asSafe<T extends Function>(fn: T): T {
  return ((...args) => safe(() => fn(...args))) as any;
}

/**
 * Returns a result of a given `fn` or `fallbackValue`.
 *
 * If evaluation fails due to TypeError (something in access chain is undefined),
 * then a `fallbackValue` will be returned.
 *
 * NOTE: Default fallback value is `undefined`.
 */
export function safe<T, S = undefined>(fn: () => T, fallbackValue?: S): T | S {
  try {
    return fn();
  } catch (e) {
    if (e instanceof TypeError) return fallbackValue;
    throw e;
  }
}

/**
 * Replays the latest item with `refCount()`.
 */
export function replayLast<T>(): (observable: Observable<T>) => Observable<T> {
  return shareReplay({ bufferSize: 1, refCount: true });
}

/**
 * Combines an object of observables to one observable,
 * which emits object with the latest values from all observables.
 *
 * Basically a `combineLatest` with object instead of array.
 */
export function combineLatestObj<T extends object, S = any>(object: T): Observable<S> {
  const sources = values(object);
  const keys = Object.keys(object);

  return combineLatest(sources).pipe(
    map(args => fromPairs(args.map((value, i) => [keys[i], value]) as any))
  ) as any;
}

/**
 * Calls a given `updateFn` with the first value from `subject`
 * and then publish the result to the same subject.
 */
export function alterSubjectValue<T>(subject: Subject<T>, updateFn: (value: T) => T) {
  subject.pipe(first(), map(updateFn)).subscribe(pipeValuesTo(subject));
}

/**
 * Returns function, which will pipe a given value to the given `subject`.
 */
export function pipeValuesTo<T>(subject: Subject<T>) {
  return (value: T) => subject.next(value);
}

/**
 * Transforms a given observable into RxJs Subject.
 *
 * Errors emitted by a given observable will
 * not propagate and will not cancel a returned Subject.
 */
export function asValuesSubject<T>(observable: Observable<T>): Subject<T> {
  const subject = new Subject<T>();
  observable.subscribe(pipeValuesTo(subject));
  return subject;
}

/**
 * Renames object's properties.
 *
 * EXAMPLE:
 *  renameKeys({ a: 'b' }, { a: 'data' }); //=> { b: 'data' }
 */
export const renameKeys = curry((keysMap, obj) =>
  reduce((acc, key) => assoc(keysMap[key] || key, obj[key], acc), {}, keys(obj))
);

/**
 * Async identity function.
 * Returns a given `value` wrapped into resolved Promise.
 */
export async function asyncIdentity<T>(value: T): Promise<T> {
  return value;
}

/**
 * Converts arguments to array.
 *
 * list(1, 2, 3) => [1, 2, 3]
 */
export const list = unapply(identity);

/**
 * Returns true when value is not null or undefined.
 */
export const isNotNil = complement(isNil);

/**
 * Returns true when value is not empty.
 */
export const isNotEmpty = complement(isEmpty);

/**
 * Returns true if all elements of the list are empty.
 */
export const isAllEmpty = all(isEmpty);

/**
 * Returns true when a given element has no meaningful child.
 */
export function isElementEmpty(el: HTMLElement): boolean {
  const nodes = Array.from(el.childNodes);
  const nonEmptyTextNodes = nodes.filter(
    ({ nodeType, textContent }) => nodeType === Node.TEXT_NODE && textContent.trim() !== ''
  );

  if (nonEmptyTextNodes.length > 0) return false;

  return nodes
    .filter(({ nodeType }) => nodeType === Node.ELEMENT_NODE)
    .map(
      node =>
        node.nodeName !== 'ICON' &&
        node.nodeName !== 'INPUT' &&
        node.nodeName !== 'SELECT' &&
        node.nodeName !== 'TEXTAREA' &&
        isElementEmpty(node as HTMLElement)
    )
    .every(isEmpty => isEmpty);
}

/**
 * Returns true when value is a string
 */
export function isString(value: any): boolean {
  return typeof value === 'string';
}

/**
 * Returns true when value is not string.
 */
export const isNotString = complement(isString);

/**
 * Always returns undefined.
 */
export const toUndefined = always(undefined);

/**
 * Reduce a given 2D array to 1D array.
 */
export const reduceArray: <T>(arr: T[][]) => T[] = reduce(concat, [] as any);

/**
 * Returns a partial copy of an object containing only the keys
 * which values are not null or undefined.
 */
export const omitNils: <T>(obj: T) => T = pickBy(isNotNil);

/**
 * Returns delta between the smallest and the biggest number.
 */
export function maximumDelta(values: number[]): number {
  return Math.abs(minimum(values) - maximum(values));
}

/**
 * Returns the smallest number.
 */
export function minimum(values: number[]): number {
  return values.reduce((min, value) => (min > value ? value : min), Infinity);
}

/**
 * Returns the biggest number.
 */
export function maximum(values: number[]): number {
  return values.reduce((max, value) => (max < value ? value : max), -Infinity);
}

/**
 * Escapes the `RegExp` special characters "^", "$", "\", ".", "*", "+",
 * "?", "(", ")", "[", "]", "{", "}", and "|" in `string`.
 */
export function escapeRegExp(string: string): string {
  const reRegExpChar = /[\\^$.*+?()[\]{}|]/g;
  const reHasRegExpChar = RegExp(reRegExpChar.source);

  return string && reHasRegExpChar.test(string) ? string.replace(reRegExpChar, '\\$&') : string;
}

/**
 * Extracts an `id` parameter from a given `route`.
 */
export function extractIdFromRoute(route: ActivatedRoute): Observable<number> {
  return route.paramMap.pipe(map(params => Number(params.get('id'))));
}

/**
 * Extracts an `uniqId` parameter from a given `route`.
 */
export function extractUniqIdFromRoute(route: ActivatedRoute): Observable<string> {
  return route.paramMap.pipe(map(params => String(params.get('uniqId'))));
}

/**
 * Extracts an `page` query parameter from a given `route`.
 */
export function extractPageFromRoute(route: ActivatedRoute): string {
  const PARAMS = route.snapshot.queryParams;

  return PARAMS ? PARAMS.page : null;
}

/**
 * Returns Date which is behind of the current date by a given `days`.
 */
export function subtractFromNow(days: number): Date {
  const date = new Date();
  date.setDate(date.getDate() - days);
  return date;
}

/**
 * Returns Date which is ahead of the current date by a given `days`.
 */
export function addToNow(days: number): Date {
  const date = new Date();
  date.setDate(date.getDate() + days);
  return date;
}

/**
 * Returns date without time in ISO format.
 * EXAMPLE OUTPUT:
 *   "2017-10-05"
 */
export function toISODate(date: Date): string {
  return date.toISOString().substring(0, 10);
}

/**
 * Returns the current dateTime in an ISO format.
 */
export function now(): string {
  return new Date().toISOString();
}

/**
 * Wraps a given `value` to array.
 */
export function liftToArray<T>(value: T): T[] {
  return [value];
}

/**
 * Transforms a given array to object where keys will be item's indexes.
 *
 * EXAMPLE:
 *  ['A', 'B'] => { 0: 'A', 1: 'B" }
 */
export function arrayToObject<T>(array: T[]): { [id: number]: T } {
  return fromPairs(array.map((v, i) => [i, v]) as any) as any;
}

/**
 * Updates a size of a given array.
 *
 * If an array is bigger than required, then items at the end will be omitted.
 * If an array is smaller than required, then a new values will be added to the end.
 */
export function resizeArray<T>(size: number, newValue: () => T, list: T[]): T[] {
  const delta = size - list.length;
  if (delta === 0) return list;
  if (delta < 0) return list.slice(0, size);

  const defaults = times(newValue, delta);
  return [...list, ...defaults];
}

/**
 * Calls a given function with no arguments and returns it's result.
 */
export function call<T>(fn: () => T): T {
  return fn();
}

/**
 * Returns function, which will call a given `fn` with a given `value`.
 */
export function callWith<T, S>(value: T) {
  return (fn: (value: T) => S): S => fn(value);
}

/**
 * Returns function, which will sort a given `object` by a given `compareFn`.
 */
export function sortObj<T>(compareFn: (a: T, b: T) => number) {
  return <S extends { [index: string]: T }>(object: S): S => {
    return fromPairs(toPairs(object).sort((a, b) => compareFn(a[1], b[1]))) as any;
  };
}

/**
 * Returns TRUE when a given `value` is a plain object.
 */
export function isPlainObject(value: any): boolean {
  return value != null && typeof value === 'object' && !Array.isArray(value);
}

/**
 * Rounds a given `value` to the given `step`.
 *
 * EXAMPLE:
 *   roundToStep(1.226, 0.01) // 1.23
 */
export function roundToStep(value: number, step: number): number {
  const stringifiedStep = step.toString();
  const decimalPos = stringifiedStep.indexOf('.');
  const precision = decimalPos === -1 ? 0 : stringifiedStep.length - decimalPos - 1;
  return Number((Math.round(value / step) * step).toFixed(precision));
}

/**
 * Memoize the last result for the last arguments set.
 *
 * Any change to the arguments will fire the recomputation.
 */
export function memoizeLast<T extends (...args: any[]) => any>(fn: T): T {
  let lastArgs: any[];
  let lastResult: any;

  return ((...args) => {
    if (lastArgs == null || !haveIdenticalContents(lastArgs, args)) {
      lastArgs = args;
      lastResult = fn(...args);
    }
    return lastResult;
  }) as T;
}

/**
 * Return TRUE, when the contents of a given two arrays are identical.
 */
export function haveIdenticalContents(a: any[], b: any[]): boolean {
  if (a === b) return true;
  if (a.length !== b.length) return false;

  for (let i = 0; i < a.length; i++) {
    if (a[i] !== b[i]) return false;
  }

  return true;
}

/**
 * Returns a value for a given `key` in a cookie.
 */
export function getCookieItem(key: string): string | null {
  const normalizedKey = encodeURIComponent(key).replace(/[-.+*]/g, '\\$&');

  return (
    decodeURIComponent(
      document.cookie.replace(
        new RegExp(`(?:(?:^|.*;)\\s*${normalizedKey}\\s*\\=\\s*([^;]*).*$)|^.*$`),
        '$1'
      )
    ) || null
  );
}

/**
 * Ensures that a given value is observable.
 *
 * If not, then it will be converted into observable.
 */
export function ensureObservable<T>(value: T | Observable<T>): Observable<T> {
  return isObservable(value) ? value : of(value);
}

/**
 * RxJS subject which always emits the latest value for every consumer.
 */
export class ReplayLastSubject<T> extends ReplaySubject<T> {
  constructor() {
    super(1);
  }
}

/**
 * A list of subscriptions which could be unsubscribed at once.
 */
export class Subscriptions {
  private subscriptions: Subscription[] = [];

  add = (...subscriptions: Subscription[]) => this.subscriptions.push(...subscriptions);

  unsubscribe = () => {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
    this.subscriptions = [];
  };
}

/**
 * Uppercase the first letter.
 */
export function capitalize(text: string): string {
  return text[0].toLocaleUpperCase() + text.slice(1);
}

/**
 * A `Set` with additional `toggle` method.
 */
export class DataSet<T> {
  private data = new Set<T>();

  get size() {
    return this.data.size;
  }

  add = (value: T) => this.data.add(value);
  delete = (value: T) => this.data.delete(value);
  toggle = (value: T) => {
    if (this.data.has(value)) {
      this.data.delete(value);
    } else {
      this.data.add(value);
    }
  };
  has = (value: T) => this.data.has(value);
  clear = () => this.data.clear();
  forEach = (callbackfn: (value: T, value2: T, set: Set<T>) => void, thisArg?: any): void => {
    this.data.forEach(callbackfn);
  };
  toArray = (): T[] => Array.from(this.data.values());
}

/**
 * Returns the primitive value or the value returned by `extract` function.
 */
export function alwaysPrimitive<T, S extends string | number>(
  value: T,
  extract: (value: NonPrimitive<T>) => S
): Primitive<T> | S {
  if (isPrimitive(value)) return value;
  return extract(value as NonPrimitive<T>);
}

/**
 * Returns true when the given value is a primitive value.
 */
export function isPrimitive<T>(value: T): value is Primitive<T> {
  return typeof value === 'number' || typeof value === 'string';
}

type Primitive<T> = T extends string | number ? T : never;
type NonPrimitive<T> = T extends string | number ? never : T;
