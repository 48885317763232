import { Field } from '@k2/common/k2-forms-state/types';

export function multiSelectFormatter(field: Field): string {
  const values = field.control.value || [];
  let results = '';

  for (let i = 0; i < values.length; i++) {
    let newLine = '';
    let selectedValues = values[i];

    let item1 = field.attributes.valueOptions[0].items.find(
      option => option.id === selectedValues[0]
    );
    let item2 = field.attributes.valueOptions[1].items.find(
      option => option.id === selectedValues[1]
    );

    newLine = [item1.value, item2.value].join(' - ');
    results = [results, newLine].join('\n');
  }

  return results;
}
