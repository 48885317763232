import { Component, Input } from '@angular/core';
import { Field } from '@k2/common/k2-forms-state/types';

@Component({
  selector: 'yes-no-control',
  templateUrl: 'yes-no-control.component.html',
  styleUrls: ['yes-no-control.component.scss']
})
export class YesNoControlComponent {
  @Input() field: Field<boolean>;
}
