import { HostBinding, Input, Directive } from '@angular/core';

@Directive()
export class HComponent {
  // Renders heading in slim mode. Slim heading usually excludes a top margin.
  @Input() slim?: undefined | '';

  @HostBinding('style.margin-top')
  get marginTop() {
    return this.slim === '' ? 0 : undefined;
  }
}
