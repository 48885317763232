<div class="d-flex align-items-center">
  <input
    type="text"
    [(ngModel)]="momentValue"
    [placeholder]="placeholder"
    [matDatepicker]="datepicker"
    [min]="minDate"
    [max]="getMaxDate()"
    (focus)="datepicker.open()"
    (click)="datepicker.open()"
    class="form-control"
    (ngModelChange)="onValueChange()"
  />

  <mat-icon
    *ngIf="clearable"
    matDatepickerToggleIcon
    (click)="clearValue()"
  >
    clear
  </mat-icon>

  <mat-datepicker #datepicker></mat-datepicker>
</div>
