import { ApiClient } from '@k2/common/backend/api-client';

export class EventsCalendarEndpoint {
  constructor(private api: ApiClient) {}

  fetchEventsCalendar = () => {
    const today = new Date();
    const dateFrom =
      'date_from/' + today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
    return this.api.get(`/staff/events-calendar/` + dateFrom);
  };

  filterEventsCalendar = filters => {
    const path = [
      `/staff/events-calendar`,
      ...filters.map(({ name, value }) => `${name}/${encodeURIComponent(value)}`)
    ].join('/');

    return this.api.get(path);
  };

  fetchEventsCalendarEvent = (eventsCalendarEventId: number) => {
    return this.api.get(`/staff/events-calendar/event/${eventsCalendarEventId}`);
  };
}
