<annotated-control [field]="field">
  <input
    type="email"
    [id]="field.attributes.id"
    [formControl]="field.control"
    [maxlength]="255"
    [placeholder]="field.attributes.placeholder || field.attributes.label || ''"
    class="form-control"
  />
</annotated-control>
