import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ComponentSpec } from '@k2/common/ui/component-spec';

/**
 * A wrapper component for a list of components.
 *
 * There are two ways, how to provide a components:
 *   1. [specs] input
 *   2. ng-content
 *
 * Both ways can be combined and they will be rendered in an order specified above.
 *
 * It is highly recommended to use this wrapper if you want to keep a design of
 * a lists of the same components consistent.
 *
 * EXAMPLE:
 *   <components-list>
 *     <user-ingot [user]="user1"></user-ingot>
 *     <user-ingot [user]="user2"></user-ingot>
 *   </components-list>
 */
@Component({
  selector: 'components-list',
  templateUrl: 'components-list.component.html',
  styleUrls: ['components-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ComponentsListComponent {
  @Input() specs?: ComponentSpec[];
  @Input() flow: Flow = 'wrap';
  @Input() density: Density = 'normal';

  get class() {
    return `content ${this.density}-density ${this.flow}-flow`;
  }
}

export type Flow = 'row' | 'column' | 'wrap';
export type Density = 'high' | 'normal' | 'low';
