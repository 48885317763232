import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'star-icon',
  templateUrl: 'star-icon.component.html',
  styleUrls: ['star-icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class StarIconComponent {
  @Input() serviceLevelTypesId: number | string;

  get className(): 'vip' | 'bespoke' | null {
    if (this.serviceLevelTypesId.toString() === '1797') {
      return 'vip';
    } else if (this.serviceLevelTypesId.toString() === '1798') {
      return 'bespoke';
    }
    return null;
  }
}
