import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import {
  PartnerAutocompleteOptionComponent
} from '@k2/common/partners/component/partner-autocomplete-option/partner-autocomplete-option.component';
import { PartnerFeedbackComponent } from '@k2/common/partners/component/partner-feedback/partner-feedback.component';
import {
  PartnerFinderDialogComponent
} from '@k2/common/partners/component/partner-finder/partner-finder-dialog.component';
import { PartnerFinderComponent } from '@k2/common/partners/component/partner-finder/partner-finder.component';
import { PartnerIngotComponent } from '@k2/common/partners/component/partner-ingot/partner-ingot.component';
import {
  PartnerTransitServiceFinderDialogComponent
} from '@k2/common/partners/component/partner-transit-service-finder/partner-transit-service-finder-dialog.component';
import {
  PartnerTransitServiceFinderComponent
} from '@k2/common/partners/component/partner-transit-service-finder/partner-transit-service-finder.component';
import { PartnersSelectorComponent } from '@k2/common/partners/component/partners-selector/partners-selector.component';
import { SharedModule } from '@k2/common/shared.module';
import { PartnerStatsComponent } from '@k2/common/partners/component/partner-stats/partner-stats.component';
import { UsersModule } from '@k2/common/users/users.module';
import {
  PartnerIngotBaseComponent
} from '@k2/common/partners/component/partner-ingot/base/partner-ingot-base.component';

const components = [
  PartnerFeedbackComponent,
  PartnersSelectorComponent,
  PartnerIngotComponent,
  PartnerIngotBaseComponent,
  PartnerAutocompleteOptionComponent,
  PartnerFinderComponent,
  PartnerTransitServiceFinderComponent,
  PartnerFinderDialogComponent,
  PartnerTransitServiceFinderDialogComponent,
  PartnerStatsComponent
];

@NgModule({
  imports: [SharedModule, FormsModule, UsersModule],
  declarations: components,
  exports: components
})
export class PartnersModule {
}
