import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Partner } from '@k2/common/entities-state/types';

@Component({
  selector: 'partner-ingot',
  templateUrl: 'partner-ingot.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PartnerIngotComponent {
  @Input() partner: Partner;
}
