/**
 * Normalize URL for permissions.
 *
 * Returns a given URL without non-number parts at the end.
 *
 * EXAMPLE 1:
 *   "/dashboard/user/team/staff/profile/1/personal-details/edit"
 *
 *   transforms to
 *
 *   "/dashboard/user/team/staff/profile/1"
 *
 * EXAMPLE 2:
 *   "/dashboard/user/team/staff/profile"
 *
 *   transforms to
 *
 *   "/"
 */
export function normalizeUrl(url: string): string {
  const parts = url.split('/');
  const lastNumberPartIndex = parts.reverse().findIndex((part: any) => !isNaN(part));

  if (lastNumberPartIndex === parts.length - 1) return '/';
  return parts
    .slice(lastNumberPartIndex, parts.length)
    .reverse()
    .join('/');
}
