<div class="search-input">
  <input
    type="text"
    [matAutocomplete]="auto"
    [formControl]="searchControl"
    placeholder="Search"
    class="form-control"
  />
  <icon type="search"></icon>
</div>

<mat-autocomplete #auto="matAutocomplete">
  <mat-option *ngFor="let option of options | async" [value]="option">
    <ng-template component-host [spec]="option.spec"></ng-template>
  </mat-option>
</mat-autocomplete>
