import {
  HTTP_INTERCEPTORS,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse
} from '@angular/common/http';
import { Injectable, Provider } from '@angular/core';
import { AddPermissions } from '@k2/common/permissions-state/actions';
import { RootState } from '@k2/common/permissions-state/state';
import { normalizeUrl } from '@k2/common/permissions-state/utils';
import { getCurrentUrl } from '@k2/common/routing/selectors';
import { AppStore } from '@k2/common/state/services/app-store';
import { select } from '@ngrx/store';
import { Observable } from 'rxjs';
import { first, tap } from 'rxjs/operators';

@Injectable()
export class PermissionsHttpInterceptor implements HttpInterceptor {
  constructor(private store: AppStore<RootState>) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      tap((response: HttpResponse<any>) => {
        if (!hasPermissions(response)) return;

        this.store.pipe(select(getCurrentUrl), first()).subscribe(currentUrl => {
          const normalizedUrl = normalizeUrl(currentUrl);
          const permissions = toPermissions(response);

          this.store.dispatch(new AddPermissions(normalizedUrl, permissions));
        });
      })
    );
  }
}

function hasPermissions(response: HttpResponse<any>): boolean {
  try {
    return toPermissions(response) != null;
  } catch (e) {
    if (e instanceof TypeError) return false;
    throw e;
  }
}

function toPermissions(response: HttpResponse<any>): string[] {
  return response.body.meta.permissions;
}

export const permissionsHttpInterceptorProvider: Provider = {
  provide: HTTP_INTERCEPTORS,
  useClass: PermissionsHttpInterceptor,
  multi: true
};
