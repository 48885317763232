<assignee-family-person-icon
  type="Assignee"
  [person]="assignee"
  [link]="assigneeLink"
  [isAssignmentEncrypted]="isAssignmentEncrypted"
  class="icon"
></assignee-family-person-icon>

<assignee-family-person-icon
  *ngIf="assignee.party.partner"
  type="Partner"
  [person]="assignee.party.partner"
  [link]="familyLink"
  [isAssignmentEncrypted]="isAssignmentEncrypted"
  class="icon"
></assignee-family-person-icon>

<assignee-family-person-icon
  *ngFor="let person of assignee.party.other"
  type="Other"
  [person]="person"
  [link]="familyLink"
  [isAssignmentEncrypted]="isAssignmentEncrypted"
  class="icon"
></assignee-family-person-icon>

<assignee-family-person-icon
  *ngFor="let person of assignee.party.children"
  type="Child"
  [person]="person"
  [link]="familyLink"
  [isAssignmentEncrypted]="isAssignmentEncrypted"
  class="icon"
></assignee-family-person-icon>

<assignee-family-pet-icon
  *ngFor="let pet of assignee.party.pets"
  [pet]="pet"
  [link]="petsLink"
  class="icon"
></assignee-family-pet-icon>
