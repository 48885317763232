import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnChanges,
  SimpleChanges,
  ViewChild
} from '@angular/core';

/**
 * Provides a wrapper for a simple text.
 *
 * When text's width is bigger than component's width,
 * then truncates a given text and provides a tooltip with a whole text.
 *
 * EXAMPLE:
 *   <inline-text [text]="someLongText" style="max-width: 150px"></inline-text>
 */
@Component({
  selector: 'inline-text',
  templateUrl: 'inline-text.component.html',
  styleUrls: ['inline-text.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InlineTextComponent implements OnChanges {
  @Input() text: string;

  @ViewChild('content', { static: true }) element: ElementRef;

  tooltip: string;

  constructor(private changeDetector: ChangeDetectorRef) {}

  ngOnChanges(changes: SimpleChanges): void {
    const native = this.element.nativeElement;

    setTimeout(() => {
      if (native.offsetWidth < native.scrollWidth) {
        this.tooltip = this.text;
      } else {
        this.tooltip = null;
      }

      this.changeDetector.markForCheck();
    });
  }
}
