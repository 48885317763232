import { asSafe } from '@k2/common/helpers';
import { Actions as SessionActions, REFRESH_REAL_SESSION_SUCCESS, STORE_REAL_SESSION } from '@k2/common/sessions-state/actions';
import { Session } from '@k2/common/sessions-state/session';
import { RootState, State } from '@k2/common/sessions-state/state';
import { Actions as UserActions, UPDATE_USER, UpdateUser } from '@k2/common/users-state/actions';
import { mergeDeepRight, pipe } from 'ramda';

type Actions = SessionActions | UserActions;

export function featureReducer(state: State = {}, action: Actions): State {
  switch (action.type) {
    case STORE_REAL_SESSION: {
      return { ...state, real: action.session };
    }
    case UPDATE_USER: {
      const update = pipe(tryUpdateUser(action, 'real'));
      return update(state);
    }
    case REFRESH_REAL_SESSION_SUCCESS: {
      return { ...state, real: action.session };
    }
    default: {
      return state;
    }
  }
}

function tryUpdateUser(action: UpdateUser, type: keyof State) {
  return (state: State): State => {
    if (state[type] == null) return state;
    if (action.userId !== state[type].user.id) return state;

    return mergeDeepRight(state, {
      [type]: {
        user: { ...state[type].user, ...action.values }
      }
    });
  };
}

export function getRealSession(state: RootState): Session {
  const session = toRealSession(state);
  if (session != null) return session;
}

export function getSession(state: RootState): any {
  const session = toSession(state);
  if (session != null) return session;
}

export const getSessionUser = pipe(
  getRealSession,
  asSafe(s => s.user)
);
export const getSessionUserId = pipe(
  getRealSession,
  asSafe(s => s.user.id)
);
export const getSessionUserAvatar = pipe(
  getRealSession,
  asSafe(s => s.user.avatar)
);
export const getSessionClientId = pipe(
  getRealSession,
  asSafe(s => s.clientId)
);
export const getSessionType = pipe(
  getRealSession,
  asSafe(s => s.type)
);

function toRealSession(state: RootState): Session {
  return state.sessions.real;
}

function toSession(state: RootState): any {
  return state.sessions;
}
