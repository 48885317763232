import { ChangeDetectionStrategy, Component, Input, OnChanges } from '@angular/core';
import {
  hasMultiplePages,
  Paginator,
  toActivePage,
  toNumberOfPages
} from '@k2/common/pagination/paginator';
import { complement, range } from 'ramda';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'paginator',
  templateUrl: 'paginator.component.html',
  styleUrls: ['paginator.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PaginatorComponent implements OnChanges {
  @Input() paginator: Paginator;

  numberOfPagesAsRange: Observable<number[]>;
  isHidden: Observable<boolean>;
  activePage: Observable<number>;
  cannotBeDecreased: Observable<boolean>;
  cannotBeIncreased: Observable<boolean>;

  ngOnChanges(): void {
    const { state } = this.paginator;

    const numberOfPages = state.pipe(map(toNumberOfPages));

    this.numberOfPagesAsRange = numberOfPages.pipe(map(n => range(0, n)));
    this.isHidden = state.pipe(map(complement(hasMultiplePages)));
    this.activePage = state.pipe(map(toActivePage));

    this.cannotBeDecreased = this.activePage.pipe(map(page => page === 0));
    this.cannotBeIncreased = combineLatest(this.activePage, numberOfPages).pipe(
      map(([activePage, numberOfPages]) => activePage === numberOfPages - 1)
    );
  }

  setPage = (page: number) => {
    this.paginator.updateBy(state => ({
      offset: page * state.pageSize,
      limit: state.pageSize
    }));
  };

  setPageToLast = () => {
    this.paginator.updateBy(state => {
      const lastPage = toNumberOfPages(state) - 1;

      return {
        offset: lastPage * state.pageSize,
        limit: state.pageSize
      };
    });
  };

  decreasePage = () => {
    this.paginator.updateBy(state => {
      const offset = state.offset - state.pageSize;

      if (offset < 0) return state;
      return { offset, limit: state.pageSize };
    });
  };

  increasePage = () => {
    this.paginator.updateBy(state => {
      const offset = state.offset + state.pageSize;

      if (offset >= state.length) return state;
      return { offset, limit: state.pageSize };
    });
  };
}
