<div>
  <formatted-address
    [address]="address"
    [reducedFormat]="reducedFormat"
    [isAssignmentEncrypted]="isAssignmentEncrypted">
  </formatted-address>
  <tag *ngIf="showStatus" [value]="address.status"></tag>
</div>

<div class="right">
  <div><ng-content select="[buttons]"></ng-content></div>

  <div *ngIf="!isAssignmentEncrypted" class="map-link">
    <untrusted-link
      [href]="address | gMapLink"
      (click)="$event.stopPropagation()"
    >
      <icon type="map-marker"></icon>
    </untrusted-link>
  </div>
</div>
