import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { FilterOption, ignoreValue } from '@k2/common/filters';
import { K2SelectOption } from '@k2/common/k2-select/k2-select-option';

@Component({
  selector: 'k2-select-filter',
  templateUrl: 'k2-select-filter.component.html',
  styleUrls: ['k2-select-filter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class K2SelectFilterComponent {
  @Input() control: UntypedFormControl;
  @Input() options: FilterOption[];
  @Input() placeholder = 'all';
  @Output() onChange = new EventEmitter<string | number>();

  constructor(private cdr: ChangeDetectorRef) {}

  get selectOptions(): K2SelectOption[] {
    const options = this.options || [];
    const select2Options = options.map(option => ({
      id: option.value,
      text: option.label
    }));

    if (this.placeholder === null) return select2Options;

    return [{ id: ignoreValue, text: this.placeholder }, ...select2Options];
  }

  setValue(value: any, emitEvent = true) {
    this.control.patchValue(value, { emitEvent });
    this.onChange.emit(value);
    this.cdr.detectChanges();
  }
}
