import { ApiClient } from '@k2/common/backend/api-client';
import { Session } from '@k2/common/sessions-state/session';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

export class SessionsEndpoint {
  constructor(private readonly api: ApiClient) {}

  login = (params: { email: string }): Observable<{ url: string; testMode: string }> => {
    return this.api.post('/user/login-email', params).pipe(
      map(payload => ({
        url: payload.url,
        testMode: payload.test_mode
      }))
    );
  };

  loginPassword = (
    email: string,
    password: string
  ): Observable<{ requires2fa: boolean; session: Session }> => {
    return this.api.post('/user/login-password', { email, password }).pipe(
      map(payload => ({
        requires2fa: payload.requires2fa,
        session: toSession(payload)
      }))
    );
  };

  fetchSessionInfo = (): Observable<Session> => {
    return this.api.post('/session-verify', {}).pipe(
      map(payload => toSession(payload))
    );
  }

  submit2faCode(code: string) {
    return this.api.post('/user/login/2fa', { code });
  }

  submit2faRecoveryCode(code: string) {
    return this.api.post('/user/login/2fa-recovery', { code });
  }

  logout = (): Observable<void> => {
    return this.api.delete(`/user/logout`);
  };

  requestPasswordReset = (email: string): Observable<void> => {
    return this.api.post('/user/password/reset', { email });
  };

  resetPassword = (params: { email: string; password: string; resetCode: string }) => {
    return this.api.post('/user/password/new', params).pipe(map(payload => payload.session));
  };

  authenticateSuccessToken = (successToken: string) => {
    return this.api.get(`/auth/sso/saml/sso-validate/token/${successToken}`).pipe(
      map(payload => ({
        session: payload.session ? toSession(payload) : null,
        form: payload.form
      }))
    );
  };

  fetchClientUserMissingInformationForm = () => {
    return this.api.get(`/auth/sso/saml/account-information`).pipe(map(payload => payload.form));
  };

  updateClientUserMissingInformation = (data: any) => {
    return this.api.patch(`/auth/sso/saml/account-information`, data);
  };

  authenticateTest = () => {
    return this.api.get(`/auth/sso/saml/test/state`).pipe(
      map(payload => ({
        loginState: payload.loginState,
        userEmail: payload.userEmail,
        userGivenName: payload.userGivenName,
        userSurname: payload.userSurname
      }))
    );
  };

  authenticateLogout = () => {
    return this.api.get(`/auth/saml/logout`).pipe(
      map(payload => ({
        url: payload.url
      }))
    );
  };

  fetchCurrentSessions = () => {
    return this.api.get('/sessions');
  };

  deleteSessions = (sessionIds: string | string[]) => {
    const ids = Array.isArray(sessionIds) ? sessionIds.join(',') : sessionIds;

    return this.api.delete(`/sessions/${ids}`).pipe(map(payload => payload.sessions));
  };
}

function toSession({ session }): Session {
  return {
    user: session.user,
    type: session.type,
    redirectTo: session.redirectTo,
    usersId: session.usersId,
    clientId: session.clientId,
    questionnaireHash: session.questionnaireHash,
    loginType: session.loginType
  };
}
