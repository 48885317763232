import { Pipe, PipeTransform } from '@angular/core';
import { LinkGenerator } from './link-generator';

@Pipe({ name: 'userLink' })
export class UserLinkPipe implements PipeTransform {
  constructor(private generator: LinkGenerator) {}

  transform(user: { id: number; type: 'CLIENT' | 'STAFF' }): any {
    if (user == null) return;
    if (user.type === 'STAFF') {
      return this.generator.generate({
        type: 'k2User',
        data: {
          userId: user.id
        }
      });
    }

    if (user.type === 'CLIENT') {
      return this.generator.generate({
        type: 'clientUser',
        data: {
          userId: user.id
        }
      });
    }
    throw Error(`Failed to generate user link! User type ${user.type} is not supported.`);
  }
}
