<div [id]="field.attributes.id" (blur)="touch()">
  <table class="packages custom">
    <thead>
      <tr>
        <th>&nbsp;</th>
        <th class="text-center">Included in this package</th>
        <th class="text-center">Time variant</th>
        <th aria-hidden="true">&nbsp;</th>
      </tr>
    </thead>
    <tbody>
      <ng-container *ngFor="let workflow of workflows">
        <tr>
          <th class="splitter" colspan="4">{{ workflow.label }}</th>
        </tr>
        <tr aria-hidden="true" class="spacer">
          <td aria-hidden="true">&nbsp;</td>
          <td aria-hidden="true">&nbsp;</td>
          <td aria-hidden="true">&nbsp;</td>
          <td aria-hidden="true">&nbsp;</td>
        </tr>

        <tr
          *ngFor="let service of workflow.services; let odd = odd"
          [class.even]="odd"
        >
          <th scope="row">{{ service.name }}</th>
          <td class="text-center">
            <select
              [(ngModel)]="value[service.id].package_status"
              (change)="propagateValueChange()"
              [disabled]="disabled"
              class="form-control"
            >
              <option value="DISABLED">Disabled</option>
              <option value="CORE">Core</option>
              <option value="FLEXIBLE">Flexible</option>
            </select>
          </td>

          <td *ngIf="!service.variations" class="text-center not-available">
            <empty>n/a</empty>
          </td>
          <td *ngIf="service.variations" class="text-center not-available">
            <select
              [(ngModel)]="value[service.id].variation"
              (change)="propagateValueChange()"
              [disabled]="
                value[service.id].package_status === 'DISABLED' || disabled
              "
              class="form-control"
            >
              <option
                *ngFor="let variation of service.variations | values"
                [value]="variation.id"
              >
                {{ variation.value }} {{ variation.label }}
              </option>
            </select>
          </td>
          <td aria-hidden="true">&nbsp;</td>
        </tr>
      </ng-container>
    </tbody>
  </table>
</div>
