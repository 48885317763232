import { ApiClient } from '@k2/common/backend/api-client';
import { map } from 'rxjs/operators';

export class SearchEndpoint {
  constructor(private http: ApiClient) {}

  query = (query: string) => {
    return this.http
      .get(`/search/${encodeURIComponent(query)}`)
      .pipe(map(payload => payload.results));
  };

  searchAssignmentLinks = (query: string) => {
    return this.http
      .get(`/search/assignment-links/${encodeURIComponent(query)}`)
      .pipe(map(payload => payload.results));
  };

  searchAirports = (query: string) => {
    return this.http
      .get(`/search/airport/${encodeURIComponent(query)}`)
      .pipe(map(payload => payload.results.data));
  };
}
