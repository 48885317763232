import { DeleteEntities, toNormalizedUpdate } from '@k2/common/entities-state/actions';
import { User, UserSecurity } from '@k2/common/entities-state/types';
import { BatchAction } from '@k2/common/state/batch-action';
import { usersListSchema } from '@k2/common/users-state/normalizer-schemas';
import { Action } from '@ngrx/store';

export const STORE_USERS = '[Users] Store users';
export const UPDATE_USER = '[Users] Update user';
export const DELETE_USER = '[Users] Delete user';

export const UPDATE_USER_SECURITY = '[Users] Update userSecurity';

export type Actions = StoreUsers | UpdateUser | DeleteUser;

export class StoreUsers implements BatchAction {
  readonly type = STORE_USERS;
  readonly actions: Action[];

  constructor(users: User[]) {
    const normalized = toNormalizedUpdate(usersListSchema, users);
    this.actions = normalized.actions;
  }
}

export class UpdateUser implements Action {
  readonly type = UPDATE_USER;

  constructor(readonly userId: number, readonly values: Partial<User>) {}
}

export class DeleteUser implements BatchAction {
  readonly type = DELETE_USER;
  readonly actions: Action[];

  constructor(readonly id: number) {
    this.actions = [new DeleteEntities('users', [id])];
  }
}

export class UpdateUserSecurity implements Action {
  readonly type = UPDATE_USER_SECURITY;

  constructor(readonly userId: number, readonly values: Partial<UserSecurity>) {}
}
