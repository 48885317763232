import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { FeedbackSubsidiary } from '@k2/common/entities-state/types';

@Component({
  selector: 'feedback-subsidiaries',
  templateUrl: 'feedback-subsidiaries.component.html',
  styleUrls: ['feedback-subsidiaries.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FeedbackSubsidiariesComponent {
  @Input() subsidiaries: FeedbackSubsidiary[];
}
