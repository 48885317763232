import { Pipe, PipeTransform } from '@angular/core';
import { alwaysPrimitive } from '@k2/common/helpers';
import { Client } from '@k2/common/entities-state/types';
import { LinkGenerator } from './link-generator';

@Pipe({ name: 'clientLink' })
export class ClientLinkPipe implements PipeTransform {
  constructor(private generator: LinkGenerator) {}

  transform(client: Client | number): any {
    if (client == null) return;

    return this.generator.generate({
      type: 'client',
      data: {
        clientId: alwaysPrimitive(client, c => c.id)
      }
    });
  }
}
