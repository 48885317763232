<p #pElement class="text show-trimmed" [ngClass]="{ 'show-less': !showAll }">
  {{ text }}
</p>

<div *ngIf="overflows && !showAll" class="control-link">
  <a (click)="showAll = true">(show more)</a>
</div>

<div *ngIf="overflows && showAll" class="control-link">
  <a (click)="showAll = false">(show less)</a>
</div>
