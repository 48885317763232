import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Field } from '@k2/common/k2-forms-state/types';
import { takeWhile } from "rxjs/operators";
import { containsEmojis, removeEmojis } from "@k2/common/k2-forms/utils";

@Component({
  selector: 'text-control',
  templateUrl: 'text-control.component.html'
})

export class TextControlComponent implements OnInit, OnDestroy {
  @Input() field: Field<string>;
  @Input() deleteExtension?: boolean = false;

  alive: boolean = true;

  ngOnInit() {
    this.field.control.valueChanges.pipe(takeWhile(() => this.alive)).subscribe(value => {
      if (containsEmojis(value)) {
        this.field.control.setValue(removeEmojis(value));
      }
    });

    if (this.deleteExtension) {
      const initValue = this.field.control.value;
      this.field.control.setValue(initValue.substring(0, initValue.lastIndexOf('.')));
    }
  }

  ngOnDestroy() {
    this.alive = false;
  }
}
