import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AutocompleteModule } from '@k2/common/autocomplete/autocomplete.module';
import { AddressControlComponent } from '@k2/common/k2-forms/field-control/address/address-control.component';
import { AnnotatedControlComponent } from '@k2/common/k2-forms/field-control/annotated-control.component';
import { ArrayFieldControlsComponent } from '@k2/common/k2-forms/field-control/array-field-controls.component';
import { BudgetControlComponent } from '@k2/common/k2-forms/field-control/budget/budget-control.component';
import { CheckboxControlComponent } from '@k2/common/k2-forms/field-control/checkbox/checkbox-control.component';
import { CheckboxesControlComponent } from '@k2/common/k2-forms/field-control/checkboxes/checkboxes-control.component';
import { DateControlComponent } from '@k2/common/k2-forms/field-control/date/date-control.component';
import { MultiDateControlComponent } from '@k2/common/k2-forms/field-control/multi-date/multi-date-control.component';
import { DatetimeControlComponent } from '@k2/common/k2-forms/field-control/datetime/datetime-control.component';
import { DocumentsUploaderControlComponent } from '@k2/common/k2-forms/field-control/documents-uploader/documents-uploader-control.component';
import { EmailControlComponent } from '@k2/common/k2-forms/field-control/email/email-control.component';
import { FieldControlComponent } from '@k2/common/k2-forms/field-control/field-control.component';
import { GroupedCheckboxesControlComponent } from '@k2/common/k2-forms/field-control/grouped-checkboxes/grouped-checkboxes-control.component';
import { IconSelectControlComponent } from '@k2/common/k2-forms/field-control/icon-select/icon-select-control.component';
import { K2SelectControlComponent } from '@k2/common/k2-forms/field-control/k2-select/k2-select-control.component';
import { K2UsersSelectorControlComponent } from '@k2/common/k2-forms/field-control/k2-users-selector/k2-users-selector-control.component';
import { LocationsSelectorControlComponent } from '@k2/common/k2-forms/field-control/locations-selector/locations-selector-control.component';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { MarkdownControlComponent } from '@k2/common/k2-forms/field-control/markdown/markdown-control.component';
import { MultiFieldControlComponent } from '@k2/common/k2-forms/field-control/multi-field/multi-field-control.component';
import { MultiSelectControlComponent } from '@k2/common/k2-forms/field-control/multi-select/multi-select-control.component';
import { NumberControlComponent } from '@k2/common/k2-forms/field-control/number/number-control.component';
import { PartnerFinderControlComponent } from '@k2/common/k2-forms/field-control/partner-finder/partner-finder-control.component';
import { PartnersSelectorControlComponent } from '@k2/common/k2-forms/field-control/partners-selector/partners-selector-control.component';
import { QuotedProductControlComponent } from '@k2/common/k2-forms/field-control/quoted-product/quoted-product-control.component';
import { RadioControlComponent } from '@k2/common/k2-forms/field-control/radio/radio-control.component';
import { RadiobarControlComponent } from '@k2/common/k2-forms/field-control/radiobar/radiobar-control.component';
import { FeedbackRatingControlHeaderComponent } from '@k2/common/k2-forms/field-control/rating/feedback-rating-control-header.component';
import { RatingControlComponent } from '@k2/common/k2-forms/field-control/rating/rating-control.component';
import { RecipientsControlComponent } from '@k2/common/k2-forms/field-control/recipients/recipients-control.component';
import { RegionsControlComponent } from '@k2/common/k2-forms/field-control/regions/regions-control.component';
import { SelectControlComponent } from '@k2/common/k2-forms/field-control/select/select-control.component';
import { TelephoneControlComponent } from '@k2/common/k2-forms/field-control/telephone/telephone-control.component';
import { TextControlComponent } from '@k2/common/k2-forms/field-control/text/text-control.component';
import { PasswordControlComponent } from '@k2/common/k2-forms/field-control/password/password-control.component';
import { TextareaControlComponent } from '@k2/common/k2-forms/field-control/textarea/textarea-control.component';
import { VariationsControlComponent } from '@k2/common/k2-forms/field-control/variations/variations-control.component';
import { WorkflowServiceVariationsControlComponent } from '@k2/common/k2-forms/field-control/workflow-services-variations/workflow-service-variations-control.component';
import { YesNoControlComponent } from '@k2/common/k2-forms/field-control/yes-no/yes-no-control.component';
import { K2SelectModule } from '@k2/common/k2-select/k2-select.module';
import { K2UsersModule } from '@k2/common/k2-users/k2-users.module';
import { LocationsModule } from '@k2/common/locations/locations.module';
import { PartnersModule } from '@k2/common/partners/partners.module';
import { SharedModule } from '@k2/common/shared.module';
import { TelephoneModule } from '@k2/common/telephone/telephone.module';
import { UploadsModule } from '@k2/common/uploads/uploads.module';
import { AutosizeModule } from 'ngx-autosize';
import { K2LinksSelectorControlComponent } from './k2-links-selector/k2-links-selector-control.component';
import { AssignmentsModule } from '@k2/common/assignments/assignments.module';
import { ContactCardsControlComponent } from './contact-cards/contact-cards-control.component';
import { AirportSelectorControlComponent } from './airport-selector/airport-selector-control.component';

const publicComponents = [
  FieldControlComponent,
  AddressControlComponent,
  DateControlComponent,
  MultiDateControlComponent,
  DatetimeControlComponent,
  TextControlComponent,
  PasswordControlComponent,
  EmailControlComponent,
  NumberControlComponent,
  SelectControlComponent,
  K2SelectControlComponent,
  IconSelectControlComponent,
  K2LinksSelectorControlComponent,
  K2UsersSelectorControlComponent,
  PartnersSelectorControlComponent,
  PartnerFinderControlComponent,
  TelephoneControlComponent,
  TextareaControlComponent,
  MarkdownControlComponent,
  DocumentsUploaderControlComponent,
  RadioControlComponent,
  RadiobarControlComponent,
  RatingControlComponent,
  CheckboxControlComponent,
  CheckboxesControlComponent,
  VariationsControlComponent,
  RegionsControlComponent,
  GroupedCheckboxesControlComponent,
  WorkflowServiceVariationsControlComponent,
  YesNoControlComponent,
  FeedbackRatingControlHeaderComponent,
  LocationsSelectorControlComponent,
  BudgetControlComponent,
  MultiFieldControlComponent,
  MultiSelectControlComponent,
  ArrayFieldControlsComponent,
  RecipientsControlComponent,
  QuotedProductControlComponent,
  ContactCardsControlComponent,
  AirportSelectorControlComponent
];

@NgModule({
  imports: [
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    TelephoneModule,
    UploadsModule,
    K2UsersModule,
    PartnersModule,
    AutocompleteModule,
    K2SelectModule,
    LocationsModule,
    AutosizeModule,
    AssignmentsModule,
    NgxMaterialTimepickerModule
  ],
  declarations: [AnnotatedControlComponent, ...publicComponents],
  exports: publicComponents
})
export class FieldControlModule {}
