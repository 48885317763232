import { toUserName } from '@k2/common/ui/pipes/user-name.pipe';

export function toUserId(user) {
  if (user.id) return user.id;
  let userName = toUserName(user);
  if (!userName) {
    console.warn('Invalid user.');
    return;
  }
  return Math.abs(
    userName
      .split('')
      .reduce((prevHash, currVal) => (prevHash << 5) - prevHash + currVal.charCodeAt(0), 0)
  );
}
