import { Component, Input } from '@angular/core';
import { Fields } from '@k2/common/k2-forms-state/types';
import { Observable } from 'rxjs';

@Component({
  selector: 'email-fields',
  templateUrl: 'email-fields.component.html'
})
export class EmailFieldsComponent {
  @Input() fields: Observable<Fields>;
}
