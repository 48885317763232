import { Component, Input, OnDestroy } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Router } from '@angular/router';
import {
  isNotEmpty,
  isNotNil,
  isNotString,
  isString,
  pipeValuesTo,
  Subscriptions
} from '@k2/common/helpers';
import { includesParts } from '@k2/common/filters/utils';
import { ComponentSpec } from '@k2/common/ui/component-spec';
import { mergeDeepRight } from 'ramda';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { filter, flatMap, map } from 'rxjs/operators';

const cacheSize = 30;
const visibleItemsLimit = 30;

@Component({
  selector: 'search',
  templateUrl: 'search.component.html',
  styleUrls: ['search.component.scss']
})
export class SearchComponent implements OnDestroy {
  @Input() config: SearchConfig;
  readonly options: Observable<Result[]>;
  readonly searchControl = new UntypedFormControl();
  private readonly optionsSubject = new BehaviorSubject<Result[]>(null);
  private readonly subscriptions = new Subscriptions();

  constructor(private router: Router) {
    this.options = this.createOptionsStream();
    this.listenToSearchEvents();
    this.listenToSelectEvents();
  }

  private createOptionsStream = () => {
    return combineLatest(
      this.searchControl.valueChanges.pipe(filter(isString)),
      this.optionsSubject
    ).pipe(
      map(([query, items]) => {
        if (items == null) return [];
        return items
          .filter(item => includesParts(item.searchable, query))
          .slice(0, visibleItemsLimit)
          .map(highlight(query));
      })
    );
  };

  private listenToSearchEvents = () => {
    this.subscriptions.add(
      this.searchControl.valueChanges
        .pipe(
          filter(isString),
          filter(isNotEmpty),
          // pairwise(),
          filter(query => query.length >= 3),
          // withLatestFrom(this.optionsSubject.pipe(map(items => (items ? items.length : Infinity)))),
          // filter(([[previousQuery, query], optionsLength]) => {
          // return !query.startsWith(previousQuery) || optionsLength > cacheSize;
          // }),
          flatMap(query => this.config.query(query))
        )
        .subscribe(pipeValuesTo(this.optionsSubject))
    );
  };

  private listenToSelectEvents = () => {
    this.subscriptions.add(
      this.searchControl.valueChanges
        .pipe(filter(isNotNil), filter(isNotString))
        .subscribe((item: Result) => {
          this.searchControl.patchValue(null);
          this.router.navigate(item.link);
        })
    );
  };

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}

function highlight(highlight: string) {
  return (result: Result): Result => {
    return mergeDeepRight(result, {
      spec: {
        inputs: { highlight }
      }
    });
  };
}

export interface SearchConfig {
  query(query: string): Observable<Result[]>;
}

export interface Result<T = any> {
  readonly searchable: string;
  readonly link: any;
  readonly spec: ComponentSpec<T>;
}
