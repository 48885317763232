import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Location } from '@k2/common/entities-state/types';

@Component({
  selector: 'detailed-assignment-location',
  templateUrl: 'detailed-assignment-location.component.html',
  styleUrls: ['detailed-assignment-location.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DetailedAssignmentLocationComponent {
  @Input() origin: Location;
  @Input() destination: Location;
}
