import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnDestroy,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { minimum } from '@k2/common/helpers';
import { loadAmMap } from '@k2/common/map/am-map';
import {
  calculateZoomLatitude,
  calculateZoomLevel,
  calculateZoomLongitude
} from '@k2/common/map/zoom';

@Component({
  selector: 'bubble-map',
  templateUrl: 'bubble-map.component.html',
  styleUrls: ['bubble-map.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BubbleMapComponent implements OnChanges, OnDestroy {
  @Input() locations: BubbleMapLocation[];
  @Input() minRadius = 5;
  @Input() maxRadius = 50;

  @ViewChild('mapWrapper', { static: true }) private readonly mapElement: ElementRef;
  private map;

  ngOnChanges(changes: SimpleChanges): void {
    this.tryDestroyMap();
    this.createMap();
  }

  private createMap = async () => {
    const amMap = await loadAmMap();

    const min = minimum(this.locations.map(v => v.value));
    const images = this.toImages(this.quantify(this.locations, min));
    const mapElement = this.mapElement.nativeElement;

    this.map = amMap.makeChart(mapElement, {
      type: 'map',
      theme: 'light',
      projection: 'miller',
      areasSettings: {
        unlistedAreasColor: '#000000',
        unlistedAreasAlpha: 0.1
      },
      dataProvider: {
        map: 'worldLow',
        images,
        zoomLatitude: calculateZoomLatitude(this.locations),
        zoomLongitude: calculateZoomLongitude(this.locations),
        zoomLevel: calculateZoomLevel({ positions: this.locations, maxZoom: 4 })
      }
    });
  };

  private quantify = (locations: BubbleMapLocation[], min: number): QuantifiedLocation[] => {
    return locations.map(location => {
      const { value } = location;

      let radius = (value / min) * this.minRadius;
      if (radius > this.maxRadius) radius = this.maxRadius;

      return {
        ...location,
        size: radius * 2
      };
    });
  };

  private toImages = (locations: QuantifiedLocation[]) => {
    return locations.map(value => ({
      type: 'circle',
      theme: 'light',
      width: value.size,
      height: value.size,
      latitude: value.latitude,
      longitude: value.longitude,
      color: '#97BBCD',
      labelColor: '#637789',
      title: value.tooltip,
      value: value.value,
      label: value.name
    }));
  };

  ngOnDestroy(): void {
    this.tryDestroyMap();
  }

  private tryDestroyMap = () => {
    if (this.map) {
      this.map.clear();
    }
  };
}

interface QuantifiedLocation extends BubbleMapLocation {
  size: number;
}

export interface BubbleMapLocation {
  name: string;
  value: number;
  tooltip: string;
  latitude: number;
  longitude: number;
}
