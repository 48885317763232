import { Entities, EntityId } from '@k2/common/entities-state/entity';
import { Action } from '@ngrx/store';
import { normalize, Schema } from 'normalizr';
import { curry, mapObjIndexed, values } from 'ramda';
import { UpdateUser } from '@k2/common/users-state/actions';

export const ADD_ENTITIES = '[Entities] Add';
export const UPDATE_ENTITIES = '[Entities] Update';
export const DELETE_ENTITIES = '[Entities] Delete';

export type Actions = AddEntities | UpdateEntities | DeleteEntities | UpdateUser;

/**
 * Adds new entities under `entityType` key.
 *
 * Existing entities will be overwritten.
 */
export class AddEntities implements Action {
  readonly type = ADD_ENTITIES;

  constructor(readonly entityType: string, readonly entities: Entities) {}
}

/**
 * Deeply updates entities under `entityType` key.
 *
 * Non existing entities will be added.
 */
export class UpdateEntities implements Action {
  readonly type = UPDATE_ENTITIES;

  constructor(readonly entityType: string, readonly entities: Entities) {}
}

/**
 * Deletes entities under `entityType` key.
 */
export class DeleteEntities implements Action {
  readonly type = DELETE_ENTITIES;

  constructor(readonly entityType: string, readonly ids: EntityId[]) {}
}

export const toNormalizedUpdate = curry((schema: Schema, data): {
  result: any;
  actions: UpdateEntities[];
} => {
  const normalized = normalize(data, schema);
  return {
    result: normalized.result,
    actions: values(
      mapObjIndexed((entities, type) => new UpdateEntities(type, entities), normalized.entities)
    )
  };
});
