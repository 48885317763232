import { NgModule } from '@angular/core';
import { BubbleMapComponent } from '@k2/common/map/components/bubble-map/bubble-map.component';
import { FlightMapComponent } from '@k2/common/map/components/flight-map/flight-map.component';
import { SharedModule } from '@k2/common/shared.module';

const components = [BubbleMapComponent, FlightMapComponent];

@NgModule({
  imports: [SharedModule],
  declarations: components,
  exports: components
})
export class MapModule {}
