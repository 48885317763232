import { Component, Input, OnChanges } from '@angular/core';
import { DocumentFile } from '@k2/common/entities-state/types';
import { Field } from '@k2/common/k2-forms-state/types';
import { UploaderState } from '@k2/common/uploads/components/documents-uploader/documents-uploader.component';

@Component({
  selector: 'document-upload-fields',
  templateUrl: 'document-upload-fields.component.html'
})
export class DocumentUploadFieldsComponent implements OnChanges {
  @Input() idField: Field;
  @Input() hashField: Field;
  @Input() nameField: Field;
  @Input() mimeTypeField: Field;

  existing: DocumentFile[];

  ngOnChanges(): void {
    this.existing = [
      {
        id: this.idField.defaultValue,
        identifier_hash: this.hashField.defaultValue,
        name: this.nameField.defaultValue,
        mime_type: this.mimeTypeField.defaultValue
      }
    ];
  }

  updateExisting = (docs: DocumentFile[]) => {
    this.existing = docs;

    if (docs.length === 0) {
      this.clearValues();
    }
  };

  updateState = (state: UploaderState) => {
    this.existing = [];

    if (state.uploaded.length > 0) {
      const { id, identifier_hash, mime_type, name } = state.uploaded[0];
      this.idField.control.patchValue(id);
      this.hashField.control.patchValue(identifier_hash);
      this.mimeTypeField.control.patchValue(mime_type);
      this.nameField.control.patchValue(name);
    } else {
      this.clearValues();
    }
  };

  get hasNoFile(): boolean {
    return this.existing.length === 0 && this.idField.control.value === null;
  }

  private clearValues = () => {
    this.idField.control.patchValue(null);
    this.hashField.control.patchValue(null);
    this.mimeTypeField.control.patchValue(null);
    this.nameField.control.patchValue(null);
  };
}
