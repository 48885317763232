<a *ngIf="link" [routerLink]="link" [matTooltip]="tooltip">
  <person-icon
    [gender]="person.gender"
    [isChild]="type === 'Child'"
    [hasWarning]="isIncomplete"
    aria-hidden="true"
  ></person-icon>

  <span class="sr-only">{{ name }}</span>
</a>

<span *ngIf="!link" [matTooltip]="tooltip">
  <person-icon
    [gender]="person.gender"
    [isChild]="type === 'Child'"
    [hasWarning]="isIncomplete"
    aria-hidden="true"
  ></person-icon>

  <span class="sr-only">{{ name }}</span>
</span>
