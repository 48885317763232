import { ApiClient } from '@k2/common/backend/api-client';
import { K2User } from '@k2/common/entities-state/types';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

export class StaffEndpoint {
  constructor(private api: ApiClient) {}

  fetchNewUserForm = () => {
    return this.api.get('/form/user.account.form').pipe(map(payload => payload.form));
  };

  createUser = (values: any): Observable<number> => {
    return this.api.post('/staff', values).pipe(map(payload => payload.form.fields.id.value));
  };

  fetchAllUsers = () => {
    return this.api.get('/staff').pipe(map(payload => payload.staff));
  };

  findUsers = (query: string, onlyTeam?: boolean): Observable<K2User[]> => {
    let url = onlyTeam ? '/staff/contacts/search/' : '/staff/search/';

    return this.api
      .get(url + encodeURIComponent(query))
      .pipe(map(payload => payload.staff));
  };

  fetchProfile = (userId: number) => {
    return this.api.get(`/staff/${userId}/profile`);
  };

  updateProfile = (userId: number, data) => {
    return this.api.patch(`/staff/${userId}/profile`, data);
  };

  fetchSessionsHistory = (userId: number) => {
    return this.api.get(`/staff/${userId}/session/history`).pipe(map(payload => payload.sessions));
  };

  fetchPartnerContactSessionsHistory = (partnerId: number, userId: number) => {
    return this.api
      .get(`/staff/vendors/${partnerId}/contacts/${userId}/session/history`)
      .pipe(map(payload => payload.sessions));
  };

  updateLeavingAtDate = (userId: number, leavingAt: string) => {
    const data = { leaving_at: leavingAt };

    return this.api.put(`/staff/${userId}/leavingdate`, data);
  };

  deleteLeavingAtDate = (userId: number) => {
    return this.api.delete(`/staff/${userId}/leavingdate`);
  };

  fetchUserWelcomeForm = (recipientEmail: string) => {
    return this.api
      .get(`/staff/welcome/email/${recipientEmail}`)
      .pipe(map(payload => payload.form));
  };

  postUserWelcomeEmail = (values: any) => {
    return this.api.post('/staff/welcome/email', values);
  };

  fetchPasswordResetForm = (recipientEmail: string) => {
    return this.api.get(`/staff/reset/email/${recipientEmail}`).pipe(map(payload => payload.form));
  };

  postPasswordResetEmail = (values: any) => {
    return this.api.post('/staff/reset/email', values);
  };

  updatePassword = (userId: number, oldPassword: string, newPassword: string) => {
    const data = {
      old_password: oldPassword,
      password: newPassword
    };

    return this.api.patch(`/staff/${userId}/credentials`, data);
  };

  fetchUserCurrentAssignments = (userId: number) => {
    return this.api
      .get(`/staff/${userId}/assignments/current`)
      .pipe(map(payload => payload.current));
  };

  fetchUserPendingExceptions = (userId: number) => {
    return this.api
      .get(`/staff/${userId}/exceptions/pending`)
      .pipe(map(payload => payload.exceptions));
  };

  fetchUserTeam = (userId: number) => {
    return this.api.get(`/staff/${userId}/team`).pipe(map(payload => payload.team));
  };

  fetchUserTeamCaseload = (userId: number) => {
    return this.api.get(`/staff/${userId}/team-caseload`).pipe(map(payload => payload.team));
  };

  fetchUserClients = (userId: number) => {
    return this.api.get(`/staff/${userId}/clients`).pipe(map(payload => payload.clients));
  };

  fetchProfileSecurity = (userId: number) => {
    return this.api
      .get(`/staff/${userId}/profile-security`)
      .pipe(map(payload => payload.profile_security));
  };

  updateProfileSecurity = (userId: number, data) => {
    return this.api.patch(`/staff/${userId}/profile-security`, data);
  };
}
