<ng-container *ngIf="msg; else noMsg">
  <div>
    <p>Date: {{ msg.date | date: 'short' }}</p>

    <p>
      From:
      <email-contact [contact]="msg.sender"></email-contact>
    </p>

    <p class="recipients-wrapper">
      To:&nbsp;
      <span class="recipients">
        <span *ngFor="let recipient of msg.recipients; let i = index">
          <email-contact [contact]="recipient"></email-contact
          ><span *ngIf="i != msg.recipients.length - 1">, </span>
        </span>
      </span>
    </p>

    <p class="subject">Subject: {{ msg.subject }}</p>

    <div>
      <span *ngFor="let attachment of nonInternalAttachments(msg)" class="attachment">
        <a [href]="toAttachmentLink(attachment.id, msg.id)" target="_blank">
          <icon class="paperclip-icon" type="paperclip"></icon>
          {{ attachment.file_name }}
        </a>
      </span>
    </div>
  </div>

  <div class="msg-content">
    <iframe #iframe
            [src]="formattedContent(msg)"
            frameborder="0"
            scrolling="no"
            sandbox="allow-popups allow-same-origin">
    </iframe>
  </div>
</ng-container>

<ng-template #noMsg>
  <p *ngIf="!msg" class="nocontent-wrapper">Please select an item to read</p>
</ng-template>
