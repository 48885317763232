<annotated-control [field]="field">
  <div *ngFor="let value of values; let last = last" class="inputs">
    <div *ngFor="let option of options; let i = index" class="input">
      <input
        *ngIf="option.display_type === 'number'"
        type="number"
        [(ngModel)]="value[i]"
        (change)="propagateValueChange()"
        [disabled]="disabled"
        class="form-control"
      />

      <basic-select
        *ngIf="option.display_type === 'select'"
        [value]="value[i]"
        (valueChange)="value[i] = $event; propagateValueChange()"
        [disabled]="disabled"
        [options]="option.items"
      ></basic-select>
    </div>

    <array-field-controls
      *ngIf="!disabled"
      [canRemove]="canRemove"
      [canAdd]="last"
      (add)="addEmptyValue()"
      (remove)="removeValue(value)"
    ></array-field-controls>
  </div>
</annotated-control>
