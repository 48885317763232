import { Component } from '@angular/core';

/**
 * Can be styled with "warning" class.
 *
 * EXAMPLE:
 *   <empty class="warning">Message</empty>
 */
@Component({
  selector: 'empty',
  templateUrl: 'empty.component.html',
  styleUrls: ['empty.component.scss']
})
export class EmptyComponent {}
