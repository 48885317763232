<div [id]="field.attributes.id" class="radio-buttons rating-controls">
  <ng-container *ngIf="!field.attributes.isStarRating; else starRating">
    <label class="rating-option" *ngFor="let option of options">
      <input type="radio" [name]="field.name" [value]="option" [formControl]="field.control" />
    </label>
  </ng-container>
  <ng-template #starRating>
    <mat-icon *ngFor="let option of options"
              class="star" [class.selected]="field.control.value >= option"
              (click)="onStarClick(option)">
      {{ field.control.value >= option ? 'star' : 'star_border' }}
    </mat-icon>
  </ng-template>
</div>
