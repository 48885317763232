import { ChangeDetectionStrategy, Component, Input, OnChanges, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { UserStatus } from '@k2/common/entities-state/types';
import { RootState } from '@k2/common/k2-forms-state/state';
import { SelfSubmittingField } from '@k2/common/k2-forms/self-submitting-field/self-submitting-field';
import {
  passwordsMatchValidator,
  passwordStrengthValidator,
  requiredValidator
} from '@k2/common/k2-forms/validators';
import { appConfig } from '@k2/common/app-config';
import { getSessionUserId } from '@k2/common/sessions-state/reducers';
import { ActionDispatcher } from '@k2/common/state/services/action-dispatcher';
import { AppStore } from '@k2/common/state/services/app-store';
import { select } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'self-submitting-password-field',
  templateUrl: 'self-submitting-password-field.component.html',
  styleUrls: [
    'self-submitting-field.component.scss',
    'self-submitting-password-field.component.scss'
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SelfSubmittingPasswordFieldComponent extends SelfSubmittingField
  implements OnInit, OnChanges {
  @Input() config: PasswordFieldsConfig;

  isEditable: Observable<boolean>;

  minPasswordLength = appConfig.password.min_length;
  minPasswordNumbers = appConfig.password.min_numbers;
  minPasswordSymbols = appConfig.password.min_symbols;

  constructor(private store: AppStore<RootState>, actions: ActionDispatcher) {
    super(actions);
  }

  ngOnInit(): void {
    this.form = new UntypedFormGroup({
      oldPassword: new UntypedFormControl('', requiredValidator),
      newPassword: new UntypedFormGroup(
        {
          password: new UntypedFormControl('', [passwordStrengthValidator]),
          confirm: new UntypedFormControl('')
        },
        passwordsMatchValidator
      )
    });
  }

  ngOnChanges(): void {
    this.isEditable = this.store.pipe(
      select(getSessionUserId),
      map(userId => this.config.userId === userId)
    );
  }

  get hasPassword(): boolean {
    return this.config.userStatus !== 'FIRSTLOGIN';
  }

  get passwordControl() {
    return (this.form.controls.newPassword as UntypedFormGroup).controls.password as UntypedFormControl;
  }

  get confirmControl() {
    return (this.form.controls.newPassword as UntypedFormGroup).controls.confirm as UntypedFormControl;
  }

  protected get defaultValue() {
    return {
      oldPassword: '',
      newPassword: {
        password: '',
        confirm: ''
      }
    };
  }

  protected get submitValue(): PasswordFieldsResult {
    return {
      oldPassword: this.form.value.oldPassword,
      newPassword: this.form.value.newPassword.password
    };
  }
}

export interface PasswordFieldsConfig {
  readonly userId: number;
  readonly userStatus: UserStatus;
  readonly openWelcomeDialog: () => void;
  readonly openResetDialog: () => void;
}

export interface PasswordFieldsResult {
  readonly oldPassword: string;
  readonly newPassword: string;
}
