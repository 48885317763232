import { ApiClient } from '@k2/common/backend/api-client';
import { map } from 'rxjs/operators';
import { AssignmentIdentity } from '@k2/common/entities-state/types';

export class ClientAssignmentEndpoint {
  constructor(private api: ApiClient) {}

  getNewAssignmentForm = (clientId: number) => {
    return this.api.get(`/clients/${clientId}/assignment/new`).pipe(map(response => response.form));
  };

  postNewAssignmentForm = (clientId: number, data) => {
    return this.api
      .post(`/clients/${clientId}/assignment/new`, data)
      .pipe(map(response => response.assignmentId));
  };

  getUserForm = (clientId: number, assignmentId: number) => {
    return this.api.get(`/clients/${clientId}/assignment/${assignmentId}/user`);
  };

  patchUserForm = (clientId: number, assignmentId: number, data: any) => {
    return this.api.patch(`/clients/${clientId}/assignment/${assignmentId}/user`, data);
  };

  getDetailsForm = (clientId: number, assignmentId: number) => {
    return this.api.get(`/clients/${clientId}/assignment/${assignmentId}/details`);
  };

  patchDetailsForm = (clientId: number, assignmentId: number, data: any) => {
    return this.api.patch(`/clients/${clientId}/assignment/${assignmentId}/details`, data);
  };

  fetchPackages = (clientId: number, assignmentId: number) => {
    return this.api.get(`/clients/${clientId}/assignment/${assignmentId}/packages`);
  };

  patchPackageId = (clientId: number, assignmentId: number, packageId: number | false) => {
    const data = { packages_id: packageId };
    return this.api.patch(`/clients/${clientId}/assignment/${assignmentId}/packages`, data);
  };

  fetchServices = (clientId: number, assignmentId: number) => {
    return this.api.get(`/clients/${clientId}/assignment/${assignmentId}/services`);
  };

  patchServices = (clientId: number, assignmentId: number, services: any) => {
    const data = { services };
    return this.api.patch(`/clients/${clientId}/assignment/${assignmentId}/services`, data);
  };

  fetchBudgets = (clientId: number, assignmentId: number) => {
    return this.api.get(`/clients/${clientId}/assignment/${assignmentId}/budgets`);
  };

  patchBudgets = (clientId: number, assignmentId: number, data: any) => {
    return this.api.patch(`/clients/${clientId}/assignment/${assignmentId}/budgets`, data);
  };

  fetchEstimates = (clientId: number, assignmentId: number) => {
    return this.api
      .get(`/clients/${clientId}/assignment/${assignmentId}/estimates`)
      .pipe(map(response => response.estimate));
  };

  fetchSummary = (clientId: number, assignmentId: number) => {
    return this.api
      .get(`/clients/${clientId}/assignment/${assignmentId}/summary`)
      .pipe(map(response => response.summary));
  };

  patchStatus = (clientId: number, assignmentId: number, status: string) => {
    const data = { status };
    return this.api.patch(`/clients/${clientId}/assignment/${assignmentId}/summary`, data);
  };

  fetchConfirmation = (clientId: number, assignmentId: number) => {
    return this.api
      .get(`/clients/${clientId}/assignment/${assignmentId}/confirmation`)
      .pipe(map(response => response.confirmation));
  };

  sendAgreeTerms = ({ clientId, assignmentId }: AssignmentIdentity) => {
    return this.api.post(`/clients/${clientId}/assignment/${assignmentId}/agree-terms`, {});
  };

  sendToRelocity = ({ clientId, assignmentId }: AssignmentIdentity, data: any) => {
    return this.api.post(`/clients/${clientId}/assignment/${assignmentId}/send-to-relocity`, data);
  };

  updateRelocity = ({ clientId, assignmentId }: AssignmentIdentity, data: any) => {
    return this.api.patch(`/clients/${clientId}/assignment/${assignmentId}/send-to-relocity`, data);
  };

  fetchRelocity = ({ clientId, assignmentId }: AssignmentIdentity) => {
    return this.api.get(`/clients/${clientId}/assignment/${assignmentId}/send-to-relocity`);
  };

  fetchSubmittedRelocity = ({ clientId, assignmentId }: AssignmentIdentity) => {
    return this.api.get(`/clients/${clientId}/assignment/${assignmentId}/send-to-relocity-update`);
  }
}
