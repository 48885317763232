import { Pipe, PipeTransform } from '@angular/core';
import { identity, uniqBy } from 'ramda';

/**
 * Removes a duplicates from a given array.
 *
 * By default, items are compared by their identity.
 */
@Pipe({
  name: 'uniq'
})
export class UniqPipe implements PipeTransform {
  transform<T>(array: T[], by: (item: T) => any = identity): T[] {
    return uniqBy(by, array);
  }
}
