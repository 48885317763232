<div class="services">
  <fieldset *ngFor="let workflow of workflows | values" class="workflow">
    <legend class="block-header">{{ workflow.label }}</legend>
    <div class="block-content">
      <div class="col-sm-12 one-row">
        <ng-container *ngFor="let service of services[workflow.id]">
          <div *ngIf="isVisible(service.id)" class="row">
            <label class="col-sm-4 control-label">{{ service.name }}</label>

            <div class="col-sm-8 field-wrap">
              <div *ngIf="service.variations.length > 0">
                <select [ngModel]="findSelectedVariation(service)"
                        (ngModelChange)="enableServiceVariation(service, $event.id)"
                        class="form-control lg">
                  <option *ngFor="let variation of toNormalizedVariations(service)" [ngValue]="variation">
                    {{ variation.description }}
                  </option>
                </select>
              </div>
              <div *ngIf="service.variations.length === 0">
                <label class="col-sm-8">
                  <input [ngModel]="service.enabled" (ngModelChange)="toggleService(service)" type="checkbox" />
                  <span>{{ service.description }}</span>
                </label>
              </div>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  </fieldset>
</div>
