import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy, OnInit, Output
} from '@angular/core';
import { Subscriptions } from '@k2/common/helpers';
import { Field } from '@k2/common/k2-forms-state/types';

@Component({
  selector: 'field-control',
  templateUrl: 'field-control.component.html',
  styleUrls: ['field-control.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FieldControlComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() field: Field;
  @Input() changeServiceToUniversal: EventEmitter<any>;
  @Output() changedField = new EventEmitter<boolean>();
  private readonly subscriptions = new Subscriptions();
  initValue: any;

  constructor(private changeDetector: ChangeDetectorRef) {
  }

  ngOnInit(): void {
    this.initValue = this.field.control.value;
  }

  ngAfterViewInit(): void {
    this.subscriptions.add(
      this.field.control.valueChanges.subscribe((value) => {
        const diff = typeof(value) === 'object' ? JSON.stringify(value) !== JSON.stringify(this.initValue) : value !== this.initValue;
        this.changedField.emit(diff);
        this.changeDetector.markForCheck();
      })
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
