<ng-container *ngIf="disabled">
  <partner-ingot *ngIf="selected != null" [partner]="selected"></partner-ingot>
  <no-data *ngIf="selected == null" class="form-control disabled-msg">
    No partner selected (disabled)
  </no-data>
</ng-container>

<ng-container *ngIf="!disabled">
  <removable *ngIf="selected != null" (remove)="clear()">
    <partner-ingot [partner]="selected"></partner-ingot>
  </removable>

  <a *ngIf="selected == null" (click)="openDialog()">
    <no-data class="form-control">{{ placeholder }}</no-data>
  </a>
</ng-container>
