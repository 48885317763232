import { Directive, HostListener, Input } from '@angular/core';

/**
 * Provides an onClick listener which will scroll to a given element.
 *
 * EXAMPLE:
 *   <a scrollTo="#content">Scroll to content</a>
 *   ...
 *   <div id="content">
 *     Some content
 *   </content>
 */
@Directive({
  selector: '[scrollTo]'
})
export class ScrollToDirective {
  @Input() scrollTo: string;

  @HostListener('click')
  scroll() {
    const target = document.querySelector(this.scrollTo);

    if (target == null)
      throw new Error(`Cannot scroll to ${this.scrollTo}! Please check the element's existence.`);

    target.scrollIntoView({ behavior: 'smooth'});
  }
}
