import { AddMessage } from '@k2/common/flash-messages/actions';
import { FormSpec, FormSpecId } from '@k2/common/k2-forms-state/types';
import { Action } from '@ngrx/store';

export const STORE_FORM_SPEC = '[Forms] Store form spec';
export const UPDATE_FORM_VALUES = '[Forms] Update form values';

export type Actions = StoreFormSpec | UpdateFormValues | AddFormError;

export class StoreFormSpec implements Action {
  readonly type = STORE_FORM_SPEC;

  constructor(readonly specId: FormSpecId, readonly spec: FormSpec) {}
}

export class UpdateFormValues implements Action {
  readonly type = UPDATE_FORM_VALUES;

  constructor(readonly specId: FormSpecId, readonly values: { [key: string]: any }) {}
}

export class AddFormError extends AddMessage {
  constructor() {
    super({
      text: 'The form is invalid. Please correct the highlighted fields.',
      type: 'error'
    });
  }
}

export class MissingFieldValueError extends AddMessage {
  constructor(fieldName: string) {
    super({
      text: 'The form is invalid. Field ' + fieldName + ' should not be empty.',
      type: 'error'
    });
  }
}

export class MissingFieldsValueError extends AddMessage {
  constructor(fieldNames: string[]) {
    super({
      text: 'The form is invalid. Fields: "' + fieldNames.toString() + '" should not be empty.',
      type: 'error'
    });
  }
}
