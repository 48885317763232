import { toNormalizedUpdate } from '@k2/common/entities-state/actions';
import { Task } from '@k2/common/entities-state/types';
import { BatchAction } from '@k2/common/state/batch-action';
import { tasksListSchema } from '@k2/common/tasks-state/normalizer-schemas';
import { Action } from '@ngrx/store';
import { groupBy, toPairs } from 'ramda';
import { StoreAvailableData } from '@k2/common/k2-users-state/actions';

export const FETCH_K2_USER_TEAM_TASKS = '[K2 Users] Fetch team tasks';
export const STORE_K2_USER_TEAM_TASKS = '[K2 Users] Store user team tasks';
export const STORE_K2_USER_TASKS = '[K2 Users] Store user tasks';

export type Actions = FetchK2UserTeamTasks | StoreK2UserTeamTasks | StoreK2UserTasks;

export class FetchK2UserTeamTasks implements Action {
  readonly type = FETCH_K2_USER_TEAM_TASKS;

  constructor(readonly userId: number) {}
}

export class StoreK2UserTeamTasks implements BatchAction {
  readonly type = STORE_K2_USER_TEAM_TASKS;
  readonly actions: Action[];

  constructor(readonly userId: number, tasks: Task[], clientTasks: Task[], clients: number[], assignments: number[]) {
    const groupByUserId = groupBy((task: Task) => task.assigned_to.id.toString());
    const groupedTasks = groupByUserId(tasks);

    if (groupedTasks[userId] == null) {
      groupedTasks[userId] = [];
    }

    this.actions = toPairs(groupedTasks).map(([uId, userTasks]: any) => {
      const id = Number(uId);
      return new StoreK2UserTasks(id, userTasks, id === userId ? clientTasks : null);
    });
    this.actions.push(new StoreAvailableData(clients, assignments));
  }
}

export class StoreK2UserTasks implements BatchAction {
  readonly type = STORE_K2_USER_TASKS;
  readonly actions: Action[];
  readonly userTaskIds: number[];
  readonly clientTaskIds?: number[];

  constructor(readonly userId: number, userTasks: Task[], clientTasks?: Task[]) {
    const normalizedUserTasks = toNormalizedUpdate(tasksListSchema, userTasks);
    this.userTaskIds = normalizedUserTasks.result;

    if (clientTasks == null) {
      this.actions = normalizedUserTasks.actions;
    } else {
      const filteredClientTasks = clientTasks.filter(task => task.assigned_to.id !== userId);
      const normalizedClientTasks = toNormalizedUpdate(tasksListSchema, filteredClientTasks);
      this.clientTaskIds = normalizedClientTasks.result;
      this.actions = [...normalizedUserTasks.actions, ...normalizedClientTasks.actions];
    }
  }
}
