import { ChangeDetectionStrategy, Component, Input, Optional } from '@angular/core';
import { SectionComponent } from '@k2/common/ui/components/section/section.component';

/**
 * Heading with an automatically inherited level.
 *
 * Level will be equal to parent <section> component's level or 1.
 *
 * EXAMPLE:
 *   <heading>Heading 1</heading>
 *   <section>
 *     <heading>Heading 2</heading>
 *     <section>
 *       <heading>Heading 3</heading>
 *       <section>
 *         <heading>Heading 4</heading>
 *       </section>
 *     </section>
 *   </section>
 */
@Component({
  selector: 'heading',
  templateUrl: 'heading.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HeadingComponent {
  // Renders heading in slim mode. Slim heading usually excludes a top margin.
  @Input() slim?: undefined | '';

  constructor(@Optional() private section?: SectionComponent) {}

  get level(): number {
    return this.section ? this.section.level : 1;
  }
}
