import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  ViewChild
} from '@angular/core';
import { RemoteFile, RemoteMutatedFile } from '@k2/common/file-jet/file';
import { toCropMutation } from '@k2/common/uploads/components/image-uploader/crop';
import qq from '@k2/common/uploads/fine-uploader/fine-uploader.js';
import Cropper from 'cropperjs';
import { Backend } from '../../../backend/backend';
import { AddMessage } from '../../../flash-messages/actions';
import { ActionDispatcher } from '../../../state/services/action-dispatcher';

@Component({
  selector: 'image-uploader',
  templateUrl: 'image-uploader.component.html',
  styleUrls: ['image-uploader.component.scss']
})
export class ImageUploaderComponent implements OnDestroy, AfterViewInit {
  @Input() image: RemoteMutatedFile;
  @Input() aspectRatio: number;
  @Input() variableAspectRatio;
  @Input() placeholder?: string;
  @Output() imageChange = new EventEmitter<RemoteMutatedFile>();

  @ViewChild('fineUploader') private fineUploaderRef: ElementRef;
  @ViewChild('cropper') private cropperRef: ElementRef;

  private fineUploader;
  private cropper;

  fileSrc: Blob;
  identifier: string;
  mutation: string;

  constructor(
    private cd: ChangeDetectorRef,
    private backend: Backend,
    private actions: ActionDispatcher
  ) {}

  get imageHeight(): number {
    return Math.round(255 / this.aspectRatio);
  }

  ngAfterViewInit(): void {
    this.createFineUploader();
  }

  private createFineUploader = () => {
    const options = {
      element: this.fineUploaderRef.nativeElement,
      template: 'qq-image-uploader',
      validation: {
        acceptFiles: 'image/bmp,image/gif,image/jpg,image/jpeg,image/png',
        allowedExtensions: ['bmp', 'gif', 'jpg', 'jpeg', 'png'],
        itemLimit: 1
      },
      request: {
        method: 'POST',
        forceMultipart: false,
        endpoint: this.backend.toApiUrl('/jetfile/upload')
      },
      cors: {
        expected: true,
        sendCredentials: true
      },
      callbacks: {
        onComplete: this.onUploadComplete,
        onError: this.onError
      }
    };

    this.fineUploader = new qq.FineUploader(options);
  };

  private onUploadComplete = (fileId: number, _, { payload }) => {
    const file = this.getFile(fileId);
    this.identifier = payload.identifier;
    this.initializeCropper(file);
    this.tryPropagateState();
  };

  private onError = (id, name, reason, request: XMLHttpRequest) => {
    try {
      const body = JSON.parse(request.response);

      body.meta.flash
        .map(({ text }) => new AddMessage({ text, type: 'error' }))
        .forEach(this.actions.dispatch);
    } catch (e) {
      console.error('Unexpected error during image upload!', request);

      this.actions.dispatch(
        new AddMessage({ text: 'Unexpected error during image upload!', type: 'error' })
      );
    }
    this.fineUploader.reset();
  };

  private getFile = (fileId: number): UploaderFile => this.fineUploader.getFile(fileId);

  private initializeCropper = (file: File) => {
    const reader = new FileReader();

    reader.onload = e => {
      this.fileSrc = (e.target as any).result;
      this.cd.markForCheck();
      setTimeout(this.createCropperJs);
    };

    reader.readAsDataURL(file);
  };

  private createCropperJs = () => {
    this.cropper = new Cropper(this.cropperRef.nativeElement, {
      zoomable: false,
      aspectRatio: this.aspectRatio,
      responsive: true,
      viewMode: 2,
      ready: () => {
        if (!this.variableAspectRatio) return;
        const data = this.cropper.getCropBoxData();
        this.cropper.setAspectRatio(null);
        this.cropper.setCropBoxData(data);
      }
    });
  };

  crop = () => {
    const cropData = this.cropper.getData(true);
    const canvasData = this.cropper.getCanvasData();
    this.mutation = toCropMutation(cropData, canvasData, this.aspectRatio);
    this.fileSrc = null;
    this.tryPropagateState();
  };

  private tryPropagateState = () => {
    if (this.identifier == null || this.mutation == null) return;
    this.imageChange.emit({ identifier: this.identifier, mutation: this.mutation });
    this.fineUploader.reset();
    this.identifier = null;
    this.mutation = null;
    this.fileSrc = null;
  };

  removeImage = () => {
    this.imageChange.emit(null);
  };

  cancel = () => {
    this.fineUploader.reset();
    this.hardResetFineUploader();
    this.identifier = null;
    this.mutation = null;
    this.fileSrc = null;
  };

  private hardResetFineUploader = () => {
    this.fineUploader.cancelAll();

    const successfulUploads = this.fineUploader.getUploads({ status: qq.status.UPLOAD_SUCCESSFUL });
    successfulUploads.forEach(upload => this.fineUploader.deleteFile(upload.id));
  };

  ngOnDestroy(): void {
    this.fineUploader.cancelAll();
  }
}

type UploaderFile = RemoteFile & File;
