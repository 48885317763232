import { Component, Input } from '@angular/core';

/**
 * Wraps a given child into the <label> with custom text.
 *
 * Good for standalone fields.
 *
 * EXAMPLE:
 *   <labeled label="Transit boxes">
 *     <number-control [field]="field"></number-control>
 *   </labeled>
 */
@Component({
  selector: 'labeled',
  templateUrl: 'labeled.component.html',
  styleUrls: ['labeled.component.scss']
})
export class LabeledComponent {
  @Input() label: string;
}
