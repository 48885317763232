import { Pipe, PipeTransform } from '@angular/core';
import { DocumentUrlType, toDocumentUrl } from '@k2/common/documents-state/utils';
import { Document } from '@k2/common/entities-state/types';

@Pipe({ name: 'documentUrl' })
export class DocumentUrlPipe implements PipeTransform {
  transform(document: Document, urlType?: DocumentUrlType): string {
    return toDocumentUrl(document, urlType);
  }
}
