import { Component, Input } from '@angular/core';
import { Field } from '@k2/common/k2-forms-state/types';
import { WithFormControl } from '../utils';

@Component({
  selector: 'address-control',
  templateUrl: 'address-control.component.html',
  styleUrls: ['address-control.component.scss']
})
export class AddressControlComponent extends WithFormControl {
  @Input() field: Field<AddressValue>;

  update = (newValue: string, propertyName: keyof AddressValue) => {
    this.value = { ...this.value, [propertyName]: newValue };
  };
}

export interface AddressValue {
  address1: string;
  address2: string;
  address3: string;
  address4: string;
  city: string;
  county: string;
  postal_code: string;
}
