import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Field } from '@k2/common/k2-forms-state/types';
import { SelfSubmittingField } from '@k2/common/k2-forms/self-submitting-field/self-submitting-field';

@Component({
  selector: 'self-submitting-name-fields',
  templateUrl: 'self-submitting-name-fields.component.html',
  styleUrls: ['self-submitting-field.component.scss']
})
export class SelfSubmittingNameFieldsComponent extends SelfSubmittingField implements OnChanges {
  @Input() firstName: Field;
  @Input() lastName: Field;

  ngOnChanges(changes: SimpleChanges): void {
    this.form = new UntypedFormGroup({
      [this.firstName.name]: this.firstName.control,
      [this.lastName.name]: this.lastName.control
    });
  }

  protected get defaultValue() {
    return {
      [this.firstName.name]: this.firstName.defaultValue,
      [this.lastName.name]: this.lastName.defaultValue
    };
  }

  protected get submitValue() {
    return this.form.value;
  }
}
