import { ApiClient } from '@k2/common/backend/api-client';
import { map } from 'rxjs/operators';

export class ClientEstimateEndpoint {
  constructor(private api: ApiClient) {}

  fetchNewEstimateForm = (clientId: number) => {
    return this.api.get(`/clients/${clientId}/estimate/new`).pipe(map(response => response.form));
  };

  createEstimate = (clientId: number, data: any) => {
    return this.api
      .post(`/clients/${clientId}/estimate/new`, data)
      .pipe(map(response => response.estimateId));
  };

  fetchPackages = (clientId: number, estimateId: number) => {
    return this.api
      .get(`/clients/${clientId}/estimate/${estimateId}/packages`)
      .pipe(map(response => response.packages));
  };

  selectPackage = (clientId: number, estimateId: number, packageId: number | false) => {
    const data = { packages_id: packageId };
    return this.api.patch(`/clients/${clientId}/estimate/${estimateId}/packages`, data);
  };

  fetchServices = (clientId: number, estimateId: number) => {
    return this.api.get(`/clients/${clientId}/estimate/${estimateId}/services`);
  };

  selectServices = (clientId: number, estimateId: number, services: any) => {
    const data = { services };
    return this.api.patch(`/clients/${clientId}/estimate/${estimateId}/services`, data);
  };

  fetchBudgets = (clientId: number, estimateId: number) => {
    return this.api.get(`/clients/${clientId}/estimate/${estimateId}/budgets`);
  };

  selectBudgets = (clientId: number, estimateId: number, budgets: any) => {
    return this.api.patch(`/clients/${clientId}/estimate/${estimateId}/budgets`, budgets);
  };

  fetchSummary = (clientId: number, estimateId: number) => {
    return this.api
      .get(`/clients/${clientId}/estimate/${estimateId}/summary`)
      .pipe(map(response => response.summary));
  };
}
