import { Component, Input } from '@angular/core';
import { Field } from '@k2/common/k2-forms-state/types';

@Component({
  selector: 'checkbox-control',
  templateUrl: 'checkbox-control.component.html',
  styleUrls: ['checkbox-control.component.scss']
})
export class CheckboxControlComponent {
  @Input() field: Field<boolean>;
}
