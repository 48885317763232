import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'date',
  templateUrl: 'date.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DateComponent {
  @Input() iso: string; // ISO8601
  @Input() rfc: string; // RFC850

  ngOnChanges() {
    this.iso = this.iso?.replace(/\s/g, 'T');
    this.rfc = this.rfc?.replace(/\s/g, 'T');
  }
}
