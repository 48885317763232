import { ReassignmentRequest } from '@k2/common/tasks-state/types';
import { Action } from '@ngrx/store';

export const MARK_TASK_AS_DONE = '[Tasks] Mark as done';
export const DELETE_TASK = '[Tasks] Delete';
export const REASSIGN_TASKS = '[Tasks] Reassign';

export type Actions = MarkTaskAsDone | DeleteTask | ReassignTasks;

export class MarkTaskAsDone implements Action {
  readonly type = MARK_TASK_AS_DONE;

  constructor(readonly taskId: number) {}
}

export class DeleteTask implements Action {
  readonly type = DELETE_TASK;

  constructor(readonly taskId: number) {}
}

export class ReassignTasks implements Action {
  readonly type = REASSIGN_TASKS;

  constructor(readonly requests: ReassignmentRequest[]) {}
}
