import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Gender } from '@k2/common/entities-state/types';
import { toLower } from 'ramda';

@Component({
  selector: 'person-icon',
  templateUrl: 'person-icon.component.html',
  styleUrls: ['person-icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  preserveWhitespaces: true
})
export class PersonIconComponent {
  @Input() gender: Gender;
  @Input() isChild: boolean;
  @Input() hasWarning: boolean;

  get normalizedGender(): string {
    if (toLower(this.gender) == 'male' || toLower(this.gender) == 'female') return this.gender;
    return 'unknown';
  }
}
