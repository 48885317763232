import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { isNotNil } from '@k2/common/helpers';
import { AssigneePartyMember } from '@k2/common/entities-state/types';
import { toUserName } from '@k2/common/ui/pipes/user-name.pipe';

@Component({
  selector: 'assignee-family-person-icon',
  templateUrl: 'assignee-family-person-icon.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AssigneeFamilyPersonIconComponent {
  @Input() type: string;
  @Input() person: AssigneePartyMember;
  @Input() link: any;
  @Input() isAssignmentEncrypted: boolean = true;

  get tooltip(): string {
    return this.isAssignmentEncrypted ? this.person.gender : [this.name, this.person.gender].filter(isNotNil).join(' - ');
  }

  get name(): string {
    const { person } = this;

    if (!person.first_name && !person.last_name) return 'Unspecified';
    return toUserName(person);
  }

  get isIncomplete(): boolean {
    const { person } = this;
    return person.first_name == null || person.last_name == null || person.gender == null;
  }
}
