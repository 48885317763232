<section class="assignment-services">
  <h2 class="pull-left mt0">Services</h2>
  <a [routerLink]="['initiation']" class="btn-link">View status &raquo;</a>

  <ng-container *ngIf="workflows">
    <div *ngFor="let workflow of workflows">
      <div *ngIf="workflow.services.length !== 0" class="workflow">
        <h2 class="block-header">{{ workflow.name }}</h2>
        <div class="block-content">
          <div *ngFor="let service of workflow.services" class="service">
            <table class="table-fixed">
              <tr>
                <td>
                  <span *ngIf="isVisaImmigrationService(service.id)"
                        (click)="onServiceOpenClick(service.id)"
                        class="mr-3 fa fa-chevron-right cursor-pointer"></span>
                  <a [routerLink]="toServiceLink(service.id, service.name)">
                    <service-status-indicator *ngFor="let status of service.progress"
                                              [status]="status.value"
                                              [tooltip]="status.value"></service-status-indicator>
                    {{ service.name }}
                  </a>
                </td>
                <td *ngIf="isVisaImmigrationService(service.id)">{{ getServiceStatus(service.progress) }}</td>
              </tr>
            </table>
            <table *ngIf="showAllServiceStatuses(service.id)" class="mt-2 table-fixed all-service-statuses">
              <tr *ngFor="let status of service.progress">
                <td>
                  <service-status-indicator [status]="status.value"></service-status-indicator>
                  {{ status.label }}
                </td>
                <td>{{ status.value }}</td>
              </tr>
            </table>
          </div>
        </div>
      </div>
    </div>
  </ng-container>

  <div *ngIf="hhgWorkflow" class="workflow">
    <h2 class="block-header">{{ hhgWorkflow.name }}</h2>
    <div class="block-content">
      <div *ngFor="let service of hhgWorkflow.services" class="service">
        <a *ngIf="toServiceLink(service.id, service.name)" [routerLink]="toServiceLink(service.id, service.name)">
          <service-status-indicator *ngFor="let status of service.progress"
                                    [status]="status.value"
                                    [tooltip]="status.value"></service-status-indicator>
          {{ service.name }}
        </a>
      </div>
    </div>
  </div>

  <no-data *ngIf="!hasServices">
    There are no services associated with this assignment.
  </no-data>
</section>
