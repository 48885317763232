import { ChangeDetectionStrategy, Component, ElementRef, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild, ViewEncapsulation } from '@angular/core';
import { Editor, Toolbar } from 'ngx-editor';
import { containsEmojis, removeEmojis } from "@k2/common/k2-forms/utils";

@Component({
  selector: 'markdown-editor',
  templateUrl: 'markdown-editor.component.html',
  styleUrls: ['markdown-editor.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class MarkdownEditorComponent implements OnInit, OnChanges, OnDestroy {
  @Input() id?: string;
  @Input() value: string;
  @Input() disabled = false;
  @Input() placeholder?: string;
  @Input() noPreview?: boolean = false;
  @Output() valueChange = new EventEmitter();
  @Output() touch = new EventEmitter();

  @ViewChild('preview') preview: ElementRef;

  editable = false;
  minHeight = null;

  html = '';
  editor: Editor;
  toolbar: Toolbar = [['bold', 'italic'], ['underline'], ['ordered_list', 'bullet_list'],
    ['text_color', 'background_color'], ['align_left', 'align_center', 'align_right', 'align_justify']];

  ngOnInit(): void {
    this.editor = new Editor();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.value) {
      if (!containsEmojis(changes.value.currentValue)) {
        this.html = this.value;
      } else {
        this.html = removeEmojis(changes.value.previousValue);
        if (this.editor) {
          this.editor.setContent(this.html);
        }
      }
    }
  }

  showInput() {
    if (this.preview) {
      const matDialogContentElement = document.getElementsByTagName('mat-dialog-content')[0];
      if (matDialogContentElement instanceof HTMLElement) {
        this.minHeight = matDialogContentElement.offsetHeight * 0.8 + 'px';
      }
    }

    this.editable = true;
  }

  showPreview() {
    this.editable = false;
  }

  ngOnDestroy(): void {
    this.editor.destroy();
  }
}
