<field-template>
  <field-label [field]="idField" mainLabel></field-label>

  <existing-documents-list
    [documents]="existing"
    (documentsChange)="updateExisting($event)"
  ></existing-documents-list>

  <no-file-msg *ngIf="hasNoFile">Please upload a file</no-file-msg>

  <documents-uploader
    (state)="updateState($event)"
    [multiple]="false"
  ></documents-uploader>

  <field-errors [controls]="idField.control" errors></field-errors>
</field-template>

<field [field]="nameField"></field>
