import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { K2IconComponent } from '@k2/common/k2-brand/k2-icon/k2-icon.component';
import { K2LogoComponent } from '@k2/common/k2-brand/k2-logo/k2-logo.component';
import { PageNotFoundComponent } from '@k2/common/k2-brand/page-not-found/page-not-found.component';

@NgModule({
  imports: [CommonModule, RouterModule],
  declarations: [K2LogoComponent, K2IconComponent, PageNotFoundComponent],
  exports: [K2LogoComponent, K2IconComponent, PageNotFoundComponent]
})
export class K2BrandModule {}
