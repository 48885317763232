import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { K2SelectComponent } from '@k2/common/k2-select/k2-select.component';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { CommonModule } from '@angular/common';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';

const components = [K2SelectComponent];

@NgModule({
  imports: [
    MatSelectModule,
    FormsModule,
    CommonModule,
    NgxMatSelectSearchModule,
    ReactiveFormsModule
  ],
  declarations: components,
  exports: components
})
export class K2SelectModule {}
