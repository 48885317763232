import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ImageOverlayComponent } from '@k2/common/ui/components/image-overlay/image-overlay.component';
import { ImageOverlayDirective } from '@k2/common/ui/components/image-overlay/image-overlay.directive';

@NgModule({
  imports: [CommonModule],
  declarations: [ImageOverlayDirective, ImageOverlayComponent],
  exports: [ImageOverlayDirective]
})
export class ImageOverlayModule {}
