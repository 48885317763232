<existing-documents-list
  [documents]="existing"
  (documentsChange)="updateExisting($event)"
></existing-documents-list>

<no-file-msg *ngIf="hasNoFile">
  Please upload a file / multiple files
</no-file-msg>

<documents-uploader
  (state)="updateState($event)"
  [multiple]="multiple"
></documents-uploader>
