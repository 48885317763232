import { isNotEmpty, isNotNil } from '@k2/common/helpers';
import { Address } from '@k2/common/entities-state/types';
import { trim } from 'ramda';

export function stringifyAddress(address: Address): string {
  if (isValidCoordinate(address.latitude) && isValidCoordinate(address.longitude)) {
    return `${address.latitude},${address.longitude}`;
  }

  let result = address.address1;
  if (address.address2) result += ',' + address.address2;
  if (address.address3) result += ',' + address.address3;
  if (address.address4) result += ',' + address.address4;
  if (address.city) result += ',' + address.city;
  if (address.postal_code) result += ',' + address.postal_code;
  if (address.county) result += ',' + address.county;
  if (address.country_name) result += ',' + address.country_name;

  return result;
}

function isValidCoordinate(value: string | number | null | undefined): boolean {
  if (value == null || value === '') return false;
  if (isNaN(value as any)) return false;
  return Number(value) !== 0;
}

export function inlineAddress(address: Address): string {
  return [
    address.name,
    address.address1,
    address.address2,
    address.address3,
    address.address4,
    address.city,
    address.county,
    address.postal_code
  ]
    .filter(isNotNil)
    .map(trim)
    .filter(isNotEmpty)
    .join(', ');
}
