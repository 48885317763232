import { Component, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Backend } from '@k2/common/backend/backend';
import { Client, ClientStatus, Region } from '@k2/common/entities-state/types';
import { RemoteMutatedFile } from '@k2/common/file-jet/file';
import { extractIdFromRoute, pipeValuesTo, ReplayLastSubject, Subscriptions } from '@k2/common/helpers';
import { BehaviorSubject, combineLatest, forkJoin, Observable, of, switchMap } from 'rxjs';
import { distinctUntilChanged, first, map } from 'rxjs/operators';
import { AppStore } from '@k2/common/state/services/app-store';
import { StaffAppState } from '@k2/staff/staff-app-state';
import { getAvailableData } from '@k2/common/k2-users-state/reducers';

@Component({
  selector: 'client',
  templateUrl: 'client.component.html',
  styleUrls: ['client.component.scss']
})
export class ClientComponent implements OnDestroy {
  private _client = new ReplayLastSubject<Client>();
  private _id: number;
  private readonly subscriptions = new Subscriptions();
  private readonly refreshEvents = new BehaviorSubject(null);

  readonly client: Observable<Client> = this._client.asObservable();
  readonly status: Observable<ClientStatus>;
  readonly name: Observable<string>;
  readonly shortName: Observable<string>;
  readonly region: Observable<Region>;
  readonly isNotActive: Observable<boolean>;
  readonly needsAnalysisRequired: Observable<boolean>;
  isDisplayed = false;

  constructor(route: ActivatedRoute, private backend: Backend, private store: AppStore<StaffAppState>) {
    this.refreshEvents.next(null);

    const id = extractIdFromRoute(route);

    combineLatest([this.store.pipe(getAvailableData, distinctUntilChanged()), this.refreshEvents])
      .pipe(switchMap(([availableData]) => forkJoin([of(availableData?.clients), id.pipe(first())])))
      .subscribe(([ids, id]) => {
        if (!ids || ids.includes(id)) {
          this.isDisplayed = true;
          this.backend.clients.fetchClient(id).subscribe(pipeValuesTo(this._client));
        } else {
          this.isDisplayed = false;
        }
      });

    this.status = this.client.pipe(map(client => client.status));
    this.name = this.client.pipe(map(client => client.name));
    this.shortName = this.client.pipe(map(client => client.short_name));
    this.region = this.client.pipe(map(client => client.region));
    this.needsAnalysisRequired = this.client.pipe(map(client => client.needs_analysis_required));
    this.isNotActive = this.status.pipe(map(status => status !== 'ACTIVE'));

    this.subscriptions.add(id.subscribe(id => (this._id = id)));
  }

  get id(): number {
    return this._id;
  }

  updateLogo = (logo: RemoteMutatedFile | null) => {
    this.client.pipe(first()).subscribe(client => {
      this._client.next({ ...client, logo });
    });

    this.backend.clients.updateLogo(this.id, logo).subscribe();
  };

  refresh = () => this.refreshEvents.next(null);

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
