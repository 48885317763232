import { Component, Input } from '@angular/core';
import { FieldTooltip } from '@k2/common/k2-forms-state/types';

/**
 * Common field template.
 *
 * Component provides a projection slots to keep all fields across an app consistent.
 * Pass TRUE to horizontallyCollapsed input to collapse a control's width.
 *
 * EXAMPLE:
 *   <field-template [tooltip]="{ type: 'info', text: 'awesome' }">
 *     <p notes">Some notes</label>
 *     <label mainLabel">Field label</label>
 *
 *     <input type="text">
 *
 *     <div additional>
 *       Additional HTML, which appears next to the controls
 *     </div>
 *
 *     <div errors>Field errors</div>
 *   </field-template>
 */
@Component({
  selector: 'field-template',
  templateUrl: 'field-template.component.html',
  styleUrls: ['field-template.component.scss']
})
export class FieldTemplateComponent {
  @Input() tooltip?: FieldTooltip;
  @Input() horizontallyCollapsed = false;
}
