<ng-container *ngIf="mainField && isVisible">
  <field-template
    [tooltip]="tooltip"
    [horizontallyCollapsed]="shouldCollapseHorizontally"
    [class.invoiceAddresses]="isIdInvoiceAddresses"
    [class.quoted-product-ctrl]="mainField.attributes.display_type === 'quotedProduct'"
  >
    <field-label [field]="mainField" mainLabel></field-label>

    <div [class.multiple]="fields && fields.length > 0" class="d-flex-col">
      <div *ngIf="mainField.attributes.message" class="message-container">
        {{ mainField.attributes.message }}
      </div>
      <field-control
        *ngFor="let field of fields"
        [field]="field"
        (change)="onFieldControlChange(field)"
        [changeServiceToUniversal]="changeServiceToUniversal"
      ></field-control>
    </div>

    <ng-content select="[additional]" additional></ng-content>

    <ng-container errors>
      <field-errors [controls]="controls"></field-errors>
    </ng-container>
  </field-template>
</ng-container>
