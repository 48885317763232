<annotated-control [field]="field">
  <input
    id="address1"
    type="text"
    [ngModel]="value?.address1"
    (ngModelChange)="update($event, 'address1')"
    [disabled]="disabled"
    maxlength="255"
    [placeholder]="'Address line 1 *'"
    [required]="true"
    class="form-control"
  />
  <input
    id="address2"
    type="text"
    [ngModel]="value?.address2"
    (ngModelChange)="update($event, 'address2')"
    [disabled]="disabled"
    maxlength="255"
    [placeholder]="'Address line 2'"
    class="form-control"
  />
  <input
    id="address3"
    type="text"
    [ngModel]="value?.address3"
    (ngModelChange)="update($event, 'address3')"
    [disabled]="disabled"
    maxlength="255"
    [placeholder]="'Address line 3'"
    class="form-control"
  />
  <input
    id="address4"
    type="text"
    [ngModel]="value?.address4"
    (ngModelChange)="update($event, 'address4')"
    [disabled]="disabled"
    maxlength="255"
    [placeholder]="'Address line 4'"
    class="form-control"
  />
  <input
    id="city"
    type="text"
    [ngModel]="value?.city"
    (ngModelChange)="update($event, 'city')"
    [disabled]="disabled"
    maxlength="255"
    [placeholder]="'City *'"
    [required]="true"
    class="form-control"
  />
  <input
    id="county"
    type="text"
    [ngModel]="value?.county"
    (ngModelChange)="update($event, 'county')"
    [disabled]="disabled"
    maxlength="255"
    [placeholder]="'County/state'"
    class="form-control"
  />
  <input
    id="postal_code"
    type="text"
    [ngModel]="value?.postal_code"
    (ngModelChange)="update($event, 'postal_code')"
    [disabled]="disabled"
    maxlength="255"
    [placeholder]="'Postal code'"
    class="form-control"
  />
</annotated-control>
