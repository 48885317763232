import { maximum, maximumDelta, minimum } from '@k2/common/helpers';
import { compose } from 'ramda';

export const defaultMaxZoom = 64;
export const defaultZoomModifier = 0.85;

export function calculateZoomLevel({
  positions,
  maxZoom = defaultMaxZoom,
  zoomModifier = defaultZoomModifier
}: ZoomOptions): number {
  const deltaLat = maximumDelta(toLatitudes(positions));
  const deltaLng = maximumDelta(toLongitudes(positions));

  const latZoom = 180 / deltaLat;
  const lngZoom = 360 / deltaLng;

  const zoom = latZoom < lngZoom ? latZoom : lngZoom;

  const modifiedZoom = zoom * zoomModifier;
  if (modifiedZoom > maxZoom) return maxZoom;
  if (modifiedZoom <= 1) return 1;
  return modifiedZoom;
}

export const calculateZoomLatitude = compose(findCenter, toLatitudes);
export const calculateZoomLongitude = compose(findCenter, toLongitudes);

function findCenter(values: number[]): number {
  const min = minimum(values);
  const max = maximum(values);
  return min + (max - min) / 2;
}

function toLatitudes(positions: Position[]): number[] {
  return positions.map(position => parseFloat(position.latitude as string));
}

function toLongitudes(positions: Position[]): number[] {
  return positions.map(position => parseFloat(position.longitude as string));
}

export interface ZoomOptions {
  positions: Position[];
  maxZoom?: number;
  zoomModifier?: number;
}

export interface Position {
  readonly latitude: string | number;
  readonly longitude: string | number;
}
