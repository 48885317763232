import { Component, Input } from '@angular/core';
import { Field } from '@k2/common/k2-forms-state/types';
import { WithFormControl } from '@k2/common/k2-forms/field-control/utils';
import { normalizeValueOption } from '@k2/common/k2-forms/utils';
import { K2SelectOption } from '@k2/common/k2-select/k2-select-option';

@Component({
  selector: 'k2-select-control',
  templateUrl: 'k2-select-control.component.html'
})
export class K2SelectControlComponent extends WithFormControl<number | string> {
  @Input() field: Field<number | string>;
  @Input() multiple = false;

  get options(): K2SelectOption[] {
    return this.field.attributes.valueOptions
      .map(normalizeValueOption)
      .filter(option => option.id != null)
      .map(option => ({
        id: option.id,
        text: option.value
      }));
  }
}
