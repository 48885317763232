<h2 mat-dialog-title>{{ data.title }}</h2>

<alert-bar *ngIf="data.notes">{{ data.notes }}</alert-bar>

<mat-dialog-content>
  <k2-table [items]="data.documents">
    <table>
      <thead>
        <tr>
          <th>Name</th>
          <th>Category</th>
          <th class="options">Select</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let document of data.documents | async">
          <td><document-link [document]="document"></document-link></td>
          <td>{{ document.category_label }}</td>
          <td class="options">
            <input
              type="checkbox"
              [(checklist)]="selected"
              [checklistValue]="document.id"
            />
          </td>
        </tr>
      </tbody>
    </table>

    <no-data ifNothing>There are no available options.</no-data>
  </k2-table>
</mat-dialog-content>

<mat-dialog-actions>
  <submit-control
    (onSubmit)="submit()"
    (cancel)="cancel()"
    [icon]="data.submitIcon || 'ok'"
    [disabled]="selected.length === 0"
  >
    {{ data.submitLabel }}
  </submit-control>
</mat-dialog-actions>
