import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { DocumentFile } from '@k2/common/entities-state/types';

@Component({
  selector: 'existing-documents-list',
  templateUrl: 'existing-documents-list.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ExistingDocumentsListComponent {
  @Input() documents: DocumentFile[];
  @Output() documentsChange = new EventEmitter<DocumentFile[]>();

  remove = (doc: DocumentFile) => {
    const docs = this.documents.filter(({ id }) => id !== doc.id);
    this.documentsChange.emit(docs);
  };
}
