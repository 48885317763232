<icon *ngIf="todo.status === 'pending'" type="remove" color="red"></icon>
<icon *ngIf="todo.status === 'completed'" type="ok" color="green"></icon>
<a
  *ngIf="todo.link != null && isPrevTodoComplete(todo); else noLink"
  [routerLink]="routerLinkElements"
  [queryParams]="params"
>
  {{ todo.text }}</a
>

<ng-template #noLink>
  {{ todo.text }}
</ng-template>
