import { Component, Input } from '@angular/core';
import { Flow } from '@k2/common/ui/components/components-list/components-list.component';

/**
 * A list of cards.
 */
@Component({
  selector: 'cards-list',
  templateUrl: 'cards-list.component.html'
})
export class CardsListComponent {
  @Input() flow?: Flow;
}
