import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

/**
 * An icon component which supports Bootstrap 3 Glyphicons and Material icons.
 *
 * To use a Glyphicon, just provide an icon's name as `type` argument.
 * To use Material icon, provide an icon's name prefixed with `mat_` as `type` argument.
 *
 * Icon will inherit the font color by default.
 * Use `color` to override it to one of the supported colors (see `IconColor`).
 *
 * Glyphicons: https://getbootstrap.com/docs/3.3/components/#glyphicons
 * Material icons: https://material.io/resources/icons
 */
@Component({
  selector: 'icon',
  templateUrl: 'icon.component.html',
  styleUrls: ['icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: { 'aria-hidden': 'true' }
})
export class IconComponent {
  @Input() type: string;
  @Input() color: IconColor = 'inherit';

  get isMaterialIcon(): boolean {
    return this.type.startsWith('mat_');
  }

  get asMaterialIcon(): string {
    return this.type.substring(4);
  }

  get asGlyphicon(): string {
    return `glyphicon glyphicon-${this.type} ${this.color}`;
  }
}

type IconColor = 'inherit' | 'green' | 'red' | 'orange' | 'grey' | 'blue';
