import { Pipe, PipeTransform } from '@angular/core';
import { stringifyAddress } from '@k2/common/addresses/utils';
import { Address } from '@k2/common/entities-state/types';

/**
 * Transforms location object into Google Maps link.
 */
@Pipe({
  name: 'gMapLink'
})
export class GMapLinkPipe implements PipeTransform {
  transform(address: Address): string {
    if (address == null) return;
    return toGMapUrl('https://maps.google.com/?q=', address);
  }
}

/**
 * Transforms location object into Google Maps link,
 * which can be used to embed a map into the website.
 */
@Pipe({
  name: 'embedGMapLink'
})
export class EmbedGMapLinkPipe implements PipeTransform {
  transform(address: Address): string {
    if (address == null) return;
    return toGMapUrl('https://www.google.com/maps/embed/v1/place?q=', address);
  }
}

function toGMapUrl(prefix: string, address: Address) {
  return encodeURI(prefix + stringifyAddress(address));
}
