import { ApiClient } from '@k2/common/backend/api-client';
import { AssignmentIdentity, K2CostingOptionsData, TransitItemType } from '@k2/common/entities-state/types';
import { toFormSpecNormalizer } from '@k2/common/k2-forms/utils';
import { values } from 'ramda';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import {
  TransitBundle
} from '@k2/staff/content/clients/client/assignment/hhg/costing/quotes-review/transit-bundles/transit-bundles.component';

export class AssignmentHhgEndpoint {
  constructor(private api: ApiClient) {
  }

  fetchInitiationOverview = ({ clientId, assignmentId }: AssignmentIdentity) => {
    return this.api.get(`/clients/${clientId}/assignment/${assignmentId}/hhg/initiation/overview`);
  };

  fetchTransits = ({ clientId, assignmentId }: AssignmentIdentity) => {
    return this.api.get(`/clients/${clientId}/assignment/${assignmentId}/hhg/transits`);
  };

  updateTransits = ({ clientId, assignmentId }: AssignmentIdentity, data: any) => {
    return this.api.put(`/clients/${clientId}/assignment/${assignmentId}/hhg/transits`, data);
  };

  fetchNewTransitForm = ({ clientId, assignmentId }: AssignmentIdentity) => {
    return this.api
      .get(`/clients/${clientId}/assignment/${assignmentId}/hhg/transits/new`)
      .pipe(map(payload => payload.form));
  };

  createTransit = ({ clientId, assignmentId }: AssignmentIdentity, data: any) => {
    return this.api.post(`/clients/${clientId}/assignment/${assignmentId}/hhg/transits`, data);
  };

  fetchTransitForm = ({ clientId, assignmentId }: AssignmentIdentity, transitId: string) => {
    return this.api
      .get(`/clients/${clientId}/assignment/${assignmentId}/hhg/transits/${transitId}`)
      .pipe(map(payload => payload.form));
  };

  updateTransit = (
    { clientId, assignmentId }: AssignmentIdentity,
    transitId: string,
    data: any
  ) => {
    return this.api.patch(
      `/clients/${clientId}/assignment/${assignmentId}/hhg/transits/${transitId}`,
      data
    );
  };

  deleteTransit = ({ clientId, assignmentId }: AssignmentIdentity, transitId: string) => {
    return this.api.delete(
      `/clients/${clientId}/assignment/${assignmentId}/hhg/transits/${transitId}`
    );
  };

  fetchAddresses = ({ clientId, assignmentId }: AssignmentIdentity) => {
    return this.api.get(`/clients/${clientId}/assignment/${assignmentId}/hhg/addresses`);
  };

  fetchNewAssigneeAddressForm = ({ clientId, assignmentId }: AssignmentIdentity) => {
    return this.api
      .get(`/clients/${clientId}/assignment/${assignmentId}/hhg/addresses/new`)
      .pipe(map(payload => payload.assignee_address));
  };

  createAssigneeAddress = ({ clientId, assignmentId }: AssignmentIdentity, data: any) => {
    return this.api.post(
      `/clients/${clientId}/assignment/${assignmentId}/hhg/addresses/assignee`,
      data
    );
  };

  fetchAssigneeAddressForm = (
    { clientId, assignmentId }: AssignmentIdentity,
    addressId: number
  ) => {
    return this.api
      .get(`/clients/${clientId}/assignment/${assignmentId}/hhg/addresses/assignee/${addressId}`)
      .pipe(map(payload => payload.assignee_address));
  };

  updateAssigneeAddress = (
    { clientId, assignmentId }: AssignmentIdentity,
    addressId: number,
    data: any
  ) => {
    return this.api.patch(
      `/clients/${clientId}/assignment/${assignmentId}/hhg/addresses/assignee/${addressId}`,
      data
    );
  };

  fetchNewStorageAddressForm = ({ clientId, assignmentId }: AssignmentIdentity) => {
    return this.api
      .get(`/clients/${clientId}/assignment/${assignmentId}/hhg/addresses/new`)
      .pipe(map(payload => payload.partner_address));
  };

  createStorageAddress = ({ clientId, assignmentId }: AssignmentIdentity, data: any) => {
    return this.api.post(
      `/clients/${clientId}/assignment/${assignmentId}/hhg/addresses/storage`,
      data
    );
  };

  fetchStorageAddressForm = ({ clientId, assignmentId }: AssignmentIdentity) => {
    return this.api
      .get(`/clients/${clientId}/assignment/${assignmentId}/hhg/addresses/storage`)
      .pipe(map(payload => payload.partner_address));
  };

  updateStorageAddress = ({ clientId, assignmentId }: AssignmentIdentity, data: any) => {
    return this.api.patch(
      `/clients/${clientId}/assignment/${assignmentId}/hhg/addresses/storage`,
      data
    );
  };

  removeStorageAddress = ({ clientId, assignmentId }: AssignmentIdentity, addressId: number) => {
    return this.api.delete(
      `/clients/${clientId}/assignment/${assignmentId}/hhg/addresses/storage/${addressId}`
    );
  };

  fetchStorage = ({ clientId, assignmentId }: AssignmentIdentity) => {
    return this.api
      .get(`/clients/${clientId}/assignment/${assignmentId}/hhg/storage`)
      .pipe(map(payload => payload.storage));
  };

  fetchNewStorageForm = ({ clientId, assignmentId }: AssignmentIdentity) => {
    return this.api
      .get(`/clients/${clientId}/assignment/${assignmentId}/hhg/storage/new`)
      .pipe(map(payload => payload.form));
  };

  createStorage = ({ clientId, assignmentId }: AssignmentIdentity, data: any) => {
    return this.api.post(`/clients/${clientId}/assignment/${assignmentId}/hhg/storage`, data);
  };

  fetchNewStorageRevisionForm = (
    { clientId, assignmentId }: AssignmentIdentity,
    storageLocation: string
  ) => {
    return this.api
      .get(
        `/clients/${clientId}/assignment/${assignmentId}/hhg/storage/${storageLocation}/revision/new`
      )
      .pipe(map(payload => payload.form));
  };

  createStorageRevision = (
    { clientId, assignmentId }: AssignmentIdentity,
    storageLocation: string,
    data: any
  ) => {
    return this.api.post(
      `/clients/${clientId}/assignment/${assignmentId}/hhg/storage/${storageLocation}/revisions`,
      data
    );
  };

  deleteStorageRevision = (
    { clientId, assignmentId }: AssignmentIdentity,
    storageLocation: string,
    revisionId: string
  ) => {
    return this.api.delete(
      `/clients/${clientId}/assignment/${assignmentId}/hhg/storage/${storageLocation}/revisions/${revisionId}`
    );
  };

  fetchNewStorageInspectionForm = (
    { clientId, assignmentId }: AssignmentIdentity,
    storageLocation: string
  ) => {
    return this.api
      .get(
        `/clients/${clientId}/assignment/${assignmentId}/hhg/storage/${storageLocation}/inspections/new`
      )
      .pipe(map(payload => payload.form));
  };

  createStorageInspection = (
    { clientId, assignmentId }: AssignmentIdentity,
    storageLocation: string,
    data: any
  ) => {
    return this.api.post(
      `/clients/${clientId}/assignment/${assignmentId}/hhg/storage/${storageLocation}/inspections`,
      data
    );
  };

  fetchStorageInspectionForm = (
    { clientId, assignmentId }: AssignmentIdentity,
    storageLocation: string,
    inspectionId: string
  ) => {
    return this.api
      .get(
        `/clients/${clientId}/assignment/${assignmentId}/hhg/storage/${storageLocation}/inspections/${inspectionId}`
      )
      .pipe(map(payload => payload.form));
  };

  updateStorageInspection = (
    { clientId, assignmentId }: AssignmentIdentity,
    storageLocation: string,
    inspectionId: string,
    data: any
  ) => {
    return this.api.patch(
      `/clients/${clientId}/assignment/${assignmentId}/hhg/storage/${storageLocation}/inspections/${inspectionId}`,
      data
    );
  };

  deleteStorageInspection = (
    { clientId, assignmentId }: AssignmentIdentity,
    storageLocation: string,
    inspectionId: string
  ) => {
    return this.api.delete(
      `/clients/${clientId}/assignment/${assignmentId}/hhg/storage/${storageLocation}/inspections/${inspectionId}`
    );
  };

  fetchNewStorageBillingForm = (
    { clientId, assignmentId }: AssignmentIdentity,
    storageLocation: string
  ) => {
    return this.api
      .get(
        `/clients/${clientId}/assignment/${assignmentId}/hhg/storage/${storageLocation}/billings/new`
      )
      .pipe(map(payload => payload.form));
  };

  createStorageBilling = (
    { clientId, assignmentId }: AssignmentIdentity,
    storageLocation: string,
    data: any
  ) => {
    return this.api.post(
      `/clients/${clientId}/assignment/${assignmentId}/hhg/storage/${storageLocation}/billings`,
      data
    );
  };

  fetchStorageBillingForm = (
    { clientId, assignmentId }: AssignmentIdentity,
    storageLocation: string,
    billingId: string
  ) => {
    return this.api
      .get(
        `/clients/${clientId}/assignment/${assignmentId}/hhg/storage/${storageLocation}/billings/${billingId}`
      )
      .pipe(map(payload => payload.form));
  };

  updateStorageBilling = (
    { clientId, assignmentId }: AssignmentIdentity,
    storageLocation: string,
    billingId: string,
    data: any
  ) => {
    return this.api.patch(
      `/clients/${clientId}/assignment/${assignmentId}/hhg/storage/${storageLocation}/billings/${billingId}`,
      data
    );
  };

  deleteStorageBilling = (
    { clientId, assignmentId }: AssignmentIdentity,
    storageLocation: string,
    billingId: string
  ) => {
    return this.api.delete(
      `/clients/${clientId}/assignment/${assignmentId}/hhg/storage/${storageLocation}/billings/${billingId}`
    );
  };

  fetchSurveys = ({ clientId, assignmentId }: AssignmentIdentity) => {
    return this.api.get(`/clients/${clientId}/assignment/${assignmentId}/hhg/surveys/details`);
  };

  updateSurvey = ({ clientId, assignmentId }: AssignmentIdentity, data: any) => {
    return this.api.patch(
      `/clients/${clientId}/assignment/${assignmentId}/hhg/surveys/details`,
      data
    );
  };

  updateSurveyDates = ({ clientId, assignmentId }: AssignmentIdentity, data: any) => {
    return this.api.patch(
      `/clients/${clientId}/assignment/${assignmentId}/hhg/surveys/dates`,
      data
    );
  };

  fetchNewSurveyInitiationForm = (
    { clientId, assignmentId }: AssignmentIdentity,
    partnerId: number
  ) => {
    return this.api
      .get(`/clients/${clientId}/assignment/${assignmentId}/hhg/surveys/initiate/${partnerId}/form`)
      .pipe(map(payload => payload.form));
  };

  initiateSurvey = (
    { clientId, assignmentId }: AssignmentIdentity,
    partnerId: number,
    data: any
  ) => {
    return this.api.post(
      `/clients/${clientId}/assignment/${assignmentId}/hhg/surveys/initiate/${partnerId}/form`,
      data
    );
  };

  markSurveyAsInitiated = ({ clientId, assignmentId }: AssignmentIdentity, partnerId: number) => {
    return this.api.post(
      `/clients/${clientId}/assignment/${assignmentId}/hhg/surveys/initiate/${partnerId}/offplatform`,
      null
    );
  };

  fetchNewSurveyForm = ({ clientId, assignmentId }: AssignmentIdentity) => {
    return this.api
      .get(`/clients/${clientId}/assignment/${assignmentId}/hhg/surveys/new`)
      .pipe(map(payload => payload.form));
  };

  createSurvey = ({ clientId, assignmentId }: AssignmentIdentity, data: any) => {
    return this.api.post(`/clients/${clientId}/assignment/${assignmentId}/hhg/surveys`, data);
  };

  fetchSurveyPropertiesDetails = ({ clientId, assignmentId }: AssignmentIdentity) => {
    return this.api.get(
      `/clients/${clientId}/assignment/${assignmentId}/hhg/surveys/property/details`
    );
  };

  updateSurveyPropertyDetails = (
    { clientId, assignmentId }: AssignmentIdentity,
    addressId: number | string,
    data: any
  ) => {
    return this.api.patch(
      `/clients/${clientId}/assignment/${assignmentId}/hhg/surveys/property/${addressId}/details`,
      data
    );
  };

  fetchSurveyVolumes = ({ clientId, assignmentId }: AssignmentIdentity) => {
    return this.api.get(
      `/clients/${clientId}/assignment/${assignmentId}/hhg/surveys/property/volumes`
    );
  };

  updateSurveyVolumesScale = ({ clientId, assignmentId }: AssignmentIdentity, scale: number) => {
    const data = { measurement_scale_types_id: scale };
    return this.api.patch(
      `/clients/${clientId}/assignment/${assignmentId}/hhg/surveys/property/volumes/scale`,
      data
    );
  };

  acceptMove4USurvey = ({ clientId, assignmentId }: AssignmentIdentity, externalSurveyId: number) => {
    const data = { external_survey_id: externalSurveyId };
    return this.api.patch(
      `/clients/${clientId}/assignment/${assignmentId}/hhg/surveys/move4u/survey/${externalSurveyId}/accept`,
      data
    );
  }

  removeMove4USurvey = ({ clientId, assignmentId }: AssignmentIdentity, externalSurveyId: number) => {
    const data = { external_survey_id: externalSurveyId };
    return this.api.patch(
      `/clients/${clientId}/assignment/${assignmentId}/hhg/surveys/move4u/survey/${externalSurveyId}/reject`,
      data
    );
  }

  updateSurveyTransit = (
    { clientId, assignmentId }: AssignmentIdentity,
    transitId: string,
    data: any
  ) => {
    return this.api.patch(
      `/clients/${clientId}/assignment/${assignmentId}/hhg/surveys/property/volumes/transit/${transitId}`,
      data
    );
  };

  fetchNewTransitItemForm = (
    { clientId, assignmentId }: AssignmentIdentity,
    transitId: string,
    itemType: TransitItemType
  ) => {
    return this.api
      .get(
        `/clients/${clientId}/assignment/${assignmentId}/hhg/surveys/property/volumes/transit/${transitId}/${itemType}/new`
      )
      .pipe(map(payload => payload.form));
  };

  createTransitItem = (
    { clientId, assignmentId }: AssignmentIdentity,
    transitId: string,
    itemType: TransitItemType,
    data: any
  ) => {
    return this.api.post(
      `/clients/${clientId}/assignment/${assignmentId}/hhg/surveys/property/volumes/transit/${transitId}/${itemType}`,
      data
    );
  };

  fetchTransitItemForm = (
    { clientId, assignmentId }: AssignmentIdentity,
    transitId: string,
    itemType: TransitItemType,
    itemId: string
  ) => {
    return this.api
      .get(
        `/clients/${clientId}/assignment/${assignmentId}/hhg/surveys/property/volumes/transit/${transitId}/${itemType}/${itemId}`
      )
      .pipe(map(payload => payload.form));
  };

  updateTransitItem = (
    { clientId, assignmentId }: AssignmentIdentity,
    transitId: string,
    itemType: TransitItemType,
    itemId: string,
    data: any
  ) => {
    return this.api.patch(
      `/clients/${clientId}/assignment/${assignmentId}/hhg/surveys/property/volumes/transit/${transitId}/${itemType}/${itemId}`,
      data
    );
  };

  deleteTransitItem = (
    { clientId, assignmentId }: AssignmentIdentity,
    transitId: string,
    itemType: TransitItemType,
    itemId: string
  ) => {
    return this.api.delete(
      `/clients/${clientId}/assignment/${assignmentId}/hhg/surveys/property/volumes/transit/${transitId}/${itemType}/${itemId}`
    );
  };

  fetchTransitPhotoCandidates = (
    { clientId, assignmentId }: AssignmentIdentity,
    transitId: string
  ) => {
    return this.api
      .get(
        `/clients/${clientId}/assignment/${assignmentId}/hhg/surveys/property/volumes/transit/${transitId}/photos/candidates`
      )
      .pipe(
        map(payload => payload.documents),
        map(values)
      );
  };

  addTransitPhotos = (
    { clientId, assignmentId }: AssignmentIdentity,
    transitId: string,
    ids: string | number[]
  ) => {
    const data = { selected_documents: ids };

    return this.api.put(
      `/clients/${clientId}/assignment/${assignmentId}/hhg/surveys/property/volumes/transit/${transitId}/photos`,
      data
    );
  };

  removeTransitPhoto = (
    { clientId, assignmentId }: AssignmentIdentity,
    transitId: string,
    photoId: string
  ) => {
    return this.api.delete(
      `/clients/${clientId}/assignment/${assignmentId}/hhg/surveys/property/volumes/transit/${transitId}/photo/${photoId}`
    );
  };

  fetchSpecialistServices = ({ clientId, assignmentId }: AssignmentIdentity) => {
    return this.api.get(
      `/clients/${clientId}/assignment/${assignmentId}/hhg/surveys/specialist/services`
    );
  };

  fetchNewSpecialistServiceForm = ({ clientId, assignmentId }: AssignmentIdentity) => {
    return this.api
      .get(`/clients/${clientId}/assignment/${assignmentId}/hhg/surveys/specialist/services/new`)
      .pipe(map(payload => payload.form));
  };

  createSpecialistService = ({ clientId, assignmentId }: AssignmentIdentity, data: any) => {
    return this.api
      .post(`/clients/${clientId}/assignment/${assignmentId}/hhg/surveys/specialist/services`, data)
      .pipe(map(payload => payload.specialist_services));
  };

  fetchSpecialistServiceForm = (
    { clientId, assignmentId }: AssignmentIdentity,
    serviceId: string
  ) => {
    return this.api
      .get(
        `/clients/${clientId}/assignment/${assignmentId}/hhg/surveys/specialist/services/${serviceId}`
      )
      .pipe(map(payload => payload.form));
  };

  updateSpecialistService = (
    { clientId, assignmentId }: AssignmentIdentity,
    serviceId: string,
    data: any
  ) => {
    return this.api
      .patch(
        `/clients/${clientId}/assignment/${assignmentId}/hhg/surveys/specialist/services/${serviceId}`,
        data
      )
      .pipe(map(payload => payload.specialist_services));
  };

  deleteSpecialistService = ({ clientId, assignmentId }: AssignmentIdentity, serviceId: string) => {
    return this.api.delete(
      `/clients/${clientId}/assignment/${assignmentId}/hhg/surveys/specialist/services/${serviceId}`
    );
  };

  submitVolumesForCosting = ({ clientId, assignmentId }: AssignmentIdentity) => {
    const data = { survey_is_locked: true };
    return this.api.patch(
      `/clients/${clientId}/assignment/${assignmentId}/hhg/surveys/property/volumes`,
      data
    );
  };

  fetchCostingsConfiguration = ({ clientId, assignmentId }: AssignmentIdentity) => {
    return this.api.get(`/clients/${clientId}/assignment/${assignmentId}/hhg/costings/configure`);
  };

  updateCostingsConfiguration = ({ clientId, assignmentId }: AssignmentIdentity, data: any) => {
    return this.api.put(
      `/clients/${clientId}/assignment/${assignmentId}/hhg/costings/configure`,
      data
    );
  };

  fetchCostingsPartnerForm = ({ clientId, assignmentId }: AssignmentIdentity) => {
    return this.api
      .get(`/clients/${clientId}/assignment/${assignmentId}/hhg/costings/partner/select`)
      .pipe(map(payload => payload.form));
  };

  addCostingsPartner = ({ clientId, assignmentId }: AssignmentIdentity, data: any) => {
    return this.api.post(
      `/clients/${clientId}/assignment/${assignmentId}/hhg/costings/partners`,
      data
    );
  };

  addAditionalStorage = ({
                           clientId,
                           assignmentId
                         }: AssignmentIdentity, data: any, supplierConfirmationIds: number[]) => {
    return this.api.post(
      `/clients/${clientId}/assignment/${assignmentId}/hhg/costings/confirmations/email/${supplierConfirmationIds.join()}/section/origin_addresses/new`,
      data
    );
  };

  generateDocument = ({
                        clientId,
                        assignmentId
                      }: AssignmentIdentity, supplierConfirmationId: string, documentType: string) => {
    return this.api.post(
      `/clients/${clientId}/assignment/${assignmentId}/hhg/costings/confirmations/email/${supplierConfirmationId}/section/documents/generate/${documentType}`,
      {}
    );
  };

  resetCostings = ({ clientId, assignmentId }: AssignmentIdentity) => {
    return this.api.delete(`/clients/${clientId}/assignment/${assignmentId}/hhg/costings`);
  };

  deleteCostingsPartner = (
    { clientId, assignmentId }: AssignmentIdentity,
    costRequestId: number
  ) => {
    return this.api.delete(
      `/clients/${clientId}/assignment/${assignmentId}/hhg/costings/partners/request/${costRequestId}`
    );
  };

  fetchCostRequestForms = ({ clientId, assignmentId }: AssignmentIdentity, requestId: string) => {
    return this.api
      .get(
        `/clients/${clientId}/assignment/${assignmentId}/hhg/costings/generate/${requestId}/forms`
      )
      .pipe(map(payload => payload.forms));
  };

  updateCostRequestForm = (
    { clientId, assignmentId }: AssignmentIdentity,
    data: { container_id: string; cost_request_id: number } & any
  ) => {
    return this.api.patch(
      `/clients/${clientId}/assignment/${assignmentId}/hhg/costings/request/email/${data.cost_request_id}/section/${data.container_id}`,
      data
    );
  };

  sendCostRequestEmail = ({ clientId, assignmentId }: AssignmentIdentity, requestId: number) => {
    return this.api.patch(
      `/clients/${clientId}/assignment/${assignmentId}/hhg/costings/request/email/${requestId}/confirm/send`,
      null
    );
  };

  fetchConfirmationsForms({
                            clientId,
                            assignmentId
                          }: AssignmentIdentity, confirmationsId: string, isBulkGeneration = false) {
    return this.api.post(
      `/clients/${clientId}/assignment/${assignmentId}/hhg/costings/${isBulkGeneration ? 'bulk-' : ''}confirmations/email/generate/${confirmationsId}/forms`, {}
    );
  }

  updateConfirmationsForm({ clientId, assignmentId }: AssignmentIdentity, data, supplierConfirmationIds: number[]) {
    const res = `${supplierConfirmationIds.length > 1 ? 'bulk-' : ''}confirmations/email/${supplierConfirmationIds.join()}`;

    return this.api
      .patch(`/clients/${clientId}/assignment/${assignmentId}/hhg/costings/${res}/section/${data.container_id}`, data);
  };

  getPreviewConfirmationEmailPDF({
                                   clientId,
                                   assignmentId
                                 }: AssignmentIdentity, supplierConfirmationIds: number[]): Observable<string> {
    return this.api.get(`/clients/${clientId}/assignment/${assignmentId}/hhg/supplier/confirmation/email/${supplierConfirmationIds.join()}/html-preview`)
      .pipe(map(payload => payload.html));
  }

  sendCostConfirmationsEmail({ clientId, assignmentId }: AssignmentIdentity, supplierConfirmationIds: number[]) {
    const res = `${supplierConfirmationIds.length > 1 ? 'bulk-' : ''}confirmations/email/${supplierConfirmationIds.join()}`;

    return this.api.patch(`/clients/${clientId}/assignment/${assignmentId}/hhg/costings/${res}/confirm/send`, null);
  }

  getPDF({ clientId, assignmentId }: AssignmentIdentity, supplierConfirmationIds: number[]) {
    return this.api.get(`/clients/${clientId}/assignment/${assignmentId}/hhg/supplier/confirmation/email/${supplierConfirmationIds.join()}/pdf-preview`);
  }

  fetchAdditionalCostRequestServices({ clientId, assignmentId }: AssignmentIdentity, partnerId: number) {
    return this.api
      .get(`/clients/${clientId}/assignment/${assignmentId}/hhg/costings/partners/${partnerId}/additional/services`)
      .pipe(map(payload => payload.transits));
  }

  addAdditionalCostRequestServices({ clientId, assignmentId }: AssignmentIdentity, partnerId: number, data: any) {
    return this.api.put(
      `/clients/${clientId}/assignment/${assignmentId}/hhg/costings/partners/${partnerId}/additional/services`,
      data
    );
  }

  fetchCostingsConfirmations = ({ clientId, assignmentId }: AssignmentIdentity) => {
    return this.api.get(`/clients/${clientId}/assignment/${assignmentId}/hhg/quotes/options/confirmations`);
  };

  updateCostingsConfirmations = ({ clientId, assignmentId }: AssignmentIdentity, data: any) => {
    return this.api.patch(
      `/clients/${clientId}/assignment/${assignmentId}/hhg/quotes/options/confirmations/approved`,
      data
    );
  };

  completeCostingsConfirmations = ({ clientId, assignmentId }: AssignmentIdentity, data: any) => {
    return this.api.patch(
      `/clients/${clientId}/assignment/${assignmentId}/hhg/quotes/options/confirmations/complete`,
      data
    );
  };

  deleteCostingConfirmation = ({ clientId, assignmentId }: AssignmentIdentity, confirmationId: string) => {
    return this.api.delete(
      `/clients/${clientId}/assignment/${assignmentId}/hhg/quotes/options/confirmations/${confirmationId}`
    );
  };

  fetchTracking = ({ clientId, assignmentId }: AssignmentIdentity) => {
    return this.api.get(`/clients/${clientId}/assignment/${assignmentId}/hhg/tracking`);
  };

  updateTrackingTransit = (
    { clientId, assignmentId }: AssignmentIdentity,
    transitId: string,
    formType: 'origin' | 'destination',
    data
  ) => {
    return this.api.patch(
      `/clients/${clientId}/assignment/${assignmentId}/hhg/tracking/transit/${transitId}/originstatus/${formType}`,
      data
    );
  };

  updateTrackingSpecialist = (
    { clientId, assignmentId }: AssignmentIdentity,
    serviceId: string,
    data
  ) => {
    return this.api.patch(
      `/clients/${clientId}/assignment/${assignmentId}/hhg/tracking/services/${serviceId}`,
      data
    );
  };

  fetchNewAdditionalTransitCostForm = (
    { clientId, assignmentId }: AssignmentIdentity,
    transitId: string
  ) => {
    return this.api
      .get(
        `/clients/${clientId}/assignment/${assignmentId}/hhg/tracking/transit/${transitId}/costs/form`
      )
      .pipe(map(payload => payload.form));
  };

  createAdditionalTransitCost = (
    { clientId, assignmentId }: AssignmentIdentity,
    transitId: string,
    data
  ) => {
    return this.api
      .post(
        `/clients/${clientId}/assignment/${assignmentId}/hhg/tracking/transit/${transitId}/costs`,
        data
      )
      .pipe(map(payload => payload.form));
  };

  fetchInsuranceOverview = ({ clientId, assignmentId }: AssignmentIdentity) => {
    return this.api
      .get(`/clients/${clientId}/assignment/${assignmentId}/hhg/insurance`)
      .pipe(map(payload => payload.insurance));
  };

  fetchNewInsuranceActionForm = ({ clientId, assignmentId }: AssignmentIdentity) => {
    return this.api
      .get(`/clients/${clientId}/assignment/${assignmentId}/hhg/insurance/pending/new`)
      .pipe(map(payload => payload.form));
  };

  fetchInsuranceActionForm = ({ clientId, assignmentId }: AssignmentIdentity, actionId: string) => {
    return this.api
      .get(`/clients/${clientId}/assignment/${assignmentId}/hhg/insurance/pending/${actionId}/form`)
      .pipe(map(payload => payload.form));
  };

  createNewInsuranceAction = ({ clientId, assignmentId }: AssignmentIdentity, data) => {
    return this.api.post(
      `/clients/${clientId}/assignment/${assignmentId}/hhg/insurance/pending/new`,
      data
    );
  };

  updateInsuranceAction = (
    { clientId, assignmentId }: AssignmentIdentity,
    actionId: string,
    data
  ) => {
    return this.api.post(
      `/clients/${clientId}/assignment/${assignmentId}/hhg/insurance/pending/${actionId}`,
      data
    );
  };

  deleteInsuranceAction = ({ clientId, assignmentId }: AssignmentIdentity, actionId: string) => {
    return this.api
      .delete(`/clients/${clientId}/assignment/${assignmentId}/hhg/insurance/pending/${actionId}`)
      .pipe(map(payload => payload.insurance));
  };

  fetchInsuranceActionCertForm = (
    { clientId, assignmentId }: AssignmentIdentity,
    actionId: string
  ) => {
    return this.api
      .get(
        `/clients/${clientId}/assignment/${assignmentId}/hhg/insurance/insure/${actionId}/certificate/form`
      )
      .pipe(map(payload => payload.form));
  };

  addInsuranceActionCert = (
    { clientId, assignmentId }: AssignmentIdentity,
    actionId: string,
    data
  ) => {
    return this.api.post(
      `/clients/${clientId}/assignment/${assignmentId}/hhg/insurance/insure/${actionId}/certificate`,
      data
    );
  };

  fetchNewInsuranceTransitActionForm = (
    { clientId, assignmentId }: AssignmentIdentity,
    transitId: string
  ) => {
    return this.api
      .get(
        `/clients/${clientId}/assignment/${assignmentId}/hhg/insurance/transit/${transitId}/additional/action/form`
      )
      .pipe(map(payload => payload.form));
  };

  fetchEditInsuranceTransitActionForm = (
    { clientId, assignmentId }: AssignmentIdentity,
    transitId: string,
    actionId: string
  ) => {
    return this.api
      .get(
        `/clients/${clientId}/assignment/${assignmentId}/hhg/insurance/transit/${transitId}/edit/${actionId}`
      )
      .pipe(map(payload => payload.form));
  };

  updateInsuranceTransitAction = (
    { clientId, assignmentId }: AssignmentIdentity,
    transitId: string,
    actionId: string,
    data
  ) => {
    return this.api.patch(
      `/clients/${clientId}/assignment/${assignmentId}/hhg/insurance/transit/${transitId}/edit/${actionId}`,
      claimFormNormalizer.forBackend(data)
    );
  };

  createInsuranceTransitAction = (
    { clientId, assignmentId }: AssignmentIdentity,
    transitId: string,
    data
  ) => {
    return this.api.post(
      `/clients/${clientId}/assignment/${assignmentId}/hhg/insurance/transit/${transitId}/additional/action`,
      data
    );
  };

  fetchNewInsuranceClaimForm = ({ clientId, assignmentId }: AssignmentIdentity) => {
    return this.api
      .get(`/clients/${clientId}/assignment/${assignmentId}/hhg/insurance/claims/form`)
      .pipe(map(payload => payload.form));
  };

  createInsuranceClaim = ({ clientId, assignmentId }: AssignmentIdentity, data) => {
    return this.api.post(
      `/clients/${clientId}/assignment/${assignmentId}/hhg/insurance/claims`,
      claimFormNormalizer.forBackend(data)
    );
  };

  fetchInsuranceClaimForm = ({ clientId, assignmentId }: AssignmentIdentity, claimId: string) => {
    return this.api
      .get(`/clients/${clientId}/assignment/${assignmentId}/hhg/insurance/claim/${claimId}/form`)
      .pipe(
        map(payload => payload.form),
        map(claimFormNormalizer.forFrontend)
      );
  };

  updateInsuranceClaim = (
    { clientId, assignmentId }: AssignmentIdentity,
    claimId: string,
    data
  ) => {
    return this.api.patch(
      `/clients/${clientId}/assignment/${assignmentId}/hhg/insurance/claim/${claimId}`,
      claimFormNormalizer.forBackend(data)
    );
  };

  deleteInsuranceClaim = ({ clientId, assignmentId }: AssignmentIdentity, claimId: string) => {
    return this.api
      .delete(`/clients/${clientId}/assignment/${assignmentId}/hhg/insurance/claim/${claimId}`)
      .pipe(map(payload => payload.insurance));
  };

  fetchQuotes = ({ clientId, assignmentId }: AssignmentIdentity) => {
    return this.api.get(`/clients/${clientId}/assignment/${assignmentId}/hhg/quotes/overview`);
  };

  fetchQuoteForms = ({ clientId, assignmentId }: AssignmentIdentity, quoteId: number) => {
    return this.api.get(
      `/clients/${clientId}/assignment/${assignmentId}/hhg/quotes/${quoteId}/forms`
    );
  };

  updateQuotes = ({ clientId, assignmentId }: AssignmentIdentity, quoteId: number, data: any) => {
    return this.api.patch(
      `/clients/${clientId}/assignment/${assignmentId}/hhg/quotes/${quoteId}`,
      data
    );
  };

  generateQuoteOptions = ({ clientId, assignmentId }: AssignmentIdentity, data: any) => {
    return this.api.put(
      `/clients/${clientId}/assignment/${assignmentId}/hhg/quotes/options/generate`,
      data
    );
  };

  patchQuoteOptions = ({ clientId, assignmentId }: AssignmentIdentity, data: any) => {
    return this.api.patch(
      `/clients/${clientId}/assignment/${assignmentId}/hhg/quotes/options/generate`,
      data
    );
  };

  regenerateBidSummary = ({ clientId, assignmentId }: AssignmentIdentity, data: any) => {
    return this.api.patch(`/clients/${clientId}/assignment/${assignmentId}/hhg/quotes/options/regenerate`, data);
  };

  getBidSummaryReportForm(offerHash: string) {
    return this.api.get(`/hhg/quotes/offer/${offerHash}`).pipe(take(1), map(response => response.form));
  }

  getBidSummaryReportData(offerHash: string, email: string) {
    return this.api.patch(`/hhg/quotes/offer/${offerHash}`, email).pipe(take(1), map(res => ({
      ...res.offer,
      specialist_services: Object.values(res.offer.specialist_services)
    })));
  }

  fetchApprovingMCEForm(offerHash: string) {
    return this.api.get(`/hhg/quotes/offer/${offerHash}/approve`)
      .pipe(take(1), map(res => res.form));
  }

  approveBidSummaryBillingInfo(offerHash: string, data) {
    return this.api.patch(`/hhg/quotes/offer/${offerHash}/approve`, data)
      .pipe(take(1), map(res => res.offer));
  }

  fetchQuoteOptionsStatus = ({ clientId, assignmentId }: AssignmentIdentity) => {
    return this.api.get(
      `/clients/${clientId}/assignment/${assignmentId}/hhg/quotes/options/status`
    );
  };

  fetchQuoteOptionsReview = ({ clientId, assignmentId }: AssignmentIdentity) => {
    return this.api.get(
      `/clients/${clientId}/assignment/${assignmentId}/hhg/quotes/options/review`
    );
  };

  resetQuoteOptions = ({ clientId, assignmentId }: AssignmentIdentity) => {
    return this.api.delete(
      `/clients/${clientId}/assignment/${assignmentId}/hhg/quotes/options/reset`
    );
  };

  approveQuoteOptionsByK2 = ({ clientId, assignmentId }: AssignmentIdentity) => {
    return this.api.patch(
      `/clients/${clientId}/assignment/${assignmentId}/hhg/quotes/options/k2/approve`,
      null
    );
  };

  sendToWorkspaceQuoteOptions = ({ clientId, assignmentId }: AssignmentIdentity, hash: string) => {
    return this.api.patch(
      `/clients/${clientId}/assignment/${assignmentId}/hhg/quotes/options/k2workspace/send/${hash}`,
      null
    );
  };

  fetchK2CostingOptions({ clientId, assignmentId }: AssignmentIdentity) {
    return this.api.get(`/clients/${clientId}/assignment/${assignmentId}/hhg/quotes/options/costing`);
  }

  updateK2CostingOptions({ clientId, assignmentId }: AssignmentIdentity, data: K2CostingOptionsData) {
    return this.api.patch(`/clients/${clientId}/assignment/${assignmentId}/hhg/quotes/options/costing`, data);
  }

  updateK2CostingOptionsRounding({ clientId, assignmentId }: AssignmentIdentity, rounding: number) {
    return this.api.patch(`/clients/${clientId}/assignment/${assignmentId}/hhg/quotes/options/costing/rounding`, { rounding });
  }

  updateTransitAdditionalDetails = (
    { clientId, assignmentId }: AssignmentIdentity,
    transitId: string,
    data: any
  ) => {
    return this.api.patch(
      `/clients/${clientId}/assignment/${assignmentId}/hhg/transits/${transitId}/additional-details`,
      data
    );
  };

  fetchQuoteOptionsEmailForm = ({ clientId, assignmentId }: AssignmentIdentity) => {
    return this.api
      .get(`/clients/${clientId}/assignment/${assignmentId}/hhg/quotes/options/email/form`)
      .pipe(map(payload => payload.form));
  };

  postQuoteOptionsEmail = ({ clientId, assignmentId }: AssignmentIdentity, data: any) => {
    return this.api.post(
      `/clients/${clientId}/assignment/${assignmentId}/hhg/quotes/options/email`,
      data
    );
  };

  fetchQuoteOptionsRejectionForm = ({ clientId, assignmentId }: AssignmentIdentity) => {
    return this.api
      .get(`/clients/${clientId}/assignment/${assignmentId}/hhg/quotes/options/rejection/form`)
      .pipe(map(payload => payload.form));
  };

  confirmQuoteOptionsRejection = ({ clientId, assignmentId }: AssignmentIdentity, data: any) => {
    return this.api.post(
      `/clients/${clientId}/assignment/${assignmentId}/hhg/quotes/options/rejection`,
      data
    );
  };

  fetchQuoteOptionsApprovalForm = ({ clientId, assignmentId }: AssignmentIdentity) => {
    return this.api
      .get(`/clients/${clientId}/assignment/${assignmentId}/hhg/quotes/options/approval/form`)
      .pipe(map(payload => payload.form));
  };

  confirmQuoteOptionsApproval = ({ clientId, assignmentId }: AssignmentIdentity, data: any) => {
    return this.api.post(
      `/clients/${clientId}/assignment/${assignmentId}/hhg/quotes/options/approval`,
      data
    );
  };

  changeQuoteOptionsPolicy = ({ clientId, assignmentId }: AssignmentIdentity, data: any) => {
    return this.api.patch(
      `/clients/${clientId}/assignment/${assignmentId}/hhg/quotes/options/policy`,
      data
    );
  };

  updateQuoteOptionsRounding = ({ clientId, assignmentId }: AssignmentIdentity, rounding: any) => {
    return this.api.patch(
      `/clients/${clientId}/assignment/${assignmentId}/hhg/quotes/options/rounding`,
      {
        rounding
      }
    );
  };

  updateQuoteProductLabels = ({ clientId, assignmentId }: AssignmentIdentity, labels: any) => {
    return this.api.patch(
      `/clients/${clientId}/assignment/${assignmentId}/hhg/quotes/options/review/labels`, labels
    ).pipe(take(1));
  };

  updateQuoteOptionsTransitMarkup = (
    { clientId, assignmentId }: AssignmentIdentity,
    transitId: string,
    markupValue: any
  ) => {
    return this.api.patch(
      `/clients/${clientId}/assignment/${assignmentId}/hhg/quotes/options/transit/${transitId}/markup`,
      { mark_up: markupValue }
    );
  };

  fetchNewTransitInsuranceForm = (
    { clientId, assignmentId }: AssignmentIdentity,
    transitId: string
  ) => {
    return this.api.get(
      `/clients/${clientId}/assignment/${assignmentId}/hhg/quotes/transit/${transitId}/insurance/new`
    );
  };

  createTransitInsurance = (
    { clientId, assignmentId }: AssignmentIdentity,
    transitId: string,
    data: any
  ) => {
    return this.api.post(
      `/clients/${clientId}/assignment/${assignmentId}/hhg/quotes/transit/${transitId}/insurance/new`,
      data
    );
  };

  fetchTransitInsuranceForm = (
    { clientId, assignmentId }: AssignmentIdentity,
    transitId: string,
    insuranceId: number
  ) => {
    return this.api.get(
      `/clients/${clientId}/assignment/${assignmentId}/hhg/quotes/transit/${transitId}/insurance/${insuranceId}`
    );
  };

  updateTransitInsurance = (
    { clientId, assignmentId }: AssignmentIdentity,
    transitId: string,
    insuranceId: number,
    data: any
  ) => {
    return this.api.patch(
      `/clients/${clientId}/assignment/${assignmentId}/hhg/quotes/transit/${transitId}/insurance/${insuranceId}`,
      data
    );
  };

  deleteTransitInsurance = (
    { clientId, assignmentId }: AssignmentIdentity,
    transitId: string,
    insuranceId: number
  ) => {
    return this.api.delete(
      `/clients/${clientId}/assignment/${assignmentId}/hhg/quotes/transit/${transitId}/insurance/${insuranceId}`
    );
  };

  fetchTransitProductAdjustmentForm = (
    { clientId, assignmentId }: AssignmentIdentity,
    vendorQuoteId: number,
    transitId: string,
    productKey: string
  ) => {
    return this.api.get(
      `/clients/${clientId}/assignment/${assignmentId}/hhg/quotes/${vendorQuoteId}/transit/${transitId}/product/${productKey}/adjustment/form`
    );
  };

  updateTransitProductAdjustment = (
    { clientId, assignmentId }: AssignmentIdentity,
    vendorQuoteId: number,
    transitId: string,
    productKey: string,
    data: any
  ) => {
    return this.api.patch(
      `/clients/${clientId}/assignment/${assignmentId}/hhg/quotes/${vendorQuoteId}/transit/${transitId}/product/${productKey}/adjustment`,
      data
    );
  };

  // TRANSACTIONS
  fetchTransactions = ({ clientId, assignmentId }: AssignmentIdentity, serviceId: number) => {
    return this.api.get(
      `/clients/${clientId}/assignment/${assignmentId}/service/${serviceId}/transactions`
    );
  };

  // Invoices Oneoff
  fetchNewTransactionsInvoicesOneoffForm = (
    { clientId, assignmentId }: AssignmentIdentity,
    serviceId: number
  ) => {
    return this.api.get(
      `/clients/${clientId}/assignment/${assignmentId}/service/${serviceId}/transactions/invoices/oneoff/new`
    );
  };

  createTransactionsInvoicesOneoff = (
    { clientId, assignmentId }: AssignmentIdentity,
    serviceId: number,
    data: any
  ) => {
    return this.api.post(
      `/clients/${clientId}/assignment/${assignmentId}/service/${serviceId}/transactions/invoices/oneoff`,
      data
    );
  };

  fetchTransactionsInvoicesOneoffForm = (
    { clientId, assignmentId }: AssignmentIdentity,
    transactionId: string,
    serviceId: number
  ) => {
    return this.api.get(
      `/clients/${clientId}/assignment/${assignmentId}/service/${serviceId}/transactions/invoices/oneoff/${transactionId}`
    );
  };

  updateTransactionsInvoicesOneoff = (
    { clientId, assignmentId }: AssignmentIdentity,
    transactionId: string,
    serviceId: number,
    data: any
  ) => {
    return this.api.patch(
      `/clients/${clientId}/assignment/${assignmentId}/service/${serviceId}/transactions/invoices/oneoff/${transactionId}`,
      data
    );
  };

  // Invoices Recurring
  fetchNewTransactionsInvoicesRecurringForm = (
    { clientId, assignmentId }: AssignmentIdentity,
    serviceId: number
  ) => {
    return this.api.get(
      `/clients/${clientId}/assignment/${assignmentId}/service/${serviceId}/transactions/invoices/recurring/new`
    );
  };

  createTransactionsInvoicesRecurring = (
    { clientId, assignmentId }: AssignmentIdentity,
    serviceId: number,
    data: any
  ) => {
    return this.api.post(
      `/clients/${clientId}/assignment/${assignmentId}/service/${serviceId}/transactions/invoices/recurring`,
      data
    );
  };

  fetchTransactionsInvoicesRecurringForm = (
    { clientId, assignmentId }: AssignmentIdentity,
    transactionId: string,
    serviceId: number
  ) => {
    return this.api.get(
      `/clients/${clientId}/assignment/${assignmentId}/service/${serviceId}/transactions/invoices/recurring/${transactionId}`
    );
  };

  updateTransactionsInvoicesRecurring = (
    { clientId, assignmentId }: AssignmentIdentity,
    transactionId: string,
    serviceId: number,
    data: any
  ) => {
    return this.api.patch(
      `/clients/${clientId}/assignment/${assignmentId}/service/${serviceId}/transactions/invoices/recurring/${transactionId}`,
      data
    );
  };

  // Invoices Recurring & Oneoff
  cancelTransactionsInvoicesForm = (
    { clientId, assignmentId }: AssignmentIdentity,
    transactionId: string,
    serviceId: number
  ) => {
    return this.api.get(
      `/clients/${clientId}/assignment/${assignmentId}/service/${serviceId}/transactions/invoices/transaction/${transactionId}/cancellation`
    );
  };

  deleteTransactionsInvoices = (
    { clientId, assignmentId }: AssignmentIdentity,
    transactionId: string,
    serviceId: number,
    data: any
  ) => {
    return this.api.patch(
      `/clients/${clientId}/assignment/${assignmentId}/service/${serviceId}/transactions/invoices/cancellation/transaction/${transactionId}`,
      data
    );
  };

  addTransactionsInvoicesToGroup = (
    { clientId, assignmentId }: AssignmentIdentity,
    serviceId: number,
    data: any
  ) => {
    return this.api.put(
      `/clients/${clientId}/assignment/${assignmentId}/service/${serviceId}/transactions/invoices/group`,
      data
    );
  };

  removeTransactionsInvoicesFromGroup = (
    { clientId, assignmentId }: AssignmentIdentity,
    transactionId: string,
    serviceId: number
  ) => {
    return this.api.delete(
      `/clients/${clientId}/assignment/${assignmentId}/service/${serviceId}/transactions/invoices/group/transaction/${transactionId}`
    );
  };

  // Purchases Oneoff
  fetchNewTransactionsPurchasesOneoffForm = (
    { clientId, assignmentId }: AssignmentIdentity,
    serviceId: number
  ) => {
    return this.api.get(
      `/clients/${clientId}/assignment/${assignmentId}/service/${serviceId}/transactions/purchases/oneoff/new`
    );
  };

  createTransactionsPurchasesOneoff = (
    { clientId, assignmentId }: AssignmentIdentity,
    serviceId: number,
    data: any
  ) => {
    return this.api.post(
      `/clients/${clientId}/assignment/${assignmentId}/service/${serviceId}/transactions/purchases/oneoff`,
      data
    );
  };

  fetchTransactionsPurchasesOneoffForm = (
    { clientId, assignmentId }: AssignmentIdentity,
    transactionId: string,
    serviceId: number
  ) => {
    return this.api.get(
      `/clients/${clientId}/assignment/${assignmentId}/service/${serviceId}/transactions/purchases/oneoff/${transactionId}`
    );
  };

  updateTransactionsPurchasesOneoff = (
    { clientId, assignmentId }: AssignmentIdentity,
    transactionId: string,
    serviceId: number,
    data: any
  ) => {
    return this.api.patch(
      `/clients/${clientId}/assignment/${assignmentId}/service/${serviceId}/transactions/purchases/oneoff/${transactionId}`,
      data
    );
  };

  // Purchases Recurring
  fetchNewTransactionsPurchasesRecurringForm = (
    { clientId, assignmentId }: AssignmentIdentity,
    serviceId: number
  ) => {
    return this.api.get(
      `/clients/${clientId}/assignment/${assignmentId}/service/${serviceId}/transactions/purchases/recurring/new`
    );
  };

  createTransactionsPurchasesRecurring = (
    { clientId, assignmentId }: AssignmentIdentity,
    serviceId: number,
    data: any
  ) => {
    return this.api.post(
      `/clients/${clientId}/assignment/${assignmentId}/service/${serviceId}/transactions/purchases/recurring`,
      data
    );
  };

  fetchTransactionsPurchasesRecurringForm = (
    { clientId, assignmentId }: AssignmentIdentity,
    transactionId: string,
    serviceId: number
  ) => {
    return this.api.get(
      `/clients/${clientId}/assignment/${assignmentId}/service/${serviceId}/transactions/purchases/recurring/${transactionId}`
    );
  };

  // Purchases Recurring PO
  fetchNewTransactionsPurchasesOrderRecurringForm(
    { clientId, assignmentId }: AssignmentIdentity,
    serviceId: number
  ) {
    return this.api.get(
      `/clients/${clientId}/assignment/${assignmentId}/service/${serviceId}/transactions/purchases/recurring/order/new`
    );
  }

  createTransactionsPurchasesOrderRecurring = (
    { clientId, assignmentId }: AssignmentIdentity,
    serviceId: number,
    data: any
  ) => {
    return this.api.post(
      `/clients/${clientId}/assignment/${assignmentId}/service/${serviceId}/transactions/purchases/recurring/order`,
      data
    );
  };

  fetchTransactionsPurchasesOrderRecurringForm = (
    { clientId, assignmentId }: AssignmentIdentity,
    transactionId: string,
    serviceId: number
  ) => {
    return this.api.get(
      `/clients/${clientId}/assignment/${assignmentId}/service/${serviceId}/transactions/purchases/oneoff/${transactionId}`
    );
  };

  updateTransactionsPurchasesOrderRecurring = (
    { clientId, assignmentId }: AssignmentIdentity,
    transactionId: string,
    serviceId: number,
    data: any
  ) => {
    return this.api.patch(
      `/clients/${clientId}/assignment/${assignmentId}/service/${serviceId}/transactions/purchases/oneoff/${transactionId}`,
      data
    );
  };

  updateTransactionsPurchasesRecurring = (
    { clientId, assignmentId }: AssignmentIdentity,
    transactionId: string,
    serviceId: number,
    data: any
  ) => {
    return this.api.patch(
      `/clients/${clientId}/assignment/${assignmentId}/service/${serviceId}/transactions/purchases/recurring/${transactionId}`,
      data
    );
  };

  // Purchases Recurring & Oneoff
  cancelTransactionsPurchasesForm = (
    { clientId, assignmentId }: AssignmentIdentity,
    transactionId: string,
    serviceId: number
  ) => {
    return this.api.get(
      `/clients/${clientId}/assignment/${assignmentId}/service/${serviceId}/transactions/purchases/transaction/${transactionId}/cancellation`
    );
  };

  deleteTransactionsPurchases = (
    { clientId, assignmentId }: AssignmentIdentity,
    transactionId: string,
    serviceId: number,
    data: any
  ) => {
    return this.api.patch(
      `/clients/${clientId}/assignment/${assignmentId}/service/${serviceId}/transactions/purchases/cancellation/transaction/${transactionId}`,
      data
    );
  };

  // Credit Notes

  fetchCreditNotesDialogData({clientId, assignmentId}: AssignmentIdentity, serviceId: number, transactionId: number) {
    return this.api.get(`/clients/${clientId}/assignment/${assignmentId}/service/${serviceId}/transactions/sales-invoice/details/${transactionId}`);
  }

  setCreditNotes({clientId, assignmentId}: AssignmentIdentity, serviceId: number, transactionId: number, data) {
    return this.api.put(`/clients/${clientId}/assignment/${assignmentId}/service/${serviceId}/transactions/sales-invoice/details/${transactionId}`,
      data);
  }

  fetchTransitEditForm({ clientId, assignmentId }: AssignmentIdentity, transitId: number) {
    return this.api.get(`/clients/${clientId}/assignment/${assignmentId}/hhg/quotes/options/review/transits/${transitId}`)
      .pipe(map(({ form }) => form));
  }

  patchTransitEditForm({ clientId, assignmentId }: AssignmentIdentity, transitId: number, data) {
    return this.api.patch(`/clients/${clientId}/assignment/${assignmentId}/hhg/quotes/options/review/transits/${transitId}`, data);
  }

  /** transit bundles **/

  private _getTransitBundleUrl = ({ clientId, assignmentId }: AssignmentIdentity) =>
    `/clients/${clientId}/assignment/${assignmentId}/hhg/quotes/options/bundle`;

  getTransitBundleForm(assignmentIdentity: AssignmentIdentity) {
    return this.api.get(`${this._getTransitBundleUrl(assignmentIdentity)}/new`)
      .pipe(take(1), map(({ form }) => form));
  }

  addNewTransitBundle(assignmentIdentity: AssignmentIdentity, data: TransitBundle) {
    return this.api.post(`${this._getTransitBundleUrl(assignmentIdentity)}/new`, data);
  }

  getEditTransitBundleForm(assignmentIdentity: AssignmentIdentity, id: string) {
    return this.api.get(`${this._getTransitBundleUrl(assignmentIdentity)}/${id}`)
      .pipe(take(1), map(({ form }) => form));
  }

  updateTransitBundle(assignmentIdentity: AssignmentIdentity, data: TransitBundle) {
    return this.api.patch(`${this._getTransitBundleUrl(assignmentIdentity)}/${data.id}`, data);
  }

  deleteTransitBundle(assignmentIdentity: AssignmentIdentity, id: string) {
    return this.api.delete(`${this._getTransitBundleUrl(assignmentIdentity)}/${id}`);
  }
}

const claimFormNormalizer = toFormSpecNormalizer('origin_agent_id', 'destination_agent_id');
