import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BackForwardFilterComponent } from '@k2/common/filters/components/back-forward-filter.component';
import { DateFilterComponent } from '@k2/common/filters/components/date-filter.component';
import { K2SelectFilterComponent } from '@k2/common/filters/components/k2-select-filter.component';
import { SelectFilterComponent } from '@k2/common/filters/components/select-filter.component';
import { TextFilterComponent } from '@k2/common/filters/components/text-filter.component';
import { K2SelectModule } from '@k2/common/k2-select/k2-select.module';
import { UIModule } from '@k2/common/ui/ui.module';
import { UserSearchFilterComponent } from "@k2/common/filters/components/user-search-filter.component";
import { MatLegacySelectModule as MatSelectModule } from "@angular/material/legacy-select";
import { NgxMatSelectSearchModule } from "ngx-mat-select-search";
import { MultiSelectFilterComponent } from "@k2/common/filters/components/multi-select-filter.component";
import { MatInputModule } from '@angular/material/input';

const components = [
  SelectFilterComponent,
  K2SelectFilterComponent,
  MultiSelectFilterComponent,
  TextFilterComponent,
  BackForwardFilterComponent,
  DateFilterComponent,
  UserSearchFilterComponent,
  ];

const exports = [
  SelectFilterComponent,
  K2SelectFilterComponent,
  MultiSelectFilterComponent,
  TextFilterComponent,
  BackForwardFilterComponent,
  DateFilterComponent,
  UserSearchFilterComponent,
  MatInputModule
];

@NgModule({
  imports: [
    CommonModule,
    UIModule,
    FormsModule,
    ReactiveFormsModule,
    K2SelectModule,
    MatSelectModule,
    NgxMatSelectSearchModule,
    MatInputModule
  ],
  declarations: components,
  exports: exports
})
export class FiltersModule {
}
