/**
 * Creates an absolute path, which is compatible with Angular router.
 *
 * Uses current window's location to create an absolute path from a given relative path.
 * Base href will be ignored to maintain compatibility with Angular router.
 *
 * Example:
 *  Location: /dashboard/client/setup/addresses/2/edit
 *  Base: /dashboard
 *
 *  "toAbsolutePath('../..')" will return "/client/setup/addresses"
 */
export function toAbsolutePath(relativePath: string): string {
  const link = document.createElement('a');
  const base = link.baseURI.replace(`${window.location.protocol}//${window.location.host}`, '');
  link.href = `${window.location.pathname.replace(base, '')}/${relativePath}`;

  const absolutePath = link.pathname;

  if (absolutePath.endsWith('/')) return absolutePath.slice(0, absolutePath.length - 1);
  return absolutePath;
}
