<div class="services">
  <a (click)="toggleAll()" class="toggle-all pull-right">Toggle all</a>
  <fieldset *ngFor="let workflow of workflows | values" class="workflow">
    <legend class="block-header">{{ workflow.label }}</legend>
    <div class="block-content">
      <div class="col-sm-12 one-row">
        <div *ngFor="let service of services[workflow.id]" class="row">
          <label class="col-sm-4 control-label">{{ service.name }}</label>

          <div class="col-sm-8 field-wrap">
            <div *ngIf="service.variations">
              <label
                *ngFor="let variation of service.variations | values"
                [class.disabled]="service.locked"
                class="col-sm-8"
              >
                <input
                  [ngModel]="variation.enabled"
                  [disabled]="service.locked"
                  (ngModelChange)="toggle(variation)"
                  type="checkbox"
                />
                <span>{{ variation.value }} {{ variation.label }}</span>
              </label>
            </div>
            <div *ngIf="!service.variations">
              <label [class.disabled]="service.locked" class="col-sm-8">
                <input
                  [ngModel]="service.enabled"
                  [disabled]="service.locked"
                  (ngModelChange)="toggle(service)"
                  type="checkbox"
                />
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>
  </fieldset>
</div>
