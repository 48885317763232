import { Session } from '@k2/common/sessions-state/session';
import { Action } from '@ngrx/store';
import { RouterStateSnapshot } from '@angular/router';

export const STORE_REAL_SESSION = '[Sessions] Store real';
export const LOGOUT = '[Sessions] Logout';
export const DELETE_SESSION = '[Sessions] Delete';
export const REFRESH_REAL_SESSION = '[Sessions] Refresh real';
export const REFRESH_REAL_SESSION_SUCCESS = '[Sessions] Refresh real success';
export const REFRESH_REAL_SESSION_FAILURE = '[Sessions] Refresh real failure';

export type Actions =
  | StoreRealSession
  | Logout
  | DeleteSession
  | RefreshRealSession
  | RefreshRealSessionSuccess
  | RefreshRealSessionFailure;

export class StoreRealSession implements Action {
  readonly type = STORE_REAL_SESSION;

  constructor(readonly session: Session) {}
}

export class Logout implements Action {
  readonly type = LOGOUT;
  readonly skipApiRequest: boolean;

  constructor(params: { skipApiRequest: boolean } = { skipApiRequest: false }) {
    this.skipApiRequest = params.skipApiRequest;
  }
}

export class DeleteSession implements Action {
  readonly type = DELETE_SESSION;

  constructor(readonly userId: number, readonly sessionId: string) {}
}

export class RefreshRealSession implements Action {
  readonly type = REFRESH_REAL_SESSION;

  constructor(readonly state: RouterStateSnapshot) {}
}

export class RefreshRealSessionSuccess implements Action {
  readonly type = REFRESH_REAL_SESSION_SUCCESS;

  constructor(readonly session: Session, readonly state: RouterStateSnapshot) {}
}

export class RefreshRealSessionFailure implements Action {
  readonly type = REFRESH_REAL_SESSION_FAILURE;
}
