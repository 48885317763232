import { K2User } from '@k2/common/entities-state/types';
import { Field } from '@k2/common/k2-forms-state/types';
import { LinkGenerator } from '@k2/common/links/link-generator';
import { ComponentSpec } from '@k2/common/ui/component-spec';
import { ComponentsListComponent } from '@k2/common/ui/components/components-list/components-list.component';
import { UserIngotComponent } from '@k2/common/users/components/user-ingot/user-ingot.component';

type Result = ComponentSpec<Partial<ComponentsListComponent> | UserIngotComponent>;

export function k2UserFormatter(linkGenerator: LinkGenerator, field: Field): Result {
  const { value } = field.control;

  if (Array.isArray(value)) {
    return {
      inputs: {
        specs: value.map(user => toUserIngotSpec(user))
      },
      component: ComponentsListComponent
    };
  }
  return value ? toUserIngotSpec(value) : null;

  function toUserIngotSpec(user: K2User): ComponentSpec<UserIngotComponent> {
    const link = linkGenerator.generate({
      type: 'k2User',
      data: { userId: user.id }
    });

    return {
      inputs: { user, link },
      component: UserIngotComponent
    };
  }
}
