import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { FileJetModule } from '@k2/common/file-jet/file-jet.module';
import { FiltersModule } from '@k2/common/filters/filters.module';
import { K2SelectModule } from '@k2/common/k2-select/k2-select.module';
import { LinksModule } from '@k2/common/links/links.module';
import { LocationsModule } from '@k2/common/locations/locations.module';
import { PaginationModule } from '@k2/common/pagination/pagination.module';
import { PermissionsModule } from '@k2/common/permissions/permissions.module';
import { UIModule } from '@k2/common/ui/ui.module';
import { AngularChecklistReplacementModule as ChecklistModule } from "@k2/common/angular-checklist-replacement/angular-checklist-replacement.module";
import { ApiUrlPipe } from './backend/api-url.pipe';

@NgModule({
  exports: [
    CommonModule,
    ReactiveFormsModule,
    UIModule,
    PaginationModule,
    RouterModule,
    FiltersModule,
    PermissionsModule,
    LinksModule,
    ChecklistModule,
    FileJetModule,
    K2SelectModule,
    LocationsModule,
    ApiUrlPipe
  ],
  declarations: [ApiUrlPipe]
})
export class SharedModule {
}
