import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  ViewChild
} from '@angular/core';

@Component({
  selector: 'service-message-text',
  templateUrl: 'service-message-text.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ServiceMessageTextComponent implements AfterViewInit {
  @Input() text: string;
  showAll = false;
  overflows: boolean;

  @ViewChild('pElement', { static: true }) pElement: ElementRef;

  constructor(private changeDetectorRef: ChangeDetectorRef) {}

  ngAfterViewInit(): void {
    this.overflows = isOverflown(this.pElement.nativeElement);
    this.changeDetectorRef.detectChanges();
  }
}
function isOverflown(element) {
  return element.scrollHeight > element.clientHeight || element.scrollWidth > element.clientWidth;
}
