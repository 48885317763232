import { Component, Inject } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { Backend } from '@k2/common/backend/backend';
import { Logout } from '@k2/common/sessions-state/actions';
import { ActionDispatcher } from '@k2/common/state/services/action-dispatcher';

@Component({
  templateUrl: 'logout-countdown-dialog.component.html',
  styleUrls: ['logout-countdown-dialog.component.scss']
})
export class LogoutCountdownDialogComponent {
  public countdown: number;
  private interval;

  constructor(
    private dialogRef: MatDialogRef<LogoutCountdownDialogComponent, void>,
    private backend: Backend,
    private readonly actions: ActionDispatcher,
    @Inject(MAT_DIALOG_DATA) public data: LogoutCountdownDialogData
  ) {
    this.countdown = data.warningDuration;
    this.interval = setInterval(() => {
      this.countdown--;
      if (this.countdown === 0) this.logout();
    }, 1000);
  }

  logout = () => {
    clearInterval(this.interval);
    this.dialogRef.close();
    this.actions.dispatch(new Logout());
  };

  ping = () => {
    clearInterval(this.interval);
    this.backend.ping();
    this.dialogRef.close();
  };
}

export interface LogoutCountdownDialogData {
  warningDuration: number;
}
