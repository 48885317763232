<annotated-control [field]="field">
  <input
    type="number"
    [id]="field.attributes.id"
    [formControl]="field.control"
    [min]="field.attributes.min"
    [max]="field.attributes.max"
    [step]="step"
    [placeholder]="field.attributes.placeholder || ''"
    class="form-control"
  />
</annotated-control>
