<a
  *ngFor="let option of options; let i = index"
  class="option"
  (click)="!disabled && (value = option.id)"
  [class.disabled]="disabled"
  [class.selected]="option.id === value"
  matRipple
>
  <div class="icon">
    <div
      [style.font-size.px]="toIconSize(i)"
      [style.height.px]="toIconSize(i) + 6"
    >
      <icon type="mat_local_shipping"></icon>
    </div>
  </div>
  <span>{{ option.value }}</span>
</a>
