<mat-select #select
            [(value)]="value"
            [disabled]="disabled"
            [placeholder]="placeholder"
            (selectionChange)="changeValue()"
            (openedChange)="openedChange.emit($event)"
            [class.black-border]="select.focused"
            class="form-control"
            [class.disabled]="disabled">
  <mat-option>
    <ngx-mat-select-search
      placeholderLabel=""
      noEntriesFoundLabel="Nothing found"
      [hideClearSearchButton]="true"
      [formControl]="filterCtrl"
    ></ngx-mat-select-search>
  </mat-option>
  <mat-option *ngFor="let option of options" [value]="option.value" [class.hidden]="!option.label">
    {{ option.label }}
  </mat-option>
</mat-select>
