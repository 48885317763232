export function isFloat(value: number): boolean {
  return safeModulus(value, 0.0001) === 0;
}

export function isFloatTooLong(value: number): boolean {
  return numberOfDigits(value) > 10;
}

export function isInteger(value: number): boolean {
  return safeModulus(value, 1) === 0;
}

export function isIntegerTooLong(value: number): boolean {
  return numberOfDigits(value) > 11;
}

export function isPasswordStrengthEnough(value: string): boolean {
  const pattern = new RegExp(
    /^(?=.*[A-Za-z])(?=.*\d)(?=.*[ !"#$%&'()*+,-./:;<=>?@[\]\\^_`{|}~])[A-Za-z\d !"#$%&'()*+,-./:;<=>?@[\]\\^_`{|}~]{14,}$/
  );
  return pattern.test(value);
}

function safeModulus(dividend: number, divisor: number): number {
  const valDecCount = (dividend.toString().split('.')[1] || '').length;
  const stepDecCount = (divisor.toString().split('.')[1] || '').length;
  const decCount = valDecCount > stepDecCount ? valDecCount : stepDecCount;
  const dividedInt = parseInt(parseFloat(dividend.toString()).toFixed(decCount).replace('.', ''));
  const divisorInt = parseInt(parseFloat(divisor.toString()).toFixed(decCount).replace('.', ''));
  return (dividedInt % divisorInt) / Math.pow(10, decCount);
}

function numberOfDigits(value: number): number {
  const [number, decimal] = value.toString().split('.');

  if (decimal != null) return number.length + decimal.length;
  return number.length;
}
