import { ApiClient } from '@k2/common/backend/api-client';
import { AssignmentIdentity } from '@k2/common/entities-state/types';
import { map } from 'rxjs/operators';

export class ClientAssignmentsEndpoint {
  constructor(private api: ApiClient) {}

  fetchAssignment = ({ clientId, assignmentId }: AssignmentIdentity) => {
    return this.api.get(`/clients/${clientId}/assignment/${assignmentId}/analysis/overview`);
  };

  fetchAssignments = (clientId: number) => {
    return this.api
      .get(`/clients/${clientId}/assignments`)
      .pipe(map(payload => payload.assignments));
  };

  fetchSoonAssignments = (userId: number, type: 'starting' | 'ending') => {
    return this.api
      .get(`/clients/${userId}/assignments/soon/${type}`)
      .pipe(map(payload => payload.assignments));
  };

  fetchAssignmentStatuses = ({ clientId, assignmentId }: AssignmentIdentity) => {
    return this.api
      .get(`/clients/${clientId}/assignment/${assignmentId}/statuses`)
      .pipe(map(payload => payload.statuses));
  };

  patchAssignmentStatus = ({ clientId, assignmentId }: AssignmentIdentity, statusId: number) => {
    const data = { k2_status_id: statusId };
    return this.api.patch(`/clients/${clientId}/assignment/${assignmentId}/status`, data);
  };

  fetchAssignmentLinks = ({ clientId, assignmentId }: AssignmentIdentity) => {
    return this.api.get(`/clients/${clientId}/assignment/${assignmentId}/links`);
  };

  fetchNewAssignmentLinkForm = ({ clientId, assignmentId }: AssignmentIdentity) => {
    return this.api
      .get(`/clients/${clientId}/assignment/${assignmentId}/links/new`)
      .pipe(map(payload => payload.form));
  };

  updateNewAssignmentLink = ({ clientId, assignmentId }: AssignmentIdentity, data: any) => {
    return this.api.post(`/clients/${clientId}/assignment/${assignmentId}/link`, data);
  };

  deleteLink = (clientId: number, assignmentId: number, linkedAssignmentId: number) => {
    return this.api.delete(
      `/clients/${clientId}/assignment/${assignmentId}/link/${linkedAssignmentId}`
    );
  };

  fetchCloseDetails = ({ clientId, assignmentId }: AssignmentIdentity) => {
    return this.api.get(`/clients/${clientId}/assignment/${assignmentId}/close`);
  };

  updateCloseReason = ({ clientId, assignmentId }: AssignmentIdentity, data) => {
    return this.api.patch(`/clients/${clientId}/assignment/${assignmentId}/close`, data);
  };

  reopenAssignment = ({ clientId, assignmentId }: AssignmentIdentity) => {
    return this.api.patch(`/clients/${clientId}/assignment/${assignmentId}/reopen`, null);
  };

  deleteAssignment = ({ clientId, assignmentId }: AssignmentIdentity) => {
    return this.api.delete(`/clients/${clientId}/assignment/${assignmentId}`);
  };

  fetchServiceAddons = ({ clientId, assignmentId }: AssignmentIdentity) => {
    return this.api.get(`/clients/${clientId}/assignment/${assignmentId}/service/addons/list`);
  };

  updateEnabledServiceAddons = ({ clientId, assignmentId }: AssignmentIdentity, data) => {
    return this.api.post(`/clients/${clientId}/assignment/${assignmentId}/service/addons`, data);
  };

  fetchPackages = ({ clientId, assignmentId }: AssignmentIdentity) => {
    return this.api.get(`/clients/${clientId}/assignment/${assignmentId}/packages`);
  };
}
