import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnDestroy,
  ViewChild
} from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Backend } from '@k2/common/backend/backend';
import { Attachment, Email, EmailSummary } from '@k2/common/entities-state/types';
import { escapeRegExp, Subscriptions } from '@k2/common/helpers';
import { ClientAssignmentComponent } from '@k2/staff/content/clients/client/assignment/client-assignment.component';

@Component({
  selector: 'email-message',
  templateUrl: 'email-message.component.html',
  styleUrls: ['email-message.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EmailMessageComponent implements OnDestroy, AfterViewInit {
  @ViewChild('iframe', { static: false }) iframe: ElementRef;

  private subscriptions = new Subscriptions();
  msg: Email;

  constructor(
    public assignmentRef: ClientAssignmentComponent,
    private backend: Backend,
    private sanitizer: DomSanitizer,
    private detectorRef: ChangeDetectorRef
  ) {}

  ngAfterViewInit(): void {
    this.keepResizing();
  }

  fetchMessage(email: EmailSummary) {
    this.subscriptions.add(
      this.backend.assignmentAnalysis
        .fetchEmail(this.assignmentRef.identity, email.id)
        .subscribe((msg: Email) => {
          this.msg = msg;
          this.detectorRef.markForCheck();
        })
    );
  }

  keepResizing = () => {
    setTimeout(() => {
      try {
        this.iframe.nativeElement.style.height =
          this.iframe.nativeElement.contentWindow.document.body.offsetHeight + 20 + 'px';
      } catch (e) {}
      this.keepResizing();
    }, 200);
  };

  formattedContent(msg: Email): SafeResourceUrl {
    const html = redirectLinksToNewPage(this.replaceCIDs(msg.id, msg.body_html, msg.attachments));
    return this.toTrustedObjectUrl(html);
  }

  toTrustedObjectUrl = (html: string) => {
    const blob = new Blob([html], { type: 'text/html;charset=utf-8' });
    return this.sanitizer.bypassSecurityTrustResourceUrl(URL.createObjectURL(blob));
  };

  replaceCIDs = (msgId: number, html: string, attachments: Attachment[]): string => {
    attachments.forEach(attachment => {
      const regexp = toAttachmentCidRegExp(attachment);
      const replaceValue = this.toAttachmentLink(attachment.id, msgId);
      html = html.replace(regexp, replaceValue);
    });

    return html;
  };

  /**
   * Returns attachments, which are not mentioned in message's body.
   */
  nonInternalAttachments(msg: Email): Attachment[] {
    return msg.attachments.filter(attachment => {
      const regexp = toAttachmentCidRegExp(attachment);
      return msg.body_html.match(regexp) === null;
    });
  }

  toAttachmentLink = (attachmentId: number, emailId: number) => {
    const { clientId } = this.assignmentRef.identity;
    const { assignmentId } = this.assignmentRef.identity;
    const prefix = `https://api.k2relo.com/api/clients/${clientId}/assignment/${assignmentId}`;
    const body = `/analysis/email/message/${emailId}/attachment/${attachmentId}`;
    return prefix + body;
  };

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}

function toAttachmentCidRegExp(attachment: Attachment): RegExp {
  return new RegExp(`"cid:${escapeRegExp(attachment.file_name)}[^"]*"`, 'g');
}

function redirectLinksToNewPage(html: string): string {
  return `<base target="_blank"><style>body {overflow: hidden; height: fit-content !important;}</style>${html}`;
}
