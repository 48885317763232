import { ChangeDetectionStrategy, Component, Input, OnChanges } from '@angular/core';
import { isNotEmpty, replayLast } from '@k2/common/helpers';
import { Paginator } from '@k2/common/pagination/paginator';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'k2-table',
  templateUrl: 'k2-table.component.html',
  styleUrls: ['k2-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class K2TableComponent implements OnChanges {
  @Input('items') _items: Observable<any> | any;
  @Input() filterOptions: FilterOptions = {};
  @Input() paginator?: Paginator;

  items: Observable<any>;
  areFiltersVisible: Observable<boolean>;

  ngOnChanges(): void {
    if (this._items != null && this._items.subscribe != null) {
      this.items = this._items;
    } else {
      this.items = of(this._items).pipe(replayLast());
    }

    this.areFiltersVisible = this.items.pipe(
      map(items => {
        if (this.filterOptions.alwaysVisible) return true;
        return isNotEmpty(items) || items.$filtered === true;
      })
    );
  }

  shouldFiltersBeInHeader(header: HTMLElement): boolean {
    return isEmpty(header) || this.filterOptions.inlineWithHeader;
  }

  shouldFiltersBeUnderHeader(header: HTMLElement): boolean {
    return !isEmpty(header) && !this.filterOptions.inlineWithHeader;
  }
}

function isEmpty(element: HTMLElement): boolean {
  if (element == null) return true;
  return element.children.length === 0;
}

export interface FilterOptions {
  readonly alwaysVisible?: boolean;
  readonly inlineWithHeader?: boolean;
}
