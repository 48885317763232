<profile-ingot>
  <ng-container image>
    <country-flag
      [code]="location.country_iso2 || location.country_iso"
      [matTooltip]="location.country"
    ></country-flag>
  </ng-container>

  <div class="city">{{ location.city_utf8 }}</div>

  <div>
    <span class="region">
      {{ location.region }}, {{ location.country_iso }}
    </span>
  </div>
</profile-ingot>
