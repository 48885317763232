<ng-container *ngIf="feedback">
  <h2>Ratings & reviews</h2>

  <div class="flex-container">
    <div class="flex-item">
      <strong>K2 rating:</strong>
      <div class="avarage-rating">
        <star-rating [rating]="feedback.average_rating"></star-rating>
      </div>
    </div>
    <div class="flex-item">
      <strong>Breakdown:</strong>
      <div class="progress-wrap">
        <span>4 stars</span>
        <progress-bar
          [progress]="(feedback.rating_breakdown['4'] / ratingsCount) * 100"
        ></progress-bar>
        <span>{{ feedback.rating_breakdown['4'] }}</span>
      </div>

      <div class="progress-wrap">
        <span>3 stars</span>
        <progress-bar
          [progress]="(feedback.rating_breakdown['3'] / ratingsCount) * 100"
        ></progress-bar>
        <span>{{ feedback.rating_breakdown['3'] }}</span>
      </div>

      <div class="progress-wrap">
        <span>2 stars</span>
        <progress-bar
          [progress]="(feedback.rating_breakdown['2'] / ratingsCount) * 100"
        ></progress-bar>
        <span>{{ feedback.rating_breakdown['2'] }}</span>
      </div>

      <div class="progress-wrap">
        <span>1 star </span>
        <progress-bar
          [progress]="(feedback.rating_breakdown['1'] / ratingsCount) * 100"
        ></progress-bar>
        <span>{{ feedback.rating_breakdown['1'] }}</span>
      </div>
    </div>

    <div class="flex-item">
      <strong>Recommended:</strong>
      <div class="progress-wrap">
        <span>{{ feedback.recommend_percent | number: '1.0-0' }} %</span>
        <progress-bar [progress]="feedback.recommend_percent"></progress-bar>
      </div>
    </div>
  </div>

  <k2-table
    *ngIf="historyRecords.length > 0"
    [items]="historyRecords"
    [paginator]="paginator"
  >
    <ng-container header><h2>Ratings history</h2></ng-container>

    <table>
      <thead>
        <tr>
          <th>Rating</th>
          <th>Date</th>
          <th>Assignment</th>
          <th>Notes</th>
          <th>Responses</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let record of historyRecords | paginate: paginator | async">
          <td><star-rating [rating]="record.average_rating"></star-rating></td>

          <td><date [iso]="record.completed_at"></date></td>

          <td>
            <a
              [routerLink]="[
                '/staff/clients',
                record.clients_id,
                'assignment',
                record.assignments_id
              ]"
            >
              {{ record.assignments_id | k2Id }}
            </a>
          </td>

          <td>
            <inline-text [text]="record.notes"></inline-text>
            <empty *ngIf="!record.notes">none</empty>
          </td>

          <td>
            <a (click)="openResponsesDialog(record)" class="underline">
              view responses
            </a>
          </td>
        </tr>
      </tbody>
    </table>
  </k2-table>
</ng-container>
