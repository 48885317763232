import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { User } from '@k2/common/entities-state/types';

@Component({
  selector: 'user-contact-ingot',
  templateUrl: 'user-contact-ingot.component.html',
  styleUrls: ['user-contact-ingot.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UserContactIngotComponent {
  @Input() user: User;
  @Input() link: any;

  get telephone(): string {
    if (this.user) {
      return this.user.telephone_work || this.user.telephone_mobile || this.user.telephone_home;
    }
  }
}
