<ng-container [ngSwitch]="level">
  <h1 [slim]="slim" *ngSwitchCase="1">
    <ng-container [ngTemplateOutlet]="children"></ng-container>
  </h1>
  <h2 [slim]="slim" *ngSwitchCase="2">
    <ng-container [ngTemplateOutlet]="children"></ng-container>
  </h2>
  <h3 [slim]="slim" *ngSwitchCase="3">
    <ng-container [ngTemplateOutlet]="children"></ng-container>
  </h3>
  <h4 [slim]="slim" *ngSwitchCase="4">
    <ng-container [ngTemplateOutlet]="children"></ng-container>
  </h4>
  <h5 [slim]="slim" *ngSwitchCase="5">
    <ng-container [ngTemplateOutlet]="children"></ng-container>
  </h5>
  <h6 [slim]="slim" *ngSwitchCase="6">
    <ng-container [ngTemplateOutlet]="children"></ng-container>
  </h6>

  <span [attr.aria-level]="level" role="heading">
    <ng-container [ngTemplateOutlet]="children"></ng-container>
  </span>
</ng-container>

<ng-template #children><ng-content></ng-content></ng-template>
