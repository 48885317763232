import { Pipe, PipeTransform } from '@angular/core';
import { isNotNil } from '@k2/common/helpers';
import { VariableLengthPaginator } from '@k2/common/pagination/paginator';
import { combineLatest, Observable, of } from 'rxjs';
import { distinctUntilChanged, filter, map, tap } from 'rxjs/operators';

@Pipe({
  name: 'paginate'
})
export class PaginatePipe<T> implements PipeTransform {
  transform(items: T[] | Observable<T[]>, paginator: VariableLengthPaginator): Observable<T[]> {
    const observable = normalize(items);

    const nonNilItems = observable.pipe(
      filter(isNotNil),
      distinctUntilChanged(),
      tap(items => paginator.updateLength(items.length))
    );

    return combineLatest([nonNilItems, paginator.state]).pipe(
      map(([items, instruction]) => {
        return items.slice(instruction.offset, instruction.offset + instruction.limit);
      })
    );
  }
}

function normalize<T>(items: T[] | Observable<T[]>) {
  if (!(items instanceof Observable)) return of(items);
  return items;
}
