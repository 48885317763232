import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FileJetImgComponent } from '@k2/common/file-jet/components/responsive-img/file-jet-img.component';
import { UrlPipe } from '@k2/common/file-jet/url.pipe';

const declarations = [UrlPipe, FileJetImgComponent];

@NgModule({
  imports: [CommonModule],
  declarations,
  exports: declarations
})
export class FileJetModule {}
