export type MeasurementsSystem = 'metric' | 'imperial';

/**
 * Transforms a given `id` to a string.
 */
export function toMeasurementSystem(id: number): MeasurementsSystem {
  switch (id) {
    case 1030:
      return 'imperial';
    case 1031:
      return 'metric';
    default:
      throw Error(`RefType id:${id} is not a valid measurement system!`);
  }
}

/**
 * Converts a metric `unit` to an appropriate unit in a given `system`.
 */
export function convertUnit(unit: string, system: MeasurementsSystem): string {
  if (system === 'metric') return unit;

  switch (unit) {
    case 'cm':
      return 'in';
    case 'm':
      return 'ft';
    case 'CbM':
      return 'CuFt';
    case 'kg':
      return 'Lbs';
    default:
      throw Error(`Unit ${unit} cannot be converted to ${system} measurement system!`);
  }
}
