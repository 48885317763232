<h2 mat-dialog-title>{{ data.title }}</h2>

<mat-dialog-content>
  <ul>
    <li *ngFor="let item of data.items">{{ item }}</li>
  </ul>
</mat-dialog-content>

<mat-dialog-actions>
  <button mat-button mat-dialog-close tabindex="-1">Close</button>
</mat-dialog-actions>
