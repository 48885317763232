import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { range } from 'ramda';

@Component({
  selector: 'star-rating',
  templateUrl: 'star-rating.component.html',
  styleUrls: ['star-rating.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class StarRatingComponent {
  @Input() rating: number;
  @Input() maxRating = 4;

  get range() {
    return range(1, this.maxRating + 1);
  }

  get normalizedRating(): number {
    return Math.round(this.rating);
  }
}
