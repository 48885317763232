<div #fineUploader></div>

<div id="qq-template" style="display: none">
  <div
    class="qq-uploader-selector qq-uploader"
    qq-drop-area-text="Drop files here"
  >
    <div
      class="qq-total-progress-bar-container-selector qq-total-progress-bar-container"
    >
      <div
        role="progressbar"
        aria-valuenow="0"
        aria-valuemin="0"
        aria-valuemax="100"
        class="qq-total-progress-bar-selector qq-progress-bar qq-total-progress-bar"
      ></div>
    </div>
    <div
      class="qq-upload-drop-area-selector qq-upload-drop-area"
      qq-hide-dropzone
    >
      <span class="qq-upload-drop-area-text-selector"></span>
    </div>
    <span class="qq-drop-processing-selector qq-drop-processing">
      <span>Processing dropped files...</span>
      <span
        class="qq-drop-processing-spinner-selector qq-drop-processing-spinner"
      ></span>
    </span>

    <div>
      <ul
        class="qq-upload-list-selector qq-upload-list"
        aria-live="polite"
        aria-relevant="additions removals"
      >
        <li>
          <div class="qq-progress-bar-container-selector">
            <div
              role="progressbar"
              aria-valuenow="0"
              aria-valuemin="0"
              aria-valuemax="100"
              class="qq-progress-bar-selector qq-progress-bar"
            ></div>
          </div>
          <span class="qq-upload-spinner-selector qq-upload-spinner"></span>
          <span>
            <span aria-hidden="true" class="glyphicon glyphicon-file"></span>
            <span class="qq-upload-file-selector qq-upload-file"></span>
          </span>
          <span class="qq-upload-size-selector qq-upload-size"></span>
          <button
            type="button"
            class="qq-btn qq-upload-cancel-selector qq-upload-cancel"
          >
            Cancel
          </button>
          <button
            type="button"
            class="qq-btn qq-upload-retry-selector qq-upload-retry"
          >
            Retry
          </button>
          <button
            type="button"
            class="qq-btn qq-upload-delete-selector qq-upload-delete"
          >
            <span aria-hidden="true" class="glyphicon glyphicon-remove"></span>
          </button>
        </li>
      </ul>

      <div class="qq-upload-button-selector qq-upload-button">
        <div>
          <span aria-hidden="true" class="glyphicon glyphicon-upload"></span>
          Upload a file
        </div>
      </div>
    </div>
  </div>
</div>
