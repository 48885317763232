import { Injectable } from '@angular/core';
import { Backend } from '@k2/common/backend/backend';
import { Navigate } from '@k2/common/routing/actions';
import {
  DeleteSession,
  DELETE_SESSION,
  Logout,
  LOGOUT,
  StoreRealSession,
  STORE_REAL_SESSION, RefreshRealSession, REFRESH_REAL_SESSION, RefreshRealSessionSuccess, RefreshRealSessionFailure, REFRESH_REAL_SESSION_SUCCESS,
} from '@k2/common/sessions-state/actions';
import { clearPersistedSessions } from '@k2/common/sessions-state/persistence';
import { asUndoableRest, rest } from '@k2/common/state/effect-utils';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, flatMap, map, switchMap, tap } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class SessionEffects {
  constructor(private actions: Actions, private backend: Backend) {
  }

  storeRealSession: Observable<Action> = createEffect(() => this.actions.pipe(
    ofType<StoreRealSession>(STORE_REAL_SESSION),
    map(({ session: { type, questionnaireHash, usersId, redirectTo, loginType } }) => {
      if (type === 'STAFF') {
        switch (redirectTo) {
          case 'NORMAL':
            return new Navigate('/staff');
          case 'ACCOUNT_DETAILS':
            return new Navigate('/staff/k2-users/teams/users/' + usersId + '/account-details');
        }
      }

      if (type === 'CLIENT') {
        switch (redirectTo) {
          case 'NORMAL':
            return new Navigate(`/client`);
          case 'ACCOUNT_DETAILS':
            return new Navigate('/client/setup/users/' + usersId + '/edit');
        }
      }

      if (type === 'ASSIGNEE') {
        switch (redirectTo) {
          case 'NORMAL':
          case 'ACCOUNT_DETAILS':
            return new Navigate('/assignee');
        }
      }

      throw Error(`Unsupported session type: ${type}!`);
    })
  ));

  logout: Observable<Action> = createEffect(() => this.actions.pipe(
    ofType<Logout>(LOGOUT),
    tap(() => clearPersistedSessions()),
    switchMap(({ skipApiRequest }) => {
      if (skipApiRequest) return of();
      return this.backend.sessions.logout();
    }),
    tap(redirectToFreshRoot),
    rest
  ));

  deleteSession: Observable<Action> = createEffect(() => this.actions.pipe(
    ofType<DeleteSession>(DELETE_SESSION),
    flatMap(action => {
      return this.backend.sessions.deleteSessions(action.sessionId).pipe(asUndoableRest(action));
    })
  ));

  refreshRealSession: Observable<Action> = createEffect(() => this.actions.pipe(
    ofType<RefreshRealSession>(REFRESH_REAL_SESSION),
    flatMap(action => {
      return this.backend.sessions.fetchSessionInfo()
        .pipe(
          catchError(() => of(null)),
          map(session => {
            if (!session) {
              return new RefreshRealSessionFailure();
            } else return new RefreshRealSessionSuccess(session, action.state);
          })
        )
    })
  ));

  refreshRealSessionSuccess: Observable<Action> = createEffect(() => this.actions.pipe(
    ofType<RefreshRealSession>(REFRESH_REAL_SESSION_SUCCESS),
    map(action => {
      const redirectUrl = action.state.url;
      return new Navigate(redirectUrl);
    })
  ));
}

function toRootNavigationAction() {
  return new Navigate('/');
}

/**
 * Hard browser redirect which will clear all in-memory caches.
 */
function redirectToFreshRoot() {
  return (window.location.href = '/');
}

export const featureEffects = [SessionEffects];
