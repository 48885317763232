<fieldset>
  <div class="content brief">
    <field-label [field]="field" [notRequired]="true"></field-label>

    <field-value [field]="field"
                 emptyMessage="This field has not been set yet">
      <div *ngFor="let msg of messages">{{ msg }}</div>
    </field-value>

    <div *ngIf="!disabled" class="edit">
      <a [routerLink]="editLink">edit</a>
    </div>
  </div>
</fieldset>
