import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Field } from '@k2/common/k2-forms-state/types';

@Component({
  selector: 'self-submitting-field-view',
  templateUrl: 'self-submitting-field-view.component.html',
  styleUrls: ['self-submitting-field.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SelfSubmittingFieldViewComponent {
  // TODO: find a better name
  @Input() field: Field;
  @Input() disabled = false;
  @Input() editLink: any;
  @Input() messages: string[] = [];
}
