import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output
} from '@angular/core';
import { NumIndexed, Service, Workflow } from '@k2/common/entities-state/types';
import { clone, pickBy, values } from 'ramda';

@Component({
  selector: 'services-selector',
  templateUrl: 'services-selector.component.html',
  styleUrls: ['services-selector.component.scss', 'style.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ServicesSelectorComponent implements OnChanges {
  @Input() services: NumIndexed<Service[]>;
  @Input('workflows') allWorkflows: Workflow[];
  @Output() servicesChange = new EventEmitter<NumIndexed<Service[]>>();

  workflows: Workflow[];

  ngOnChanges(): void {
    this.services = clone(this.services);
    this.workflows = pickBy(workflow => this.services[workflow.id] != null, this.allWorkflows);
  }

  toggleAll = () => {
    const services = toFlatServices(this.services);
    const enabled = isSomeDisabled(services);

    modifyAll(services, item => {
      if (!item.locked) item.enabled = enabled;
    });

    this.servicesChange.emit(this.services);
  };

  toggle = item => {
    item.enabled = !item.enabled;
    this.servicesChange.emit(this.services);
  };
}

function toFlatServices(servicesObject) {
  return values(servicesObject).reduce((all, part) => {
    return all.concat(part);
  }, []);
}

function isSomeDisabled(items) {
  return items
    .filter(item => !item.locked)
    .some(item => {
      if (item.variations) return isSomeDisabled(values(item.variations));
      return !item.enabled;
    });
}

function modifyAll(items, modifier) {
  items.forEach(item => {
    if (item.variations) {
      modifyAll(values(item.variations), modifier);
    } else {
      modifier(item);
    }
  });
}
