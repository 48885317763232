<user-ingot-base [user]="user" [link]="link">
  <div *ngIf="telephone">
    <icon type="earphone"></icon>
    <a href="tel:{{ telephone }}" tabindex="-1">{{ telephone | telephone }}</a>
  </div>
  <div>
    <icon type="envelope"></icon>
    <a href="mailto:{{ user.email }}" tabindex="-1">{{ user.email }}</a>
  </div>
</user-ingot-base>
