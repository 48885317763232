<div class="tab-carousel">
  <div class="tabs-container" role="tablist">
    <ul>
      <li
        *ngFor="let item of items"
        routerLinkActive="active"
        [routerLinkActiveOptions]="{ exact: item.exact }"
        matRipple
      >
        <a [routerLink]="item.link" [ngStyle]="linkStyle">
          <icon [type]="item.icon"></icon>
          {{ item.label }}
        </a>
      </li>
    </ul>
  </div>
</div>

<div class="tabs-content" role="tabpanel"><ng-content></ng-content></div>
