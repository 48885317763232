import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewEncapsulation
} from '@angular/core';

@Component({
  selector: 'noticeboard-expandable',
  templateUrl: './noticeboard-expandable.component.html',
  styleUrls: ['./noticeboard-expandable.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class NoticeboardExpandableComponent implements OnChanges {
  _notes: string;
  get notes(): string {
    return this._notes;
  }

  // this forces the markdown editor to use htmlString values instead of jsonDoc
  @Input() set notes(value: string) {
    this._notes = (value ? this.convertToHtmlString(value) : null) || '<p></p>';
  }
  @Input() k2Only = true;
  @Input() loading = false;
  @Input() placeholder = 'There are no K2 notes …';
  @Output() save = new EventEmitter<string>();

  collapsed = false;
  changed = false;
  focused = false;

  @HostListener('document:click', ['$event'])
  clickOut(event) {
    if (!this.focused && !this.changed && !this.loading &&
      !(event.path || (event.composedPath && event.composedPath())).some(obj => obj?.id === 'ngx-editor')) {
      this.collapsed = true;
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.notes) {
      this.changed = false;
    }
  }

  get minRows(): number {
    return this.collapsed ? 2 : 8;
  }

  get maxRows(): number {
    if (this.collapsed) {
      return this.notes ? 6 : 2;
    }
    return null;
  }

  onBlur() {
    this.focused = false;
    if (!this.changed) {
      this.collapsed = true;
    }
  }

  onExpandedChange(event: MouseEvent) {
    event.stopPropagation();
    this.collapsed = !this.collapsed;
  }

  onFocus() {
    this.collapsed = false;
    this.focused = true;
  }

  convertToHtmlString(string: string): string {
    let processedString = string;
    // replace newlines
    processedString = processedString.replace(/(?:\n)/g, '<br>');
    processedString = processedString.replace(/(?:\\n)/g, '<br>');
    // replace tabs
    processedString = processedString.replace(/(?:\t)/g, '<span>&nbsp&nbsp&nbsp&nbsp</span>');
    processedString = processedString.replace(/(?:\\t)/g, '<span>&nbsp&nbsp&nbsp&nbsp</span>');
    return processedString.startsWith('<p') ? processedString : ('<p>' + processedString + '</p>');
  }

  onNotesChange(event: any) {
    if (this.notes !== event) {
      this.notes = event;
      this.changed = true;
    }
  }
}
