import { CurrencyPipe } from '@angular/common';
import { currentLocale } from '@k2/common/locale.module';

export function budgetFormatter(value: BudgetValue): string {
  let currencyPipe = new CurrencyPipe(currentLocale);
  let { frequency } = value;

  if (value.amount !== 'Not specified' && value.amount !== null) {
    return `${currencyPipe.transform(value.amount, value.currency_iso3, 'symbol', '1.2-2')} (${frequency})`
  } else if (value.amount === null) {
    return 'Not specified';
  }
  return value.amount.toString();
}

export function isBudget(value): boolean {
  return (
    value.amount !== undefined && value.frequency !== undefined && value.currency_iso3 !== undefined
  );
}

type BudgetValue = {
  amount: number | 'Not specified';
  frequency: string;
  currency_iso3: string;
};
