import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { AddressesModule } from '@k2/common/addresses/addresses.module';
import { AssigneeCardComponent } from '@k2/common/assignments/components/assignee-card/assignee-card.component';
import { AssigneeFamilyCardComponent } from '@k2/common/assignments/components/assignee-family-card/assignee-family-card.component';
import { AssigneeFamilyPersonIconComponent } from '@k2/common/assignments/components/assignee-family-card/assignee-family-person-icon.component';
import { AssigneeFamilyPetIconComponent } from '@k2/common/assignments/components/assignee-family-card/assignee-family-pet-icon.component';
import { AssignmentBillingComponent } from '@k2/common/assignments/components/billing/assignment-billing.component';
import { CustomFieldsReviewComponent } from '@k2/common/assignments/components/custom-fields-review/custom-fields-review.component';
import { DetailedAssignmentLocationComponent } from '@k2/common/assignments/components/detailed-location/detailed-assignment-location.component';
import { AssignmentDetailsComponent } from '@k2/common/assignments/components/details/assignment-details.component';
import { AssignmentFlightMapComponent } from '@k2/common/assignments/components/flight-map/assignment-flight-map.component';
import { FollowAssignmentToggleComponent } from '@k2/common/assignments/components/follow-assignment-toggle/follow-assignment-toggle.component';
import { AssignmentLocationComponent } from '@k2/common/assignments/components/location/assignment-location.component';
import { AssignmentProgressComponent } from '@k2/common/assignments/components/progress/assignment-progress.component';
import { ServicesMessagesComponent } from '@k2/common/assignments/components/services-messages/services-messages.component';
import { AssignmentEndingSoonTableComponent } from '@k2/common/assignments/components/soon-table/assignments-ending-soon-table.component';
import { AssignmentStartingSoonTableComponent } from '@k2/common/assignments/components/soon-table/assignments-starting-soon-table.component';
import { AssignmentStatusBarComponent } from '@k2/common/assignments/components/status-bar/assignment-status-bar.component';
import { AssignmentStatusChartComponent } from '@k2/common/assignments/components/status-chart/assignment-status-chart.component';
import { AssignmentWorkflowsReviewComponent } from '@k2/common/assignments/components/workflows-review/assignment-workflows-review.component';
import { K2ChartsModule } from '@k2/common/k2-charts/k2-charts.module';
import { MapModule } from '@k2/common/map/map.module';
import { ServicesModule } from '@k2/common/services/services.module';
import { SharedModule } from '@k2/common/shared.module';
import { TelephoneModule } from '@k2/common/telephone/telephone.module';
import { AssignmentAutocompleteOptionComponent } from './components/assignment-autocomplete-option/assignment-autocomplete-option.component';
import { AssignmentIngotComponent } from './components/assignment-ingot/assignment-ingot.component';
import { AssignmentSelectorComponent } from './components/assignment-selector/assignment-selector.component';
import { AssignmentServicesComponent } from './components/assignment-services/assignment-services.component';
import { ServiceMessageTextComponent } from './components/services-messages/service-message-text.component';
import { FilterByServicePipe } from './pipes/filter-by-service';

const exportedComponents = [
  AssignmentLocationComponent,
  AssignmentStatusChartComponent,
  AssignmentStartingSoonTableComponent,
  AssignmentEndingSoonTableComponent,
  AssignmentStatusBarComponent,
  AssigneeCardComponent,
  AssigneeFamilyCardComponent,
  AssigneeFamilyPersonIconComponent,
  AssigneeFamilyPetIconComponent,
  AssignmentProgressComponent,
  AssignmentBillingComponent,
  AssignmentDetailsComponent,
  DetailedAssignmentLocationComponent,
  CustomFieldsReviewComponent,
  AssignmentWorkflowsReviewComponent,
  AssignmentFlightMapComponent,
  FollowAssignmentToggleComponent,
  AssignmentSelectorComponent,
  AssignmentIngotComponent,
  AssignmentAutocompleteOptionComponent,
  ServicesMessagesComponent,
  ServiceMessageTextComponent,
  AssignmentServicesComponent
];

const pipes = [FilterByServicePipe];

@NgModule({
  imports: [
    AddressesModule,
    SharedModule,
    ServicesModule,
    K2ChartsModule,
    MapModule,
    TelephoneModule,
    FormsModule
  ],
  declarations: [exportedComponents, pipes],
  exports: exportedComponents
})
export class AssignmentsModule {}
