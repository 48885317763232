import { ChangeDetectorRef, Component, Input, OnChanges, ViewEncapsulation } from '@angular/core';
import { AbstractControl, ValidationErrors } from '@angular/forms';
import { Field } from '@k2/common/k2-forms-state/types';
import { addValidators } from '@k2/common/k2-forms/field-control/utils';
import * as moment from 'moment';
import { getTimeFormat } from '@k2/common/locale.module';
import { Subscription } from 'rxjs';
import { isEmpty, isNil } from 'ramda';

@Component({
  selector: 'datetime-control',
  templateUrl: 'datetime-control.component.html',
  styleUrls: ['datetime-control.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DatetimeControlComponent implements OnChanges {

  @Input() field: Field<string>;
  @Input() full: boolean = false;

  private momentValue: moment.Moment;
  readonly timeFormat = getTimeFormat();
  private lastControlValue: string;
  private controlValueSubscription: Subscription | undefined;
  timePickerOpened = false;
  timezones: string[] = [
    '+00:00',
    '+01:00',
    '+02:00',
    '+03:00',
    '+03:30',
    '+04:00',
    '+05:00',
    '+05:30',
    '+06:00',
    '+07:00',
    '+08:00',
    '+09:00',
    '+09:30',
    '+10:00',
    '+11:00',
    '+12:00',
    '-11:00',
    '-10:00',
    '-09:00',
    '-08:00',
    '-07:00',
    '-06:00',
    '-05:00',
    '-04:00',
    '-03:30',
    '-03:00',
    '-01:00'
  ];
  timezone: string;

  constructor(private cd: ChangeDetectorRef) {}

  ngOnChanges(): void {
    const { control } = this.field;

    this._propagateControlValueChange();

    if (this.controlValueSubscription) {
      this.controlValueSubscription.unsubscribe();
    }

    this.controlValueSubscription = control.valueChanges.subscribe(() =>
      this._propagateControlValueChange()
    );

    addValidators(this.field.control, this._customValidator);
  }

  private _customValidator = (_: AbstractControl): ValidationErrors => {
    if (this.min && this.min.isAfter(this.date)) {
      return { futureOnlyDate: 'Date needs to be in the future' };
    }
    if (this.max && this.max.isBefore(this.date)) {
      return { pastOnlyDate: 'Date needs to be in the past' };
    }
    return null;
  };

  set date(date: moment.Moment) {
    if (this.momentValue === null) {
      this.momentValue = date;
    } else {
      this.momentValue = date.hours(this.momentValue.hours()).minutes(this.momentValue.minutes());
    }

    this.propagateMomentValueChange();
  }

  get date(): moment.Moment {
    return this.momentValue;
  }

  get startView(): 'month' | 'year' {
    return this.field.attributes.default_view || 'month';
  }

  get time(): string {
    if (this.momentValue == null) return;
    return `${this.momentValue.hours()}:${this.momentValue.minutes()}`;
  }

  set time(time: string) {
    const [timeFormat, meaning] = time.split(' ');
    const [hours, minutes] = timeFormat.split(':');
    if (this.momentValue === null) {
      this.momentValue = moment.utc();
    }
    this.momentValue.set({
      hour: meaning === 'PM' ? 12 + Number(hours) : Number(hours),
      minute: Number(minutes),
      second: 0,
      millisecond: 0
    });
    this.propagateMomentValueChange();
  }

  get min() {
    const rule = this.dateValidatorRule;
    if (rule && rule.params && rule.params.future_only) return moment.utc().startOf('day');
  }

  get max() {
    const rule = this.dateValidatorRule;
    if (rule && rule.params && rule.params.past_only) return moment.utc().startOf('day');
  }

  get disabled() {
    return this.field.control.disabled || this.timePickerOpened;
  }

  private get dateValidatorRule() {
    const validators = this.field.validators || [];
    return validators.find(validator => validator.rule === 'IsDate');
  }

  touch() {
    this.field.control.markAsTouched();
  }

  private _firstValue: string;

  propagateMomentValueChange() {
    if (this.full) {
      this.lastControlValue = this.momentValue.clone().utcOffset(this.timezone, true).format();
    } else {
    
      this.lastControlValue = this.momentValue
        .clone()
        .add(this.momentValue.utcOffset(), 'minutes')
        .toISOString();
    }
    if (!this._firstValue) {
      this._firstValue = this.field.control.value;
    }

    this.field.control.setValue(this.lastControlValue);
  }

  cancel(): void {
    this.field.control.setValue(this._firstValue);
  }

  ok(): void {
    this._firstValue = this.field.control.value;
  }

  private _propagateControlValueChange() {
    const { value } = this.field.control;
    if (this.lastControlValue === value) return;

    if (isEmpty(value) || isNil(value)) {
      this.momentValue = null;
      this.timezone = moment().format('Z');
    } else {
      this.momentValue = this.full ? moment.parseZone(value) : moment.utc(value);
      this.timezone = this.momentValue.format('Z');
    }
    this.cd.markForCheck();
  }
}
