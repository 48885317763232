<div class="cost-savings-section">
  <k2-table [items]="costSavings" [paginator]="paginator">
    <ng-container header><h1>Cost savings</h1></ng-container>

    <ng-container buttons>
      <btn icon="plus" (click)="addCostSaving(serviceId)">Add cost saving</btn>
    </ng-container>

    <table>
      <thead>
      <tr>
        <th>Date added</th>
        <th>Item</th>
        <th>Details</th>
        <th>Request cost</th>
        <th>Comments</th>
        <th class="options">Options</th>
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let costSaving of costSavings | paginate : paginator | async">
        <td>
          <date [iso]="costSaving.requested_at"></date>
        </td>
        <td>{{ costSaving.item }}</td>
        <td>{{ costSaving.detail }}</td>
        <td>{{ costSaving.cost.currency.iso3 }}&nbsp;{{ costSaving.cost.amount | number: '1.2-2' }}</td>
        <td>{{ costSaving.comments }}</td>
        <td class="options">
          <inline-options (remove)="deleteCostSaving(costSaving.id)">
            <a (click)="updateCostSaving(costSaving.id)"><action>edit</action></a>
          </inline-options>
        </td>
      </tr>
      </tbody>
    </table>

    <no-data ifNothing>There are no cost savings for this service.</no-data>
  </k2-table>
</div>
