<div class="head">
  <div #header><ng-content select="[header]"></ng-content></div>

  <div class="filters">
    <ng-container *ngIf="shouldFiltersBeInHeader(header) && areFiltersVisible | async"
                  [ngTemplateOutlet]="filtersTmpl"></ng-container>
  </div>

  <div class="k2-filters">
    <ng-container *ngIf="shouldFiltersBeInHeader(header) && areFiltersVisible | async"
                  [ngTemplateOutlet]="k2FiltersTmpl"></ng-container>
  </div>

  <div class="buttons"><ng-content select="[buttons]"></ng-content></div>
</div>

<intro><ng-content select="[intro]"></ng-content></intro>

<div class="filters">
  <ng-container *ngIf="shouldFiltersBeUnderHeader(header) && areFiltersVisible | async"
                [ngTemplateOutlet]="filtersTmpl"></ng-container>
</div>

<div class="k2-filters">
  <ng-container *ngIf="shouldFiltersBeUnderHeader(header) && areFiltersVisible | async"
                [ngTemplateOutlet]="k2FiltersTmpl"></ng-container>
</div>

<div *ngSubscribe="items as items">
  <ng-container *ngIf="items | isNotEmpty">
    <div class="table table-striped">
      <ng-content select="table"></ng-content>
    </div>

    <ng-content select="[paginator]"></ng-content>

    <ng-container *ngIf="paginator != null">
      <paginator [paginator]="paginator"></paginator>
    </ng-container>
  </ng-container>

  <ng-content *ngIf="(items | isEmpty) && !items?.$filtered" select="[ifNothing]"></ng-content>

  <no-data *ngIf="(items | isEmpty) && items?.$filtered">
    No results matched your search.
  </no-data>
</div>

<ng-template #filtersTmpl>
  <ng-content select="[filters]"></ng-content>
</ng-template>

<ng-template #k2FiltersTmpl>
  <ng-content select="[k2-filters]"></ng-content>
</ng-template>
