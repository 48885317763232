import { NgModule } from '@angular/core';
import { SharedModule } from '@k2/common/shared.module';
import { TelephoneModule } from '@k2/common/telephone/telephone.module';
import { UserSessionsHistoryComponent } from '@k2/common/users/components/sessions-history/user-sessions-history.component';
import { UserAutocompleteOptionComponent } from '@k2/common/users/components/user-autocomplete-option/user-autocomplete-option.component';
import { UserIngotBaseComponent } from '@k2/common/users/components/user-ingot/base/user-ingot-base.component';
import { UserContactIngotComponent } from '@k2/common/users/components/user-ingot/user-contact-ingot.component';
import { UserIngotComponent } from '@k2/common/users/components/user-ingot/user-ingot.component';

const components = [
  UserIngotBaseComponent,
  UserContactIngotComponent,
  UserIngotComponent,
  UserAutocompleteOptionComponent,
  UserSessionsHistoryComponent
];

@NgModule({
  imports: [SharedModule, TelephoneModule],
  declarations: components,
  exports: components
})
export class UsersModule {}
