import { Component, Input, OnChanges, OnDestroy } from '@angular/core';
import { Subscriptions } from '@k2/common/helpers';
import { Field, Fields } from '@k2/common/k2-forms-state/types';
import { isRenderable } from '@k2/common/k2-forms/fields';
import { values } from 'ramda';
import { isObservable, Observable } from 'rxjs';

@Component({
  selector: 'fields',
  templateUrl: 'fields.component.html'
})
export class FieldsComponent implements OnChanges, OnDestroy {
  @Input() fields: Fields | Observable<Fields>;
  @Input() ignore: string[] = [];

  renderableFields: Field[] = [];

  private readonly subscriptions = new Subscriptions();

  ngOnChanges(): void {
    this.subscriptions.unsubscribe();

    if (isObservable(this.fields)) {
      this.subscriptions.add(
        this.fields.subscribe(fields => {
          this.renderableFields = values(fields)
            .filter(field => !this.ignore.includes(field.name))
            .filter(isRenderable);
        })
      );
    } else {
      this.renderableFields = values(this.fields)
        .filter(field => !this.ignore.includes(field.name))
        .filter(isRenderable);
    }
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
}
