import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from '@k2/app.module';
import { appConfig } from '@k2/common/app-config';
import { safe } from '@k2/common/helpers';
import { bootstrap } from 'src/bootstrap';

const { stage } = appConfig;

if (stage !== 'prod') {
  const prefix = `[${stage.toUpperCase()}]`;
  if (!document.title.startsWith(prefix)) {
    document.title = `${prefix} ${document.title}`;
  }
}

const bootstrapModule = () =>
  platformBrowserDynamic().bootstrapModule(AppModule, { preserveWhitespaces: false });

bootstrap(module, bootstrapModule).catch(err => console.error(err));
