import Chart from 'chart.js/auto'

export function setDefaults() {
  Chart.defaults.animation = {
    ...Chart.defaults.animation,
    ...{ duration: 0 }
  };
  Chart.defaults.plugins = {
    ...Chart.defaults.plugins,
    ...{
      beforeInit: function (chart, args, options) {
        // Make sure we're applying the legend to the right chart
        if (chart.canvas.id === "chart-id") {
          const ul = document.createElement('ul');
          chart.data.labels.forEach((label, i) => {
            ul.innerHTML += `
            <li>
              <span style="background-color: ${chart.data.datasets[0].backgroundColor[i]}">
                ${chart.data.datasets[0].data[i]}
              </span>
              ${label}
            </li>
          `;
          });
          return document.getElementById("js-legend").appendChild(ul);
        }

        return;
      }
    }
  };
}
