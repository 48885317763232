<div *ngIf="!noPreview">
  <div class="options">
    <inline-options *ngIf="!disabled">
      <a (click)="showPreview()" [class.active]="!editable">preview</a>
      <a (click)="showInput()" [class.active]="editable">edit</a>
    </inline-options>

    <inline-options *ngIf="disabled">
      <span class="active">preview</span>
      <span>edit</span>
    </inline-options>
  </div>

  <div>
    <div #preview *ngIf="!editable; else editorTemplate" class="preview" [innerHTML]="html"></div>

    <ng-template #editorTemplate>
      <div class="NgxEditor__Wrapper" [ngStyle]="{ minHeight: minHeight }">
        <ngx-editor-menu [editor]="editor" [toolbar]="toolbar"></ngx-editor-menu>
        <ngx-editor id="ngx-editor"
                    [editor]="editor"
                    [ngModel]="html"
                    [disabled]="false"
                    [placeholder]="placeholder"
                    (blur)="touch.emit()"
                    (ngModelChange)="valueChange.emit($event)"></ngx-editor>
      </div>
    </ng-template>
  </div>
</div>

<div *ngIf="noPreview" class="NgxEditor__Wrapper" [ngStyle]="{ minHeight: minHeight }">
  <ngx-editor-menu [editor]="editor" [toolbar]="toolbar"></ngx-editor-menu>
  <ngx-editor
    id="ngx-editor"
    [editor]="editor"
    [ngModel]="html"
    [disabled]="false"
    [placeholder]="placeholder"
    (blur)="touch.emit()"
    (ngModelChange)="valueChange.emit($event)"
  ></ngx-editor>
</div>
