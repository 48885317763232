import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { AbstractControl, ValidationErrors } from '@angular/forms';
import { DocumentFile } from '@k2/common/entities-state/types';
import { Field } from '@k2/common/k2-forms-state/types';
import { addValidators } from '@k2/common/k2-forms/field-control/utils';
import { UploaderState } from '@k2/common/uploads/components/documents-uploader/documents-uploader.component';

@Component({
  selector: 'documents-uploader-control',
  templateUrl: 'documents-uploader-control.component.html'
})
export class DocumentsUploaderControlComponent implements OnChanges {
  @Input() field: Field<DocumentFile[]>;
  existing: DocumentFile[];

  private state: UploaderState = { uploading: [], uploaded: [] };

  ngOnChanges(changes: SimpleChanges): void {
    this.existing = this.field.defaultValue || [];
    addValidators(this.field.control, this.customValidator);
  }

  updateExisting = (documents: DocumentFile[]) => {
    this.existing = documents;
    this.updateValue();
  };

  updateState = (state: UploaderState) => {
    this.state = state;

    if (this.multiple === false && state.uploading.length > 0) {
      this.existing = [];
    }

    this.updateValue();
  };

  private updateValue = () => {
    this.field.control.setValue([...this.existing, ...this.state.uploaded]);
  };

  customValidator = (c: AbstractControl): ValidationErrors => {
    if (this.state.uploading.length > 0) return { uploading: 'File upload still in progress' };
    return null;
  };

  get hasNoFile(): boolean {
    const { state } = this;
    return (
      this.existing.length === 0 && state.uploading.length === 0 && state.uploaded.length === 0
    );
  }

  get multiple(): boolean {
    return this.field.attributes.max !== 1;
  }
}
