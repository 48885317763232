import { Component, Input } from '@angular/core';
import { Field, ValueOption } from '@k2/common/k2-forms-state/types';
import { WithFormControl } from '@k2/common/k2-forms/field-control/utils';

@Component({
  selector: 'icon-select-control',
  templateUrl: 'icon-select-control.component.html',
  styleUrls: ['icon-select-control.component.scss']
})
export class IconSelectControlComponent extends WithFormControl {
  @Input() field: Field<string | number>;

  get options(): ValueOption[] {
    return this.field.attributes.valueOptions;
  }

  toIconSize = (index: number): number => {
    return 24 + (index * 48) / this.options.length;
  };
}
