<removable
  *ngFor="let item of selected"
  (remove)="remove(item)"
  class="selected"
>
  <ng-template
    component-host
    [spec]="config.toSelectedSpec(item)"
  ></ng-template>
</removable>

<div class="search-input">
  <input
    type="text"
    [matAutocomplete]="auto"
    [formControl]="searchControl"
    placeholder="{{ config.placeholder || 'Search' }}"
    class="form-control"
  />
  <icon type="search"></icon>
</div>

<mat-autocomplete #auto="matAutocomplete">
  <mat-option *ngFor="let option of options | async" [value]="option">
    <ng-template
      component-host
      [spec]="config.toOptionSpec(option, searchControl.value)"
    ></ng-template>
  </mat-option>
</mat-autocomplete>
