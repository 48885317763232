import { Inject, Injectable } from '@angular/core';
import { FieldSpec } from '@k2/common/k2-forms-state/types';
import { FIELD_SPEC_VALUE_FORMATTERS, Formatters } from '@k2/common/k2-forms/field-spec/formatters';

@Injectable({ providedIn: 'root' })
export class FieldSpecValueFormatter {
  constructor(@Inject(FIELD_SPEC_VALUE_FORMATTERS) private formatters: Formatters) {}

  format(field: FieldSpec): string {
    const type = field.attributes.display_type;
    const formatter = this.formatters[type];

    if (formatter == null)
      throw new Error(`Failed to format field's value! "${type}" formatter doesn't exists.`);

    return formatter(field);
  }
}
