import { Pipe, PipeTransform } from '@angular/core';
import { inlineAddress } from '@k2/common/addresses/utils';
import { Address } from '@k2/common/entities-state/types';

@Pipe({
  name: 'inlinedAddress'
})
export class InlinedAddressPipe implements PipeTransform {
  transform(address: Address): string {
    return address == null ? null : inlineAddress(address);
  }
}
