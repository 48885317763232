<mat-button-toggle-group
  [id]="field.attributes.id"
  [value]="value"
  (change)="value = $event.value"
  [disabled]="disabled"
>
  <mat-button-toggle *ngFor="let option of options" [value]="option.id">
    {{ option.value }}
  </mat-button-toggle>
</mat-button-toggle-group>
