<annotated-control [field]="field">
  <input
    type="text"
    [id]="field.attributes.id"
    [matAutocomplete]="suggestion.autocomplete"
    [formControl]="field.control"
    [maxlength]="field.attributes.maxlength || 255"
    [placeholder]="field.attributes.placeholder || field.attributes.label || ''"
    [attr.disabled]="field.attributes.disabled ? 'disabled' : null"
    class="form-control"
  />
</annotated-control>

<text-suggestion
  #suggestion
  [resource]="field.attributes.textSuggestion"
  [value]="field.control.value"
></text-suggestion>
