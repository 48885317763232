<annotated-control [field]="field">
  <markdown-editor
    [id]="field.attributes.id"
    [(value)]="value"
    (touch)="touch()"
    [disabled]="disabled"
    [placeholder]="field.attributes.placeholder || field.attributes.label || ''"
    [noPreview]="noPreview"
    (valueChange)="value = $event"
  ></markdown-editor>
</annotated-control>
