import { Directive, EventEmitter, HostBinding, HostListener, Input, OnChanges, Output } from '@angular/core';


/**
 * A simple Angular directive to bind multiple `input[type="checkbox"]` controls
 * to a single output array.
 *
 * EXAMPLE:
 *   <input type="checkbox" [checklist]="myArray" checklistValue="myVal1" />
 */

@Directive({
  selector: '[checklist]'
})
export class AngularChecklistReplacementDirective implements OnChanges {
  @Input() checklist: any[];
  @Input() checklistValue: any;
  @Input() maxSelectedItems: number = -1;
  @Output() checklistChange: EventEmitter<any[]>;
  @HostBinding('checked') isChecked: boolean;

  @HostListener('change', ['$event.target']) triggerOnChange(target: HTMLInputElement) {
    let updatedList;
    const checklist = this.checklist || [];
    if (target && target.checked) {
      if (this.maxSelectedItems === -1 || checklist.length < this.maxSelectedItems) {
        updatedList = [...checklist, this.checklistValue];
        this.checklistChange.emit(updatedList);
      } else {
        target.checked = false;
      }
    } else {
      const i = checklist.indexOf(this.checklistValue);
      updatedList = [...checklist.slice(0, i), ...checklist.slice(i + 1)];
      this.checklistChange.emit(updatedList);
    }
  }

  constructor() {
    this.maxSelectedItems = -1;
    this.checklistChange = new EventEmitter();
  }

  ngOnChanges() {
    const checklist = this.checklist || [];
    this.isChecked = checklist.indexOf(this.checklistValue) >= 0;
  }
}
