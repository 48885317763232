import { ApiClient } from '@k2/common/backend/api-client';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

export class OfficesEndpoint {
  constructor(private api: ApiClient) {}

  fetchAllOffices = () => {
    return this.api.get('/offices').pipe(map(payload => payload.offices));
  };

  fetchNewOfficeForm = () => {
    return this.api.get(`/form/office.form`).pipe(map(payload => payload.form));
  };

  createOffice = (data): Observable<number> => {
    return this.api.post(`/office`, data).pipe(map(payload => payload.form.fields.id.value));
  };

  fetchOffice = (officeId: number) => {
    return this.api.get(`/office/${officeId}`).pipe(map(payload => payload.office));
  };

  fetchOfficeClients = (officeId: number) => {
    return this.api.get(`/office/${officeId}/clients`).pipe(map(payload => payload.clients));
  };

  fetchOfficeK2Users = (officeId: number) => {
    return this.api.get(`/office/${officeId}/staff`).pipe(map(payload => payload.staff));
  };

  fetchOfficeForm = (officeId: number) => {
    return this.api.get(`/office/${officeId}/details`).pipe(map(payload => payload.office));
  };

  updateOffice = (officeId: number, data) => {
    return this.api.patch(`/office/${officeId}/details`, data);
  };

  fetchRecentAssignments = (officeId: number) => {
    return this.api
      .get(`/office/${officeId}/assignments/recent`)
      .pipe(map(payload => payload.assignments));
  };
}
