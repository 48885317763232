import { AbstractControl, UntypedFormControl } from '@angular/forms';

/**
 * Returns true when a given control is required.
 */
export function isRequired(control: AbstractControl): boolean {
  if (control.validator === null) return false;
  const validation = control.validator(new UntypedFormControl(''));

  return validation !== null && validation.required;
}
