import { groupBy, uniq } from 'ramda';
import { ChartData, ChartOptions, ChartType, ChartTypeRegistry } from "chart.js";

export function toIntegerAxis(axis) {
  return {
    ...axis,
    ticks: {
      ...axis.ticks,
      callback: label => {
        if (Math.floor(label) === label) {
          return label;
        }
      }
    }
  };
}

export function toGraphConfig<S, T>(transformation: GraphTransformation<S, T>): GraphConfig<T> {
  return {
    data: transformation.items.map(transformation.toValue),
    labels: transformation.items.map(transformation.toLabel)
  };
}

export function toDatasetsGraphConfig<S, T>(
  transformation: DatasetsGraphTransformation<S, T>
): DatasetsGraphConfig<T> {
  const labels = uniq(transformation.items.map(transformation.toLabel));
  const series = uniq(transformation.items.map(transformation.toDatasetLabel));
  const groupedBySeries = groupBy(transformation.toDatasetLabel, transformation.items);

  const data = series.map(name => {
    const items = groupedBySeries[name];

    return {
      data: labels.map(label => {
        const item = items.find(item => transformation.toLabel(item) === label);
        return item ? transformation.toValue(item) : transformation.defaultValue;
      }),
      label: name
    };
  });

  return {
    datasets: data,
    labels,
    hasData: transformation.items.length > 0
  };
}

export interface GraphTransformation<S, T> {
  readonly items: S[];
  readonly toValue: Extractor<S, T>;
  readonly toLabel: Extractor<S, string>;
}

export interface DatasetsGraphTransformation<T, S> extends GraphTransformation<T, S> {
  readonly defaultValue: any;
  readonly toDatasetLabel: Extractor<T, string>;
}

export interface GraphConfig<T = number> extends CustomizedGraphConfig {
  readonly data: T[];
  readonly labels: string[];
}

export interface DatasetsGraphConfig<T = number> extends CustomizedGraphConfig {
  readonly datasets: Array<{ data: T[]; label: string }>;
  readonly labels: string[];
  readonly hasData: boolean;
}

export interface CustomizedGraphConfig {
  readonly options?: any;
  readonly colors?: any[];
}

export interface ChartConfigV4<T extends keyof ChartTypeRegistry> {
  data: ChartData<T>,
  labels: string[],
  type: ChartType,
  options?: ChartOptions,
  hasData?: boolean,
  average?: number,
  height?: number
}

type Extractor<S, T> = (item: S) => T;
