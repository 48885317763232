import { Field } from '@k2/common/k2-forms-state/types';
import { normalizeValueOption } from '@k2/common/k2-forms/utils';

export function optionsFormatter(field: Field): string {
  const options = field.attributes.valueOptions.map(normalizeValueOption);
  const selected = options.find(option => option.id === field.control.value);

  if (selected === undefined) {
    return 'Undefined';
  }
  if (selected != null) {
    return selected.value;
  }
}
