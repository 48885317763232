<untrusted-link [href]="document | documentUrl" class="limit-text">
  <icon type="file"></icon>
  {{ document.name }}
</untrusted-link>

<div *ngIf="document.category_definition?.photo_type" class="photo-info">
  <div class="image">
    <img
      [src]="document | documentUrl: 'thumbnail'"
      [imageOverlay]="document | documentUrl"
      alt="thumbnail"
    />
  </div>

  <div *ngIf="photoCategories != null">
    <div><strong>Categories:</strong></div>
    <span>{{ photoCategories }}</span>
  </div>
</div>
