import { Pipe, PipeTransform } from '@angular/core';
import { alwaysPrimitive } from '@k2/common/helpers';
import { K2User } from '@k2/common/entities-state/types';
import { LinkGenerator } from './link-generator';

type User = Pick<K2User, 'id'>;

@Pipe({ name: 'k2UserLink' })
export class K2UserLinkPipe implements PipeTransform {
  constructor(private generator: LinkGenerator) {}

  transform(user: User | number): any {
    if (user == null) return;

    return this.generator.generate({
      type: 'k2User',
      data: {
        userId: alwaysPrimitive(user, u => u.id)
      }
    });
  }
}
