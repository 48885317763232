import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'isLoaded'
})
export class IsLoadedPipe implements PipeTransform {
  transform(value: any): boolean {
    return value != null;
  }
}
