import { FlashMessage } from '@k2/common/flash-messages/message';
import { Action } from '@ngrx/store';

export const ADD_MESSAGE = '[FlashMsgs] Add message';
export const REMOVE_MESSAGE = '[FlashMsgs] Remove message';
export const REMOVE_ALL_MESSAGES = '[FlashMsgs] Remove all messages';

export type Actions = AddMessage | RemoveMessage | RemoveAllMessages;

export class AddMessage implements Action {
  readonly type = ADD_MESSAGE;

  constructor(readonly message: FlashMessage) {}
}

export class RemoveMessage implements Action {
  readonly type = REMOVE_MESSAGE;

  constructor(readonly message: FlashMessage) {}
}

export class RemoveAllMessages implements Action {
  readonly type = REMOVE_ALL_MESSAGES;
  readonly payload = null;
}
