import { CurrencyPipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'cash'
})
export class CashPipe implements PipeTransform {
  constructor(private currencyPipe: CurrencyPipe) {}

  transform(value: number, currency: { iso3?: string; currency_iso3?: string }): string {
    if (value == null) return '';
    if (currency == null) return value.toFixed(1);
    const iso3 = currency.iso3 || currency.currency_iso3;

    if (iso3 == null) return value.toFixed(1);
    return this.currencyPipe.transform(value, iso3, 'symbol', '1.2-2');
  }
}
