<div [id]="field.attributes.id" (blur)="touch()">
  <div *ngFor="let option of options">
    <label class="checkboxes-label">
      <input type="checkbox"
             [(checklist)]="value"
             [checklistValue]="option.id"
             [disabled]="disabled" />
      <span>{{ option.value }}</span>
    </label>
  </div>
</div>
