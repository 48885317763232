import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { AssignmentStatusReport } from '@k2/common/entities-state/types';
import { ChartConfigV4, toIntegerAxis } from '@k2/common/k2-charts/utils';
import { TooltipItem } from "chart.js";

@Component({
  selector: 'assignment-status-chart',
  templateUrl: 'assignment-status-chart.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AssignmentStatusChartComponent {
  @Input() report: AssignmentStatusReport[];

  get chart(): ChartConfigV4<'bar'> {
    if (this.report == null) return;

    const data = this.report.map(item => item.count);
    const labels = this.report.map(item => item.status);

    return {
      labels: labels,
      type: 'bar',
      data: {
        labels: labels,
        datasets: [
          {
            data: data,
            backgroundColor: [
              '#444444',
              '#ff9900',
              '#468847',
              '#ff9900',
              '#468847',
              '#468847',
              '#c7c7c7'
            ]
          }
        ]
      },
      options: {
        plugins: {
          tooltip: {
            callbacks: {
              title: function (context: TooltipItem<'bar'>[]) {
                const { data } = context[0].dataset;
                return `${labels[context[0].dataIndex]}: ${data[context[0].dataIndex]}`;
              },
              label: () => null
            }
          },
        },
        indexAxis: 'y',
        scales: {
          x: toIntegerAxis({ ticks: { beginAtZero: true } })
        }
      }
    }
  }
}
