import { UNDO_ACTION } from './undoAction';
import { ActionReducer } from '@ngrx/store';

const STORE_INIT_ACTION = '@ngrx/store/init';
const STORE_UPDATE_ACTION = '@ngrx/store/update-reducers';
let executedActions = [];
let initialState;
let bufferSize = 100;

export function configureBufferSize(size: number): void {
  bufferSize = size;
}

export function handleUndo(rootReducer: ActionReducer<any>) {
  return function (state, action) {
    if (action.type === UNDO_ACTION) {
      // if the action is UNDO_ACTION,
      // then call all the actions again on the rootReducer,
      // except the one we want to rollback
      let newState_1 = initialState;
      executedActions = executedActions.filter(function (eAct) {
        return eAct !== action.payload;
      });
      // update the state for every action until we get the
      // exact same state as before, but without the action we want to rollback
      executedActions.forEach(function (executedAction) {
        return newState_1 = rootReducer(newState_1, executedAction);
      });
      return newState_1;
    }
    if (!(action.type === STORE_INIT_ACTION || action.type === STORE_UPDATE_ACTION)) {
      // push every action that isn't an UNDO_ACTION, STORE_INIT_ACTION, or STORE_UPDATE_ACTION to the executedActions property
      executedActions.push(action);
    }
    let updatedState = rootReducer(state, action);
    if (executedActions.length === bufferSize + 1) {
      let firstAction = executedActions[0];
      // calculate the state x (buffersize) actions ago
      initialState = rootReducer(initialState, firstAction);
      // keep the correct actions
      executedActions = executedActions.slice(1, bufferSize + 1);
    }
    return updatedState;
  };
}
