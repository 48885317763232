import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { Field } from '@k2/common/k2-forms-state/types';
import { WithFormControl } from '@k2/common/k2-forms/field-control/utils';

@Component({
  selector: 'markdown-control',
  templateUrl: 'markdown-control.component.html',
  styleUrls: ['markdown-control.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class MarkdownControlComponent extends WithFormControl<string> {
  @Input() field: Field<string>;
  @Input() noPreview?: boolean = false;
}
