import { CurrencyPipe } from '@angular/common';
import { FieldSpec } from '@k2/common/k2-forms-state/types';

export function budgetFormatter(currencyPipe: CurrencyPipe, field: FieldSpec): string {
  let value = field.value || {};

  let currency = field.attributes.currencyOptions.find(currency => currency.id === value.currency);
  let frequency = field.attributes.frequencyOptions.find(
    frequency => frequency.id === value.frequency
  );
  if (currency && frequency) {
    return (
      currencyPipe.transform(value.amount, currency.value, 'symbol', '1.2-2') +
      ' (' +
      frequency.value +
      ')'
    );
  }
  return value.amount;
}
