<div class="line" [ngClass]="{ full: areAllMilestonesActive() }">
  <ng-container *ngIf="!disabled">
    <a
      *ngFor="let milestone of milestones; let i = index"
      [ngClass]="{ active: isActive(milestone) }"
      (click)="updateMilestoneStatus(milestone.value)"
      [ngStyle]="toPointStyle(i)"
      class="point"
    ></a>
  </ng-container>

  <ng-container *ngIf="disabled">
    <div
      *ngFor="let milestone of milestones; let i = index"
      [ngClass]="{ active: isActive(milestone) }"
      [ngStyle]="toPointStyle(i)"
      class="point"
    ></div>
  </ng-container>

  <div class="active-line" [ngStyle]="currentLineStyle"></div>
  <div class="point active" [ngStyle]="currentPointStyle"></div>
</div>

<div class="labels">
  <label
    *ngFor="let milestone of milestones; let i = index"
    [ngStyle]="toLabelStyle(i)"
    >{{ milestone.label }}</label
  >
</div>
