import { Overlay } from '@angular/cdk/overlay';
import { ComponentPortal, PortalInjector } from '@angular/cdk/portal';
import { Directive, HostListener, Injector, Input } from '@angular/core';
import { IMAGE_OVERLAY_DATA } from '@k2/common/ui/components/image-overlay/image-overlay-data';
import { ImageOverlayComponent } from '@k2/common/ui/components/image-overlay/image-overlay.component';

@Directive({ selector: '[imageOverlay]' })
export class ImageOverlayDirective {
  @Input() imageOverlay: string;

  constructor(private overlay: Overlay, private injector: Injector) {}

  @HostListener('click')
  onClick() {
    const overlay = this.overlay.create();

    const injectorTokens = new WeakMap();
    injectorTokens.set(IMAGE_OVERLAY_DATA, { src: this.imageOverlay, overlay });
    const injector = new PortalInjector(this.injector, injectorTokens);

    const portal = new ComponentPortal(ImageOverlayComponent, null, injector);
    overlay.attach(portal);
  }
}
