import { Component } from '@angular/core';

/**
 * A line of heading with an additional content.
 * Component usually wraps one heading and a group of buttons.
 *
 * USAGE (1):
 *   <headline>
 *     <h1>Survey</h1>
 *     <btn>Add survey</btn>
 *   </headline>
 *
 * USAGE (2):
 *   <headline>
 *     <h1>Users</h1>
 *     <div>
 *       <btn>Add user</btn>
 *       <btn>Add group</btn>
 *     </div>
 *   </headline>
 */
@Component({
  selector: 'headline',
  templateUrl: 'headline.component.html',
  styleUrls: ['headline.component.scss']
})
export class HeadlineComponent {}
