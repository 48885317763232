<button
  [attr.id]="id || null"
  *ngIf="isButton"
  [type]="htmlType"
  class="{{ type }} mat-ripple"
  [disabled]="isDisabled"
  (click)="handleClick($event)"
  formnovalidate
  matRipple
>
  <ng-container [ngTemplateOutlet]="content"></ng-container>
</button>

<ng-container *ngIf="!isButton">
  <ng-container *ngIf="!disabled">
    <a
      *ngIf="link != null"
      [routerLink]="link"
      [queryParams]="queryParams"
      (click)="handleClick($event)"
      class="{{ type }}"
    >
      <ng-container [ngTemplateOutlet]="content"></ng-container>
    </a>

    <a
      *ngIf="href != null"
      [href]="href"
      (click)="handleClick($event)"
      rel="noopener noreferrer"
      target="_blank"
      class="{{ type }}"
    >
      <ng-container [ngTemplateOutlet]="content"></ng-container>
    </a>
  </ng-container>

  <ng-container *ngIf="disabled">
    <a class="disabled {{ type }}">
      <ng-container [ngTemplateOutlet]="content"></ng-container>
    </a>
  </ng-container>
</ng-container>

<ng-template #content>
  <icon *ngIf="showIcon" [type]="icon"></icon>
  <mat-progress-spinner
    *ngIf="showSpinner"
    [diameter]="14"
    mode="indeterminate"
  ></mat-progress-spinner>

  <ng-content></ng-content>
</ng-template>
